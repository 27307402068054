import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CookiesProvider } from "react-cookie";
import { Box, Typography } from "@mui/material";
import localized from "./en.json";
export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <MsalProvider instance={msalInstance}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </MsalProvider>
    <Box
      sx={{
        zIndex: "5",
        width: "100%",
        position: "fixed",
        bottom: "0",
        backgroundColor: "#F9F9F9",
        color: "#ADA9C2",
        height: "24px",
      }}
    >
      <Typography
        sx={{ fontSize: "12px", lineHeight: "15px", padding: "5px 16px", marginLeft: "50px" }}
      >
        {localized["siemens-footer"]}
      </Typography>
    </Box>
  </Router>
);
reportWebVitals();
