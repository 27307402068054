import { Box, Tab, Typography} from "@mui/material";

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export function RenderTab(tabName: string, index: number, icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined){
    return (
        <Tab
          label={tabName}
          icon={icon}
          iconPosition="start"
          {...a11yProps(index)}
          sx={{
            color: "#170D32",
            "&.Mui-selected": {
              padding: "20px 20px",
              background: " rgba(151, 71, 255, 0.1)",
              color: "#170D32",
            },
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "22px",
            textTransform: "none",
          }}
        />
    )
}