import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import localized from "../../../en.json";
import { defaultValuesEditUser } from "../../../Models/models";
import { DialogHeader } from "../../../util/DialogUtil";

type CreateUserFormProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: Function;
  createUserHandler?: Function;
  editUserHandler?: Function;
  defaultValues?: defaultValuesEditUser;
};
const pattern: string =
  "^(?![\\s_-])[A-Za-z0-9](?:[A-Za-z0-9 _-]*[A-Za-z0-9])?\\s*$";

// Create a RegExp object with the 'i' flag for case-insensitive matching
export const nameRegex: RegExp = /^[A-Za-z]+$/;

export const emailRegex: RegExp =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[- .\/0-9]*$/;

export const CreateUserForm = (props: CreateUserFormProps) => {
  const schema = z.object({
    firstName: z
      .string()
      .min(1, { message: "First name is required" })
      .regex(nameRegex, {
        message: localized["enter-valid-first-name"],
      })
      .max(50, {
        message: "First name" + localized["name_max_validation"],
      }),
    lastName: z
      .string()
      .max(50, {
        message: "Last name" + localized["name_max_validation"],
      })
      .optional(),
    emailId: z
      .string()
      .min(1, { message: "Email Id is required" })
      .regex(emailRegex, {
        message: localized["enter-valid-email-id"],
      }),
    mobilePhone: z
      .string()
      .min(10, { message: localized["enter-valid-phone-number"] })
      .regex(phoneRegex, {
        message: "Please enter a valid phone number",
      }),
    // Maximum length check
  });
  type FormValues = z.infer<typeof schema>;

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
    setValue,
    trigger,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: props.defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    reset();
    if (props.defaultValues) {
      props.editUserHandler!(data);
    } else {
      props.createUserHandler!(data);
    }
    props.setIsDialogOpen();
  };

  useEffect(() => {
    if (props.defaultValues) {
      // If default values exist, populate form with them (edit mode)
      reset(props.defaultValues);
    } else {
      // If no default values, clear the form (create mode)
      reset({
        firstName: "",
        lastName: "",
        emailId: "",
        mobilePhone: "",
      });
    }
  }, [props.defaultValues, reset]);

  useEffect(() => {
    if (props.defaultValues) {
      for (const [key, value] of Object.entries(props.defaultValues)) {
        setValue(key as keyof FormValues, value);
      }
      trigger();
    }
  }, [props.defaultValues, setValue, trigger]);

  return (
    <Dialog
      aria-labelledby="crate-user-dialog"
      open={props.isDialogOpen}
      sx={{
        border: "1px solid #EAEAEA",
        borderRadius: "8px",
        ".MuiDialog-paper ": {
          maxWidth: "790px !important",
          width: "auto !important",
        },
      }}
    >
      <DialogHeader
        titleKey="user"
        defaultValues={props.defaultValues}
        localized={localized}
        onClose={() => {
          props.setIsDialogOpen(false);
          reset();
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ padding: "32px" }}>
          <Stack direction="row" gap={"24px"} flexWrap={"wrap"}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="firstName">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                    span: {
                      color: "#DA1E28",
                    },
                  }}
                >
                  {localized["first-name"]}
                  <span>*</span>
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="name"
                  placeholder="First Name"
                  {...register("firstName")}
                  error={errors.firstName !== undefined}
                  helperText={errors.firstName?.message}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      width: "343px",
                      height: "56px",
                    },
                  }}
                  inputProps={{
                    style: {
                      width: "343px",
                      height: "26px",
                    },
                    "data-testid": "firstName",
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="lastName">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                    span: {
                      color: "#DA1E28",
                    },
                  }}
                >
                  {localized["last-name"]}
                  <Typography display="inline" color={"#9f9daa"}>
                    ({localized["optional"]})
                  </Typography>
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="name"
                  placeholder="Last Name"
                  {...register("lastName")}
                  error={errors.lastName !== undefined}
                  helperText={errors.lastName?.message}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      width: "343px",
                      height: "56px",
                    },
                  }}
                  inputProps={{
                    style: {
                      width: "343px",
                      height: "26px",
                    },
                    "data-testid": "lastName",
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="emailId">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                    span: {
                      color: "#DA1E28",
                    },
                  }}
                >
                  {localized["email-id"]}
                  <span>*</span>
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="name"
                  placeholder="Email Id"
                  {...register("emailId")}
                  error={errors.emailId !== undefined}
                  helperText={errors.emailId?.message}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      width: "343px",
                      height: "56px",
                    },
                  }}
                  inputProps={{
                    style: {
                      width: "343px",
                      height: "26px",
                    },
                  }}
                  disabled={props.defaultValues !== undefined}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="mobilePhone">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                    span: {
                      color: "#DA1E28",
                    },
                  }}
                >
                  {localized["phone-number"]}
                  <span>*</span>
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <Controller
                  name="mobilePhone"
                  control={control}
                  render={({ field }) => (
                    <MuiTelInput
                      {...field}
                      defaultCountry="DE"
                      value={field.value}
                      data-testid="mobilePhone"
                      onChange={(value: string) => field.onChange(value)}
                      error={errors.mobilePhone !== undefined}
                      helperText={errors.mobilePhone?.message}
                      placeholder="Phone Number"
                      sx={{
                        ".MuiOutlinedInput-root": {
                          width: "343px",
                          height: "56px",
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            alignItems: "center",
            padding: "32px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "24px",
              width: "100%",
            }}
            type="submit"
            disabled={!(isDirty && isValid)}
          >
            {props.defaultValues
              ? localized["edit-button"]
              : localized["create-button"]}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
