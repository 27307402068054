import { gql, useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormDetail,
  FormField,
  GetCustomerById,
} from "../../../../../Models/models";
import localized from "../../../../../en.json";
import {
  CustomerEdgeDevicesIcon,
  DeleteIcon,
  EditIcon,
  FATReadyIcon,
} from "../../../../../theme/Icons/IshIcons";
import { ConfirmationDialog } from "../../../../../util/ConfirmationDialog";
import CreateDialog from "../../../../../util/CreateDialog";
import { convertDateTime } from "../../../../../util/DateUtil";
import ShowSnackbar from "../../../../CustomizedSnackbar/ShowSnackbar";
import AssignEdgeDeviceDialog from "../../AssignEdgeDevice/AssignEdgeDeviceDialog";
import MarkDeviceFatReady from "../../MarkDeviceFatReady/MarkDeviceFatReady";

const formDetail: FormDetail = {
  title: localized["edit-customer-dialog-title"],
  nameLabel: localized["customer-name-label"],
  namePlaceholder: localized["customer-name-placeholder"],
  descriptionLabel: localized["description-label"],
  descriptionPlaceholder: localized["customer-description-placeholder"],
  saveBtnText: localized["save-btn"],
};

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: Int!, $name: String!, $description: String) {
    updateCustomer(
      customerDtoReq: { id: $id, name: $name, description: $description }
    ) {
      id
      name
    }
  }
`;
export const DELETE_CUSTOMER = gql`
  mutation ($customerID: Int!) {
    deleteCustomer(customerID: $customerID)
  }
`;
interface PropTypes {
  customerDetail: GetCustomerById;
  setIsCustomerUpdated: Function;
}

const CustomerDetailsCard = (props: PropTypes) => {
  const { customerDetail, setIsCustomerUpdated } = props;
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [openAssignDevice, setOpenAssignDevice] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [
    isDeleteCustomerConfirmationDialogOpen,
    setIsDeleteCustomerConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [openFatReadyDialog, setOpenFatReadyDialog] = useState(false);

  const handleAssignDialog = () => {
    setOpenAssignDevice(!openAssignDevice);
  };

  const updateCustomer = (updateData: FormField) => {
    const { name, description } = updateData;
    client
      .mutate({
        mutation: UPDATE_CUSTOMER,
        variables: {
          id: customerDetail.id,
          name: name,
          description: description,
        },
      })
      .then(() => {
        setOpenCustomerDialog(false);
        setIsCustomerUpdated(true);
        ShowSnackbar(
          localized["customer-update-success-msg"],
          true,
          enqueueSnackbar
        );
      })
      .catch((error) => {
        ShowSnackbar(
          localized["customer-update-failed-msg"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };

  const onSubmitHandler = (data: FormField) => {
    updateCustomer(data);
  };
  const handleCloseDeleteDialog = () => {
    setIsWarning(false);
    setIsDeleteCustomerConfirmationDialogOpen(false);
  };
  const onDeleteCustomerClickHandler = () => {
    setIsDeleteCustomerConfirmationDialogOpen(true);
    if (
      customerDetail.projects.length > 0 ||
      customerDetail.devices.length > 0
    ) {
      setIsWarning(true);
      setTitle(localized["cannot-delete-customer"]);
      setDescription(localized["delete-customer-warning"]);
    } else {
      setTitle(localized["delete-customer"]);
      setDescription(localized["customer-delete-confirmation"]);
    }
  };
  const onDeleteCustomerConfirmHandler = () => {
    client
      .mutate({
        mutation: DELETE_CUSTOMER,
        variables: {
          customerID: customerDetail.id,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        setIsDeleteCustomerConfirmationDialogOpen(false);
        setIsCustomerUpdated(true);
        ShowSnackbar(
          localized["delete-customer-success"],
          true,
          enqueueSnackbar
        );
        navigate("/engineering/customer/");
      })
      .catch(() => {
        setIsDeleteCustomerConfirmationDialogOpen(false);
        ShowSnackbar(
          localized["delete-customer-failed"],
          false,
          enqueueSnackbar
        );
      });
  };

  return (
    <Card
      data-testid="customerCard"
      sx={{
        width: "100%",
        boxShadow: "none !important",
      }}
    >
      <CardContent sx={{ padding: "0px !important", width: "100%" }}>
        <Stack padding={"24px"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h4" lineHeight="35px" fontWeight="400">
              {customerDetail?.name}
            </Typography>
            <Typography color="#393939" fontSize="12px">
              {localized["created-on"]}
              <span style={{ marginRight: "4px" }}>:</span>{" "}
              {convertDateTime(customerDetail?.createdAt)}
            </Typography>
          </Stack>
          <Typography
            variant="h5"
            fontWeight="400"
            color="#393939"
            maxWidth={"100%"}
            minHeight={"60px"}
            maxHeight={"65px"}
            sx={{
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {customerDetail?.description}
          </Typography>
          <Box display="flex" sx={{ justifyContent: "space-between" }}>
            <Stack direction={"row"}>
              <Button
                onClick={() => {
                  setOpenAssignDevice(true);
                }}
                variant="text"
                disableRipple
                sx={{
                  gap: "8px",
                  textTransform: "none",

                  width: "230px",
                  marginTop: "10px",

                  borderRadius: "24px",
                }}
              >
                <CustomerEdgeDevicesIcon />
                <Typography variant="h5">
                  {" "}
                  {localized["edge-devices-assigned-btn"]}
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  setOpenFatReadyDialog(true);
                }}
                variant="text"
                disableRipple
                sx={{
                  gap: "8px",
                  textTransform: "none",
                  width: "284px",
                  marginTop: "10px",

                  borderRadius: "24px",
                }}
              >
                <FATReadyIcon />
                <Typography variant="h5">
                  {" "}
                  {localized["mark-fat-ready"]}
                </Typography>
              </Button>
            </Stack>

            <Box display="flex">
              <IconButton>
                <EditIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                  fill="#8A00E5"
                  onClick={() => {
                    setOpenCustomerDialog(true);
                  }}
                />
              </IconButton>
              <IconButton onClick={onDeleteCustomerClickHandler}>
                <DeleteIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                  data-testid="DeleteIcon"
                  fill="#8A00E5"
                />
              </IconButton>
            </Box>
          </Box>
        </Stack>
      </CardContent>
      <AssignEdgeDeviceDialog
        open={openAssignDevice}
        handleClose={handleAssignDialog}
        customerId={(customerDetail?.id).toString()}
        customerName={customerDetail?.name}
        setIsCustomerUpdated={setIsCustomerUpdated}
        
      />
      <CreateDialog
        open={openCustomerDialog}
        handleClose={() => {
          setOpenCustomerDialog(false);
        }}
        formDetail={formDetail}
        onSubmitHandler={onSubmitHandler}
        submitStatus={false}
        defaultDetail={{
          name: customerDetail.name,
          description: customerDetail.description,
        }}
      />
      <MarkDeviceFatReady
        open={openFatReadyDialog}
        handleClose={setOpenFatReadyDialog}
        customerId={customerDetail.id}
      />
      {isDeleteCustomerConfirmationDialogOpen && (
        <ConfirmationDialog
          isDialogOpen={isDeleteCustomerConfirmationDialogOpen}
          handleCloseDialog={handleCloseDeleteDialog}
          title={title}
          description={description}
          leftButtonText={localized["cancel-btn"]}
          rightButtonText={localized["yes-delete"]}
          leftButtonClickHandler={handleCloseDeleteDialog}
          rightButtonClickHandler={onDeleteCustomerConfirmHandler}
          isWarning={isWarning}
        />
      )}
    </Card>
  );
};

export default CustomerDetailsCard;
