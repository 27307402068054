import { gql, useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Snackbar, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import localized from "../../en.json";
import { SoftwareContext } from "../../store/software-list-context";


const IS_INFLUX_AND_TELEGRAF_ASSIGNED_TO_ALL_CUSTOMER = gql`
  query {
    isInfluxAndTelegrafAssignedToAllCustomer
  }
`;
export const SoftwareStoreWarning = () => {
  const context = useContext(SoftwareContext);
  const [isInfluxTelegrafWarning, setIsInfluxTelegrafWarning] = useState(false);
  const client = useApolloClient();
  useEffect(() => {
    if (context?.softwares && context?.softwares!.length > 0) {
      if (
        context?.softwares.filter(
          (sw) => sw.name.includes("influxdb") || sw.name.includes("telegraf")
        ).length >= 2
      ) {
        client
          .query({
            query: IS_INFLUX_AND_TELEGRAF_ASSIGNED_TO_ALL_CUSTOMER,
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            setIsInfluxTelegrafWarning(
              !response.data.isInfluxAndTelegrafAssignedToAllCustomer
            );
          })
          .catch(() => {
            setIsInfluxTelegrafWarning(true);
          });
      } else {
        setIsInfluxTelegrafWarning(true);
      }
    }
  }, [context?.softwares]);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    console.log("clicked");
    if (reason === "clickaway") {
      return;
    }

    setIsInfluxTelegrafWarning(false);
  };
  return (
    <Snackbar
      open={isInfluxTelegrafWarning}
      onClose={handleClose}
      sx={{
        "&.MuiSnackbar-root": {
          border: "2px solid red",
          borderRadius: "4px",
          marginLeft: "80px",
        },
      }}
    >
      <Alert
        onClose={handleClose}
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
            data-testid="CloseIconWarning"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          "&.MuiAlert-action": {
            paddingLeft: "24px !important",
          },
        }}
      >
        <Typography variant="h6" fontWeight={400} color={"black"}>
          {localized["influx-telegraf-store-warning-message"]}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
