import { ApolloError, gql, useApolloClient } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import localized from "../../en.json";
import { BreadCrumbContext } from "../../store/breadcrumb-context";
import { ConfirmationDialog } from "../../util/ConfirmationDialog";
import { convertDateTime } from "../../util/DateUtil";
import { TableComponent } from "../../util/TableComponent";
import ShowSnackbar from "../CustomizedSnackbar/ShowSnackbar";
import { HeadCell } from "../Engineering/SiteManagement/EnergyAssets/AssetsTable/AssetsTable";
import {
  default as User,
  default as UserWithRoleName,
} from "./../../Models/models";
import { CreateUserForm } from "./CreateUpdateUser/CreateUserForm";

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      id
      firstName
      lastName
      emailId
      mobilePhone
      lastLogin
      lastUpdated
      role {
        roleId
        roleName
      }
    }
  }
`;
export const DELETE_USERS = gql`
  mutation ($userIds: [Int]) {
    deleteUsers(userIds: $userIds)
  }
`;
export const CREATE_USER = gql`
  mutation (
    $firstName: String!
    $lastName: String
    $emailId: String!
    $mobilePhone: String!
    $userRole: String!
  ) {
    createUser(
      userDtoReq: {
        firstName: $firstName
        lastName: $lastName
        emailId: $emailId
        mobilePhone: $mobilePhone
        userRole: $userRole
      }
    ) {
      id
    }
  }
`;
export const UPDATE_USER = gql`
  mutation (
    $id: Int!
    $firstName: String!
    $lastName: String
    $emailId: String!
    $mobilePhone: String!
    $userRole: String!
  ) {
    updateUser(
      userDtoReq: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        emailId: $emailId
        mobilePhone: $mobilePhone
        userRole: $userRole
      }
    ) {
      id
    }
  }
`;
const UserManagement = () => {
  const headCells: readonly HeadCell<UserWithRoleName>[] = [
    { id: "name", label: "Name" },
    { id: "roleName", label: "User role" },
    { id: "emailId", label: "Email id" },
    { id: "lastLogin", label: "Last Login" },
    { id: "lastUpdated", label: "Last updated" },
  ];
  const location = useLocation();
  const breadCrumbContext = useContext(BreadCrumbContext);
  const { enqueueSnackbar } = useSnackbar();

  const [userList, setUserList] = useState<UserWithRoleName[]>([]);
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
  const [
    isDeleteUserConfirmationDialogOpen,
    setIsDeleteUserConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUser, setSelectedUser] = useState<
    UserWithRoleName | undefined
  >(undefined);
  const [selectedUsers, setSelectedUsers] = useState<readonly number[]>([]);
  const [isMultiDelete, setIsMultiDelete] = useState<boolean>(false);
  const client = useApolloClient();
  const fetchUserList = () => {
    client
      .query({
        query: GET_USERS,
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        setUserList(
          response.data.getUsers.map((user: any) => {
            return {
              ...user,
              roleName: user.role.roleName,
              name: user.firstName + " " + user.lastName,
              lastLogin: convertDateTime(user.lastLogin),
              lastUpdated: convertDateTime(user.lastUpdated),
            };
          })
        );
      })
      .catch((err: ApolloError) => {
        ShowSnackbar(
          "Failed to fetch Users" + err.message,
          false,
          enqueueSnackbar
        );
      });
  };
  const deleteUsers = async () => {
    client
      .mutate({
        mutation: DELETE_USERS,
        variables: {
          userIds: !isMultiDelete ? [selectedUser?.id] : selectedUsers,
        },
      })
      .then(() => {
        ShowSnackbar(
          selectedUser
            ? localized["delete-user-success"]
            : localized["delete-users-success"],
          true,
          enqueueSnackbar
        );
        setIsDeleteUserConfirmationDialogOpen(false);
        fetchUserList();
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["delete-users-error"] + error.message,
          false,
          enqueueSnackbar
        );
      });
    setSelectedUser(undefined);
    setSelectedUsers([]);
  };
  const createUser = (values: User) => {
    client
      .mutate({
        mutation: CREATE_USER,
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          emailId: values.emailId,
          mobilePhone: values.mobilePhone,
          userRole: "NONADMIN",
        },
      })
      .then(async () => {
        ShowSnackbar(localized["user-added"], true, enqueueSnackbar);
        setOpenCreateUserDialog(false);
        fetchUserList();
      })
      .catch((err: ApolloError) => {
        ShowSnackbar(err.message, false, enqueueSnackbar);
        setOpenCreateUserDialog(false);
      });
  };
  const closeDialogHandler = () => {
    setOpenCreateUserDialog(false);
    setSelectedUser(undefined);
  };
  useEffect(() => {
    breadCrumbContext?.setBreadCrumbsArray([
      {
        name: "Users",
        link: "/user-management/",
      },
    ]);
  }, [breadCrumbContext?.setBreadCrumbsArray, location]);
  useEffect(() => {
    fetchUserList();
  }, []);
  function addUserHanlder() {
    setOpenCreateUserDialog(true);
  }
  const deleteSingleUserClickHandler = (row: UserWithRoleName) => {
    setIsMultiDelete(false);
    setSelectedUser(row);
    setTitle(localized["delete-user"]);
    setIsDeleteUserConfirmationDialogOpen(true);
  };
  useEffect(() => {
    if (selectedUser) {
      setDescription(localized["confirm-user-delete"] + selectedUser?.name);
    }
  }, [selectedUser]);
  const deleteMultipleUserClickHandler = async (userIds: readonly number[]) => {
    setIsMultiDelete(true);
    await setSelectedUsers(userIds);
    setTitle(localized["delete-users"]);
    setDescription(localized["confirm-users-delete"]);
    setIsDeleteUserConfirmationDialogOpen(true);
  };
  const editUserClickHanlder = (user: UserWithRoleName) => {
    setOpenCreateUserDialog(true);
    setSelectedUser(user);
  };
  const cancelHandler = () => {
    setIsDeleteUserConfirmationDialogOpen(false);
  };
  const editUserHandler = (user: UserWithRoleName) => {
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id: selectedUser?.id,
          firstName: user.firstName,
          lastName: user.lastName,
          emailId: user.emailId,
          mobilePhone: user.mobilePhone,
          userRole: selectedUser?.roleName,
        },
      })
      .then(async () => {
        ShowSnackbar(localized["user-updated"], true, enqueueSnackbar);
        fetchUserList();
      })
      .catch((err: ApolloError) => {
        ShowSnackbar(err.message, false, enqueueSnackbar);
      });
    setSelectedUser(undefined);
  };

  return (
    <Stack sx={{ padding: "24px", gap: "24px" }}>
      <Box display="flex" gap="32px" alignItems="center">
        <Typography variant="h4" sx={{ fontSize: "22px", color: "#0D0D0D" }}>
          {localized["users"]}
        </Typography>
        <Button
          onClick={addUserHanlder}
          variant="contained"
          startIcon={<AddIcon sx={{ width: "24px", height: "24px" }} />}
          sx={{ textTransform: "none", borderRadius: "24px" }}
        >
          <Typography variant="h5">{localized["add-user"]}</Typography>
        </Button>
      </Box>
      <TableComponent<UserWithRoleName>
        tableData={userList}
        headCells={headCells}
        deleteHandler={deleteSingleUserClickHandler}
        editHandler={editUserClickHanlder}
        enableCheckboxSelection={true}
        deleteMultipleHandler={deleteMultipleUserClickHandler}
      />
      <CreateUserForm
        isDialogOpen={openCreateUserDialog}
        setIsDialogOpen={closeDialogHandler}
        createUserHandler={createUser}
        defaultValues={selectedUser}
        editUserHandler={editUserHandler}
      />
      {isDeleteUserConfirmationDialogOpen && (
        <ConfirmationDialog
          isDialogOpen={isDeleteUserConfirmationDialogOpen}
          handleCloseDialog={setIsDeleteUserConfirmationDialogOpen}
          title={title}
          description={description}
          leftButtonText={localized["cancel-btn"]}
          rightButtonText={localized["yes-delete"]}
          leftButtonClickHandler={setIsDeleteUserConfirmationDialogOpen}
          rightButtonClickHandler={deleteUsers}
          isWarning={false}
        />
      )}
    </Stack>
  );
};

export default UserManagement;
