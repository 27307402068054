import localized from "../../../../en.json";

import {
  IotDeviceIcon,
  ProjectSiteDetailsIcon,
  SiteIcon,
  UserAccessIcon
} from "../../../../theme/Icons/IshIcons";
import { MenuList } from "../../../../util/MenuList";

const projectDetailsEngineeringMenuList = [
  {
    name: localized["sites"],
    icon: <SiteIcon />,
  },
  {
    name: localized["assign-edge-devices"],
    icon: <IotDeviceIcon />,
  },
];

const projectDetailsManagementMenuList = [
  {
    name: localized["project-details"],
    icon: <ProjectSiteDetailsIcon />,
  },
  {
    name: localized["manage-user-access"],
    icon: <UserAccessIcon />,
  },
];

export const ProjectMenuList = () => {
  return (
    <MenuList
      engineeringMenuList={projectDetailsEngineeringMenuList}
      ManagementMenuList={projectDetailsManagementMenuList}
      displayText={localized["project-management"]}
    ></MenuList>
  );
};
