export const cardMediaStyle = {
    height: 136,
    width: 136,
    borderRadius: "10px",
    boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.13)",
}
export const cardContentStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-start",
    padding: "0px",
    paddingLeft: "16px",
    "&:last-child": {
        paddingBottom: 0
    }
}
export const contentBoxStyle = {
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    paddingRight: "24px",
    alignSelf: "stretch",
}