import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    Link,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import localized from "../../../../en.json";
import { TableComponent } from "../../../../util/TableComponent";
import { HeadCell } from "../../../Engineering/SiteManagement/EnergyAssets/AssetsTable/AssetsTable";
import { MyCsvRow } from "../../../../Models/models";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";

interface PropTypes {
    open: boolean;
    handleClose: () => void;
    deviceId?: string;
}

export const GET_SIGNAL_DATA_REPORT = gql`
query ($deviceID: String!) {
    getAllSignalDataReport(deviceID: $deviceID) {
        id
        status
        url
        startDate
        endDate
        signalsName
        createdAt
    }
  }
`;

const MyCsvFilesDialog = (props: PropTypes) => {
    const { open, handleClose } = props;
    const [csvFilesData, setCsvFilesData] = useState<MyCsvRow[]>([]);
    const client = useApolloClient();
    const { enqueueSnackbar } = useSnackbar();

    const formatDate = (isoDateString: string) => {
        const date = new Date(isoDateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const transformApiResponse = (data: any[]) => {
        return data.map((item) => ({
            dateRange: `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`,
            parameters: item.signalsName,
            requestRaised: formatDate(item.createdAt),
            status: item.status,
            url: item.url,
        }));
    };

    const getCsvFilesTableData = () => {
        client
            .query({
                query: GET_SIGNAL_DATA_REPORT,
                variables: {
                    deviceID: props.deviceId,
                },
                fetchPolicy: "no-cache",
            })
            .then((response: ApolloQueryResult<any>) => {
                const transformed = transformApiResponse(response.data.getAllSignalDataReport);
                setCsvFilesData(transformed);
            })
            .catch(() => {
                ShowSnackbar(
                    localized["failed-to-fetch-signal-data"],
                    false,
                    enqueueSnackbar
                );
            })
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (open) {
            getCsvFilesTableData();
            intervalId = setInterval(() => {
                getCsvFilesTableData();
            }, 10000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [open]);

    const headCells: readonly HeadCell<MyCsvRow>[] = [
        { id: "dateRange", label: "Date Range" },
        { id: "parameters", label: "Parameters" },
        { id: "requestRaised", label: "Request Raised" },
        { id: "status", label: "Status" },
    ];

    const customColumn = {
        status: (row: MyCsvRow) => {
            if (row.status === "FAILED") {
                return (
                    <Typography
                        sx={{
                            color: '#DA1E28',
                        }}
                    >
                        {localized["failed-download"]}
                    </Typography>
                );
            } else if (row.status === "PENDING") {
                return (
                    <Typography
                        sx={{
                            backgroundColor: '#E2D000',
                            borderRadius: '24px',
                            display: 'inline-block',
                            width: '124px',
                            height: 'Fixed(30px)px',
                            padding: '4px 8px 4px 8px',
                            gap: '0px',
                            opacity: '0px',
                        }
                        }
                    >
                        {localized["preparing-file"]}
                    </Typography >
                );
            } else {
                return (
                    <Typography
                        sx={{
                            color: '#8A00E5',
                            textDecoration: 'underline',
                            cursor: 'pointer', // Makes the text look clickable
                        }}
                        onClick={() => window.open(row.url, '_blank')}
                    >
                        {localized["download-csv"]}
                    </Typography>
                );
            }
        },
    };


    return (
        <>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "8px",
                    ".MuiDialog-paper ": {
                        maxWidth: "1290px !important",
                        width: "1290px !important",
                    },
                }}
            >
                <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
                    <DialogTitle
                        sx={{
                            m: 0,
                            fontWeight: "400",
                            fontSize: "28px",
                            lineHeight: "36px",
                        }}
                        id="customized-dialog-title"
                    >
                        {localized["my-csv-files"]}
                    </DialogTitle>
                    <IconButton
                        data-testid="closeBtn"
                        aria-label="close"
                        onClick={() => {
                            handleClose();
                        }}
                        sx={{
                            position: "absolute",
                            right: 32,
                            top: 24,
                            color: "#0D0D0D",
                        }}
                    >
                        <CloseIcon
                            style={{
                                width: "32px",
                                height: "32px",
                            }}
                        />
                    </IconButton>
                </Box>
                <Typography
                    sx={{
                        padding: "32px 32px 0px 32px",
                        fontSize: "16px",
                    }}
                >
                    {localized["file-remove-text"]}
                </Typography>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "32px",
                        paddingTop: "16px",
                        gap: "16px",
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        height="574px"
                        maxHeight="80%"
                    >
                        <TableComponent<MyCsvRow>
                            tableData={csvFilesData}
                            headCells={headCells}
                            orderByDefault="displayName"
                            rowsPerPageDefault={10}
                            enableCheckboxSelection={false}
                            customHeight="49vh"
                            customColumn={customColumn}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default MyCsvFilesDialog;
