import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useRef } from "react";
import localized from "../../../../en.json";
import { AddIcon } from "../../../../theme/Icons/IshIcons";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../util/SearchStyleUtil";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import SoftwaresOnDeviceAlert from "./SoftwaresOnDeviceAlert";
import { GetInstalledSoftwaresByDeviceId, GetSoftwaresByDeviceId } from "../../../../Models/models";
import InstallCompatibleSoftwares, { GET_COMPATIBLE_SOFTWARES } from "./InstallCompatibleSoftwares";
import InstalledSoftwareCard from "./InstalledSoftwareCard";

export const GET_SOFTWARES_BY_DEVICE_ID = gql`
  query ($deviceId: ID!) {
    getSoftwaresByDeviceId(deviceId: $deviceId) {
      id
      name
      description
      version
      fileSize
      isUpdateAvailable
      newSoftwareId
      softwareActionStatus
    }
  }
`;
interface PropTypes {
  deviceId: string | undefined;
}
const SoftwaresOnDevice = (props: PropTypes) => {
  const { deviceId } = props;
  const [loading, setLoading] = React.useState(true);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [allInstalledSoftwaresDetail, setAllInstalledSoftwaresDetail] =
    React.useState<GetSoftwaresByDeviceId[]>([]);
  const [openInstallSoftwares, setOpenInstallSoftwares] = React.useState(false);
  const [searchedText, setSearchedText] = React.useState("");
  const [filteredInstalledsoftwares, setFilteredInstalledsoftwares] =
    React.useState<GetSoftwaresByDeviceId[]>([]);
  const [isSoftwareInstalled, setIsSoftwareInstalled] = React.useState(false);
  const [isSoftwareUpdated, setIsSoftwareUpdated] = React.useState(false);
  const searchTextRef = useRef<string>("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [
    filteredInstalledInfluxDbAndTelegrafSoftwareCount,
    setFilteredInstalledInfluxDbAndTelegrafSoftwareCount,
  ] = React.useState(0);
  const [openSoftwareUploadDialog, setOpenSoftwareUploadDialog] =
    React.useState(false);

  useEffect(() => {
    searchTextRef.current = searchedText;
  }, [searchedText]);

  const handleChangeSearch = (
    searchedValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchText = searchedValue.target.value;
    setSearchedText(searchText);
    const filteredInstalledsoftwares = allInstalledSoftwaresDetail.filter(
      (installedSoftwareDetail: GetSoftwaresByDeviceId) => {
        return installedSoftwareDetail.name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      }
    );
    setFilteredInstalledsoftwares(filteredInstalledsoftwares);
  };

  useEffect(() => {
    const filteredInstalledInfluxDbAndTelegrafSoftware =
      allInstalledSoftwaresDetail.filter(
        (installedSoftwareDetail: GetSoftwaresByDeviceId) => {
          const softwareName = installedSoftwareDetail?.name;
          return (
            softwareName.includes("influxdb") ||
            softwareName.includes("telegraf")
          );
        }
      );
    setShowAlert(filteredInstalledInfluxDbAndTelegrafSoftware?.length !== 2);
    setFilteredInstalledInfluxDbAndTelegrafSoftwareCount(
      filteredInstalledInfluxDbAndTelegrafSoftware?.length
    );
  }, [allInstalledSoftwaresDetail.length]);
  const fetchInstalledSoftwaresById = () => {
    client
      .query({
        query: GET_SOFTWARES_BY_DEVICE_ID,
        variables: {
          deviceId: deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<GetInstalledSoftwaresByDeviceId>) => {
        let allInstalledSoftwares =
          response.data?.getSoftwaresByDeviceId.filter(
            (software) => software.softwareActionStatus !== "installing"
          );
        setAllInstalledSoftwaresDetail(allInstalledSoftwares);

        const filteredInstalledSoftwares = allInstalledSoftwares.filter(
          (installedSoftwareDetail) =>
            installedSoftwareDetail.name
              .toLowerCase()
              .includes(searchTextRef.current.toLowerCase())
        );
        setFilteredInstalledsoftwares(filteredInstalledSoftwares);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-install-software"],
          false,
          enqueueSnackbar
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCompatibleSoftwares = () => {
    client
      .query({
        query: GET_COMPATIBLE_SOFTWARES,
        variables: {
          deviceId: deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<any>) => {
        const allAvailableSoftwaresForDevice =
          response.data?.getAllAvailableSoftwareForDevice;
        const filteredAvailableInfluxDbAndTelegrafSoftware =
          allAvailableSoftwaresForDevice.filter(
            (availableSoftwareDetail: { name: string }) => {
              const softwareName = availableSoftwareDetail?.name;
              return (
                softwareName.includes("influxdb") ||
                softwareName.includes("telegraf")
              );
            }
          );
        filteredAvailableInfluxDbAndTelegrafSoftware?.length +
          filteredInstalledInfluxDbAndTelegrafSoftwareCount >=
          2
          ? setOpenSoftwareUploadDialog(false)
          : setOpenSoftwareUploadDialog(true);
      })
      .catch(() => {
        ShowSnackbar(
          localized["compatible-software-failed"],
          false,
          enqueueSnackbar
        );
      });
  };

  const handleInstallCompatibleSoftwares = () => {
    setOpenInstallSoftwares(!openInstallSoftwares);
  };
  React.useEffect(() => {
    const fetchData = () => {
      if (deviceId) {
        fetchInstalledSoftwaresById();
        fetchCompatibleSoftwares();
      }
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [
    client,
    enqueueSnackbar,
    deviceId,
    isSoftwareInstalled,
    isSoftwareUpdated,
    filteredInstalledInfluxDbAndTelegrafSoftwareCount,
  ]);

  return (
    <Stack
      sx={{
        width: "100%",
        background: "#ffffff",
        justifyContent: "space-between",
        height: "calc(100vh - 180px)",
        paddingLeft: "16px",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        {loading ? (
          <Stack
            width="100%"
            height="550px"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100% !important"
            >
              <Typography
                variant="h4"
                sx={{ color: "#1B1534", marginTop: "16px", marginBottom: "24px" }}
              >
                {localized["software-installed-on-iot-device-heading"]} (
                {allInstalledSoftwaresDetail?.length})
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon sx={{ marginBottom: "2px" }} />}
                sx={{
                  gap: "8px",
                  marginTop: "5px",
                  marginRight: "24px",
                  width: "170px",
                  height: "40px",
                  textTransform: "none",
                  borderRadius: "24px",
                  ".MuiButton-startIcon": {
                    marginTop: "6px",
                    marginRight: "0px",
                  },
                }}
                onClick={handleInstallCompatibleSoftwares}
              >
                <Typography variant="h5">
                  {localized["install-software-on-device"]}
                </Typography>
              </Button>
            </Stack>
            <Box
              display="flex"
              sx={{
                flexGrow: 1,
                marginLeft: "-8px",
                marginBottom: "16px",
                justifyContent: "space-between",
                alignSelf: "stretch",
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon
                    sx={{ width: "24px", height: "24px", color: "#5D596E" }}
                  />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  value={searchedText}
                  onChange={(searchText) => handleChangeSearch(searchText)}
                />
              </Search>
            </Box>

            {filteredInstalledsoftwares?.length > 0 && ( 
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  gap: "20px",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 400px)",
                }}
              >
                {" "}
                {filteredInstalledsoftwares?.map(
                  (installedSoftwareDetail: GetSoftwaresByDeviceId) => (
                    <InstalledSoftwareCard
                      key={installedSoftwareDetail?.id}
                      installedSoftwareDetail={installedSoftwareDetail}
                      deviceId={deviceId}
                      setIsSoftwareUpdated={setIsSoftwareUpdated}
                    />
                  )
                )}
              </Box>
            )}
          </>
        )}
        {openInstallSoftwares && (
          <InstallCompatibleSoftwares
            deviceId={deviceId}
            openDialog={openInstallSoftwares}
            closeDialog={handleInstallCompatibleSoftwares}
            setIsSoftwareInstalled={setIsSoftwareInstalled}
          />
        )}
      </Box>
      {showAlert && (
        <SoftwaresOnDeviceAlert
          setShowAlert={setShowAlert}
          setOpenInstallSoftwares={setOpenInstallSoftwares}
          openSoftwareUploadDialog={openSoftwareUploadDialog}
        />
      )}
    </Stack>
  );
};

export default SoftwaresOnDevice;
