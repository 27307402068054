import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Project, ProjectResponse } from "../Models/models";
import ShowSnackbar from "../components/CustomizedSnackbar/ShowSnackbar";
import localized from "../en.json";

export const GET_PROJECT_LIST_BY_CUSTID = gql`
  query ($customerID: ID!) {
    getProjects(customerID: $customerID) {
      id
      name
      numberOfAssets
      numberOfDevices
    }
  }
`;
interface ContexType {
  projectList: Project[];
  getProjectsByCustId: Function;
}
export const ProjectListContext = React.createContext<ContexType | undefined>(
  undefined
);

export const ProjectListContextProvider = (props: any) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const [projects, setProjects] = useState<Project[]>([]);
  const getProjectsByCustId = (customerId: number) => {
    client
      .query({
        query: GET_PROJECT_LIST_BY_CUSTID,
        variables: {
          customerID: customerId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<ProjectResponse>) => {
        setProjects(response.data.getProjects);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-project-list"],
          false,
          enqueueSnackbar
        );
      });
  };

  return (
    <ProjectListContext.Provider
      value={{
        projectList: projects,
        getProjectsByCustId: getProjectsByCustId,
      }}
    >
      {props.children}
    </ProjectListContext.Provider>
  );
};

export default ProjectListContextProvider;
