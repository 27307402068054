import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';

interface Props {
    snackbarKey: SnackbarKey;
}
const SnackbarCloseButton = (props: Props) => {
    const { snackbarKey } = props;
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <CloseIcon sx={{ color: '#ffffff' }} />
        </IconButton>
    );
}

export default SnackbarCloseButton;