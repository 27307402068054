import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SettingsRemoteOutlinedIcon from "@mui/icons-material/SettingsRemoteOutlined";
import { Box, Chip, IconButton, Link, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import localized from "../../../../en.json";
import { Customers, CustomersResponse, Software } from "../../../../Models/models";
import {
  SaveIcon
} from "../../../../theme/Icons/IshIcons";
import { contentBoxStyle } from "../../../../util/CardStyleUtil";
import SoftwareCardUtil from "../../../../util/SoftwareCardUtil";
import EditSoftwareDetails from "../../EditSoftwareDetails";
import { CustomerAccessDialog } from "./CustomerAccessDialog";
import { GET_ALL_CUSTOMERS } from "../../../Engineering/CustomerManagement/CustomerList/CustomerList";
import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "../../../../util/ConfirmationDialog";
import { SoftwareContext } from "../../../../store/software-list-context";

interface PropsType {
  softwareItem: Software;
}
export const DELETE_SOFTWARE = gql`
  mutation ($softwareID: ID!) {
    deleteSoftware(softwareID: $softwareID)
  }
`;
const SoftwareStoreCard = (props: PropsType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEdit,setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] =
    useState<boolean>(false);
  const [openCustomerAccessDialog,setOpenCustomerAccessDialog] = useState(false);
  const open = Boolean(anchorEl);
  const [allCustomers,setAllCustomers] =useState<Customers[]>([]);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const softwareContext = useContext(SoftwareContext);

  const handleClickOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorEl(null);
  };
  const deleteSoftwareConfirmationHandler = () => {
    client
      .mutate({
        mutation: DELETE_SOFTWARE,
        variables: {
          softwareID: props.softwareItem?.id,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        setOpenDelete(false);
        softwareContext?.softwareListChangeHandler();
        ShowSnackbar(localized["delete-software-success"], true, enqueueSnackbar);
      })
      .catch(() => {
        setOpenDelete(false);
        ShowSnackbar(localized["delete-software-error"], false, enqueueSnackbar);
      });
  };
  const isSoftwareUploadFailed = props.softwareItem.status === "failed";

  function formattedDate(uploadedAt: string): string {
    return new Date(uploadedAt)
      .toISOString()
      .split("T")[0]
      .split("-")
      .reverse()
      .join("-");
  }

  const handleEditDialog =()=>{
    setOpenEdit(!openEdit);
    handleCloseOptions()
  }

  const handleDeleteDialog=()=>{
    setOpenDelete(!openDelete);
    handleCloseOptions()
  }
  const handleCustomerAccessDailog =()=>{
    setOpenCustomerAccessDialog(!openCustomerAccessDialog);
    handleCloseOptions()
  }
  useEffect(() => {
    const getAllCustomers = () => {
        client
            .query({
                query: GET_ALL_CUSTOMERS,
                fetchPolicy: "no-cache",
            })
            .then((response: ApolloQueryResult<CustomersResponse>) => {
                setAllCustomers(response.data.getCustomers);
            })
            .catch(() => {
                ShowSnackbar(
                    localized["failed-to-fetch-customers"],
                    false,
                    enqueueSnackbar
                );
            })
    };
    getAllCustomers();
}, [client, enqueueSnackbar, openCustomerAccessDialog]);
  return (
    <Card
      sx={{
        display: "flex",
        padding: "24px",
        paddingRight: "0px",
        minWidth: 548,
        maxWidth: 548,
        maxHeight:150,
        borderRadius: "8px",
        border: isSoftwareUploadFailed
          ? "2px solid #DA1E28"
          : "1px solid #EAEAEA",
        boxShadow: "none",
      }}
    >
      <SoftwareCardUtil>
        <Box
          display="flex"
          sx={contentBoxStyle}
        >
          <Box display="flex" sx={{ gap: "8px" }}>
            <Typography
              variant="h6"
              sx={{ color: "#1B1534", textAlign: "left" }}
            >
              {props.softwareItem.name}
            </Typography>
            <Chip
              label={props.softwareItem.version}
              variant="outlined"
              sx={{
                fontSize: "10px",
                fontWeight: 700,
                fontFamily: "SiemensSans,Arial",
                color: "#fff",
                border: "1px solid #0057C6",
                background: "#0057C6",
                borderRadius: "20px",
                height: "22px",
                ".MuiChip-label": { padding: "0px 8px" },
              }}
            />
          </Box>
          {isSoftwareUploadFailed ? (
            <IconButton
              aria-label="option-button"
              disabled
              sx={{
                padding: "0px",
                ".MuiSvgIcon-root ": {
                  color: "#ADA9C2 !important",
                },
              }}
            >
              <MoreVertIcon
                sx={{ color: "#1B1534", width: "24px", height: "24px" }}
              />
            </IconButton>
          ) : (
            <IconButton
              aria-label="option-button"
              disableRipple
              onClick={handleClickOptions}
              sx={{ padding: "0px" }}
            >
              <MoreVertIcon
                sx={{ color: "#1B1534", width: "24px", height: "24px" }}
              />
            </IconButton>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseOptions}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: "none",
              },
              "& .MuiMenuItem-root": {
                border: "1px solid #D9D9D9",
                padding: "8px",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "normal",
                color: "#000000",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleEditDialog}>
              {localized["edit-details-option"]}
            </MenuItem>
            <MenuItem onClick={handleDeleteDialog}>
              {localized["delete-button"]}
            </MenuItem>
          </Menu>
        </Box>
        <Box
          display="flex"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
            paddingRight: "24px",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
        >
          <Box display="flex" sx={{ alignItems: "center", gap: "4px" }}>
            <SettingsRemoteOutlinedIcon
              sx={{ color: "#000000", width: "16px", height: "16px" }}
            />
            <Typography
              variant="overline"
              sx={{
                color: "#393939",
                fontSize: "12px",
                lineHeight: "normal",
                textTransform: "none",
              }}
            >
              {isSoftwareUploadFailed
                ? "--"
                : props.softwareItem.compatibleDeviceType}
            </Typography>
          </Box>
          <Box display="flex" sx={{ alignItems: "center", gap: "4px" }}>
            <SaveIcon
              sx={{
                width: "16px",
                height: "16px",
                path: {
                  fill: "#000000",
                },
              }}
            />
            <Typography
              variant="overline"
              sx={{
                color: "#393939",
                fontSize: "12px",
                lineHeight: "normal",
                textTransform: "none",
              }}
            >
              {(props.softwareItem.fileSize / 1024e3).toFixed(3)} MB
            </Typography>
          </Box>
          <Box display="flex" sx={{ alignItems: "center", gap: "4px" }}>
            <AccessTimeIcon
              sx={{ color: "#000000", width: "16px", height: "16px" }}
            />
            <Typography
              variant="overline"
              sx={{
                color: "#393939",
                fontSize: "12px",
                lineHeight: "normal",
                textTransform: "none",
              }}
            >
              {formattedDate(props.softwareItem.uploadedAt)}
            </Typography>
          </Box>
          <Box display="flex" sx={{ alignItems: "center", gap: "4px" }}>
            <PermIdentityIcon
              sx={{ color: "#000000", width: "16px", height: "16px" }}
            />
            <Typography
              variant="overline"
              sx={{
                color: "#393939",
                fontSize: "12px",
                lineHeight: "normal",
                textTransform: "none",
              }}
            >
              {" "}
              {props.softwareItem.uploadedBy}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="overline"
          sx={{
            height: "55px",
            textTransform: "none",
            color: "#393939",
            paddingRight: "24px",
            textAlign: "left",
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {props.softwareItem.description}
        </Typography>
        <CardActions
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: "0px 32px",
            alignSelf: "stretch",
            justifyContent: "flex-end",
            gap: "4px 40px",
            marginTop: "16px"
          }}
        >
          <Link component="button" onClick={handleCustomerAccessDailog}>
            <Box display="flex" sx={{ alignItems: "center" }}>
              <Typography variant="overline" sx={{ textTransform: "none" }}>
                {localized["customer-access-btn"]}
              </Typography>
            </Box>
          </Link>
        </CardActions>
      </SoftwareCardUtil>
      <EditSoftwareDetails open={openEdit} onCloseEditSoftware={handleEditDialog} softwareDetails={props.softwareItem}/>
      <ConfirmationDialog
        isDialogOpen={openDelete}
        handleCloseDialog={setOpenDelete}
        title={localized["delete-software"]}
        description={localized["delete-software-confirmation"]}
        leftButtonText={localized["cancel-btn"]}
        rightButtonText={localized["delete-software-confirm"]}
        leftButtonClickHandler={setOpenDelete}
        rightButtonClickHandler={deleteSoftwareConfirmationHandler}
        isWarning={false}
      />
      <CustomerAccessDialog open={openCustomerAccessDialog} onDialogClose={handleCustomerAccessDailog} software={props.softwareItem} allCustomers={allCustomers}></CustomerAccessDialog>
    </Card>
    
  );
};

export default SoftwareStoreCard;
