import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import localized from "../../en.json";
import { DeviceType, Software } from "../../Models/models";
import { deviceTypeList } from "../../util/ConstantUtils";

let statusValueObj = {
  uploaded: 30,
  scanning: 50,
  scanned: 80,
  success: 100,
  failed: 100,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
    sx: {
      "& .MuiMenuItem-root": {
        border: "1px solid #C0C0C0",
        height: "40px",
        fontSize: "14px",
        color: "#969696",
        padding: "6px 0px",
      },
    },
  },
  MenuListProps: {
    sx: {
      "&.MuiList-root": {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
  },
};
export const UPDATE_SOFTWARE = gql`
  mutation ($id: ID!, $compatibleDeviceType: String) {
    updateSoftware(
      softwareDtoReq: { id: $id, compatibleDeviceType: $compatibleDeviceType }
    ) {
      id
    }
  }
`;
type PropsType = {
  softwareDetails: Software;
  updateSoftware: Function;
};
export const ConfigPendingSoftwareCard = (props: PropsType) => {
  const [compatibleDeviceType, setCompatibleDeviceType] = useState<string[]>(
    []
  );
  const handleChange = (
    event: SelectChangeEvent<typeof compatibleDeviceType>
  ) => {
    const {
      target: { value },
    } = event;
    setCompatibleDeviceType(
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Box border="1px solid #DEDEDE" borderRadius="8px" m="16px 24px 16px 0px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h6"
          sx={{
            fontWeight: "400",
            m: "16px",
          }}
        >
          {props.softwareDetails.name}
        </Typography>
        <Chip
          label={props.softwareDetails.status}
          color={
            props.softwareDetails.status === "uploaded" ? "warning" : "success"
          }
          variant="filled"
          sx={{ fontSize: "16px", fontWeight: 700, mr: "16px" }}
        />
      </Box>
      <Box m="16px 24px" width="85%">
        <Typography
          variant="overline"
          textTransform="none"
          sx={{ opacity: "81%" }}
        >
          {props.softwareDetails?.status}...
          <span style={{ marginLeft: "70%" }}>
            {
              statusValueObj[
                props.softwareDetails.status as keyof typeof statusValueObj
              ]
            }
            %
          </span>
        </Typography>
        <LinearProgress
          color="success"
          sx={{
            height: 4,
            backgroundColor: "#D9D9D9",
          }}
          variant="determinate"
          value={
            statusValueObj[
              props.softwareDetails.status as keyof typeof statusValueObj
            ]
          }
        />
      </Box>
      <Box
        m="16px 24px"
        sx={{
          span: {
            color: "#DA1E28",
          },
        }}
      >
        <Typography variant="h5" mb="8px">
          {localized["compatible-device-types"]}
          <span>*</span>
        </Typography>

        <Select
          disabled={props.softwareDetails.status !== "success"}
          sx={{
            width: "95%",
            height: "44px",
            "&.MuiOutlinedInput-root": {
              fontSize: "16px",
              height: "40px",
              background: "#FFFFFF",
              color: "#969696",
            },

            "&.MuiMenuItem-root": {
              height: "1000px !important",
              width: "100px",
              minHeight: "1000px",
            },
          }}
          MenuProps={MenuProps}
          multiple
          value={compatibleDeviceType}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => {
            return selected.length ? selected.join(", ") : "Select";
          }}
          data-testid="compatible-device-type"
        >
          {deviceTypeList?.map((deviceType: DeviceType) => (
            <MenuItem key={deviceType.id} value={deviceType.type}>
              <Checkbox
                size="small"
                checked={compatibleDeviceType.indexOf(deviceType.type) > -1}
              />
              <ListItemText
                sx={{
                  "& .MuiTypography-root": {
                    font: "14px SiemensSans,Arial",
                  },
                }}
                primary={deviceType.type}
              />
            </MenuItem>
          ))}
        </Select>
        <Box
          display="flex"
          sx={{
            gap: "18px",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingTop: "16px",
          }}
        >
          <Button
            variant="contained"
            disabled={props.softwareDetails.status !== "success"}
            sx={{
              width: "67px",
              height: "40px",
              borderRadius: "24px",
            }}
            onClick={() =>
              props.updateSoftware(
                props.softwareDetails.id,
                compatibleDeviceType.toString()
              )
            }
          >
            <Typography variant="h5" textTransform="none">
              {localized["save-btn"]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
