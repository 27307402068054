export function arraysEqual(arr1: any, arr2: any): boolean {
  // Early return if lengths are not equal
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Custom compare function to handle numeric and alphanumeric sorting
  const compareFunction = (a: any, b: any) => {
    const regex = /(\d+)|(\D+)/g;
    const aParts = a.toString().match(regex);
    const bParts = b.toString().match(regex);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || "";
      const bPart = bParts[i] || "";

      const aIsNumber = !isNaN(aPart);
      const bIsNumber = !isNaN(bPart);

      if (aIsNumber && bIsNumber) {
        const diff = parseInt(aPart, 10) - parseInt(bPart, 10);
        if (diff !== 0) return diff;
      } else {
        const comparison = aPart.localeCompare(bPart);
        if (comparison !== 0) return comparison;
      }
    }

    return 0;
  };

  // Sort the arrays using the custom compare function
  const sortedArr1 = [...arr1].sort(compareFunction);
  const sortedArr2 = [...arr2].sort(compareFunction);

  // Compare sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}
