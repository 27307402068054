import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export const selectSxStyle = {
  "&.MuiOutlinedInput-root": {
    height: "44px",
    background: "#FFFFFF",
    borderRadius: "8px",
    "& fieldset": {
      border: "1px solid #EAEAEA",
    },
    "&:hover fieldset": {
      border: "1px solid #EAEAEA",
    },
  },
  "& .MuiInputBase-input": {
    color: "#393939",
    fontSize: "16px",
  },
};


export const widthStyle = {
  "& .MuiInputBase-input": {
    width: "455px",
  },
};

export const mergedStylesWithWidth = {
  ...selectSxStyle,
  ...widthStyle,
};

export const selectMenuPropsStyle = {
  MenuListProps: {
    sx: {
      maxHeight: "calc(100vh - 280px)",
      "&.MuiList-root": {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
  },
  PaperProps: {
    sx: {
      "& .MuiMenuItem-root": {
        border: "1px solid #EAEAEA",
        height: "44px",
        color: "#15131F",
        fontSize: "16px",
      },
      "&.MuiPaper-root": {
        borderRadius: "8px",
      },
    },
  },
};

export const menuMaxHeightStyleSite = {
  maxHeight: "calc(100vh - 280px)",
  }

export const selectMenuPropsStyleSite = {
  MenuListProps: {
    sx: {
      ...menuMaxHeightStyleSite,
      "&.MuiList-root": {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
  },
  PaperProps: {
    sx: {
      "& .MuiMenuItem-root": {
        border: "1px solid #EAEAEA",
        height: "44px",
        color: "#15131F",
        fontSize: "16px",
      },
      "&.MuiPaper-root": {
        borderRadius: "8px",
      },
    },
  },
};
export const dataGridStyle = {
  borderColor: "#ADA9C2",
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1.8px solid #4D217A",
  },
  ".MuiInputBase-inputTypeSearch": {
    width: "580px",
    height: "56px",
    marginTop: "-10px",
    marginBottom: "-10px",
  },
  ".MuiSvgIcon-root": {
    width: "24px",
    height: "24px",
  },
  "MuiDataGrid-toolbarContainer": {
    marginTop: "24px",
  },
  ".MuiDataGrid-cellContent": {
    overflow: "visible",
  },
  ".MuiTablePagination-selectLabel": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
  },
  ".MuiTablePagination-displayedRows": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
  },
  ".MuiTablePagination-select": {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "normal",
    marginTop: "7px",
  },
  ".MuiDataGrid-virtualScroller": {
    overflowY: "auto !important",
    maxHeight: "50vh",
  },
};

export const dataGridStyleForAssigUserAndDeviceRegistration = {
  border: "0",
  borderColor: "#ADA9C2",
  marginTop: "5px",

  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "2px solid #1B1534;",
    fontSize: "20px",
  },
  ".MuiInputBase-inputTypeSearch": {
    width: "580px",
    height: "50px",
    marginTop: "-10px",
    marginBottom: "-10px",
  },
  ".MuiSvgIcon-root": {
    width: "24px",
    height: "26px",
  },
  "MuiDataGrid-toolbarContainer": {
    marginTop: "24px",
  },
  ".MuiDataGrid-cellContent": {
    overflow: "visible",
  },
  ".MuiTablePagination-selectLabel": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
  },
  ".MuiTablePagination-displayedRows": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "normal",
  },
  ".MuiTablePagination-select": {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "normal",
    marginTop: "7px",
  },
  ".MuiDataGrid-virtualScroller": {
    overflowY: "auto !important",
    maxHeight: "11vh",
  },
};

export const dialogMuiStyle = {
  overflowY: "unset",
  overflowX: "unset",
  ".MuiDialogTitle-root": {
    padding: "0 !important",
  },
  ".MuiDialogContent-root ": {
    padding: "0 !important",
  },
  ".MuiIconButton-root ": {
    padding: "0 !important",
  },
  ".MuiTypography-root ": {
    alignSelf: "center !important",
  },
  ".MuiDialog-paper ": {
    minWidth: "655px !important",
  },
};
export const dialogTitleStyle = {
  display: "flex",
  flexDirection: "row",
  borderBottom: "1px solid #C0C0C0",
  padding: "24px",
};

export const ListStyle = {
  overflowY: "auto",
  paddingBottom: "0px !important",
  paddingTop: "0px !important",
  width: "100%",
  background: "#ffffff",
  scrollbarWidth: "thin",
  height: "98%",
};
export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8A00E5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 8,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: "#8A00E5",
    boxSizing: "border-box",
  },
  "& .Mui-disabled": {
    color: "#fff !important",
  },
  "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#ADA9C2",
  },
}));
