import { LocationOnOutlined } from "@mui/icons-material";
import WifiIcon from "@mui/icons-material/Wifi";
import { Box, Stack, Typography } from "@mui/material";
import { Asset } from "../../../Models/models";
import { IotDeviceIcon } from "../../../theme/Icons/IshIcons";

type Props = {
  asset: Asset;
};
export const MarkerTooltip = (props: Props) => {
  const { asset } = props;
  return (
    <Stack direction="column" width="100%" alignItems="flex-start" gap="8px">
      <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
        <WifiIcon
          data-testid="status-icon"
          sx={{
            mt: "-3px",
            width: "16px",
            height: "16px",
            color: asset.status === "connected" ? "#14DA79" : "#5d596e",
          }}
        />
        <Typography
          variant="h5"
          lineHeight={"normal"}
          sx={{ wordWrap: "breakWord" }}
          align="left"
        >
          {asset.name}
        </Typography>
      </Stack>
      {asset.devicesInAsset.length > 0 && (
        <Stack
          gap={"4px"}
          direction={"row"}
          alignItems={"flex-start"}
          sx={{ marginLeft: "2px" }}
        >
          <IotDeviceIcon className="locationIcon" />

          <Typography
            className="deviceName"
            sx={{ maxWidth: "220px", wordWrap: "breakWord" }}
          >
            {asset.devicesInAsset.map((device: any) => device.name).join(",")}
          </Typography>
        </Stack>
      )}

      <Stack
        gap={"4px"}
        direction={"row"}
        alignItems={"center"}
        sx={{ marginLeft: "2px" }}
      >
        <Box alignContent={"center"}>
          <LocationOnOutlined className="locationIcon" />
        </Box>
        <Typography className="cityName">{asset.location}</Typography>
      </Stack>
    </Stack>
  );
};
