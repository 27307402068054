import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { WarningIcon, WindIcon } from "../../../theme/Icons/IshIcons";
import localized from "../../../en.json";

const ActiveNotifications = () => {
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const handleExpandClick = (index: number) => {
    const isCurrentlyExpanded = expandedItems.includes(index);
    if (isCurrentlyExpanded) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const truncateText = (text: string, length: number) => {
    return text.length > length ? text.substring(0, length - 3) + "..." : text;
  };

  const dummyDataActiveNotifications = [
    {
      id:1,
      title: "Error notification title 1",
      description:
        "description 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "September 26, 2023 11:30",
      type: "error",
    },
    {
      id:2,
      title: "Warning notification title 2",
      description:
        "description 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "September 27, 2023 12:00",
      type: "warning",
    },
    {
      id:3,
      title: "Warning notification title 2",
      description:
        "description 2.",
      date: "September 27, 2023 12:00",
      type: "warning",
    },
    {
      id:4,
      title: "Error notification title 2",
      description:
        "description 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "September 27, 2023 12:00",
      type: "error",
    },
  ];
  return (
    <>
      <Typography variant="h6" fontWeight="400" padding="0px 24px 16px 24px">
        {localized["active-notifications"]}
      </Typography>

      <Card
        sx={{
          boxShadow: "none",
          margin: "0px 24px 24px 24px",
          border: "1px solid #DEDEDE",
          borderRadius: "8px !important",
          ".MuiCardContent-root": {
            padding: "0px !important",
          },
        }}
      >
        {dummyDataActiveNotifications.map((data, index) => (
          <React.Fragment key={data.id}>
            {index > 0 && <Divider />}
            <CardContent>
              <Box display="flex" alignItems="flex-start" padding="16px">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mr: 2, mt: 0.5 }}
                >
                  <Box
                    height="30px"
                    width="28px"
                    sx={{
                      backgroundColor:
                        data.type === "error" ? "#DA1E28" : "#E2D000",
                      borderRadius: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 1,
                    }}
                  >
                    <WarningIcon
                      style={{
                        fontSize: 20,
                        color: data.type === "error" ? "#fff" : "#000",
                      }}
                    />
                  </Box>
                  <Box
                    height="30px"
                    width="28px"
                    sx={{
                      backgroundColor: "#F2F2F2",
                      borderRadius: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <WindIcon
                      style={{ fontSize: 20, color: "#000", marginTop: "7px" }}
                    />
                  </Box>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center" >
                    
                    <Typography variant="h6" fontWeight="400">
                      {data.title}
                    </Typography>
                    <IconButton
                      onClick={() => handleExpandClick(index)}
                      aria-expanded={expandedItems.includes(index)}
                      aria-label="show more"
                      sx={{marginLeft:"auto"}}
                      
                    >
                      <ExpandMoreIcon
                        style={{ 
                          transform: expandedItems.includes(index)
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          color: "#000",
                        }}
                      />
                
                    </IconButton>
                  </Box>
                  <Collapse in={expandedItems.includes(index)} timeout={0}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      fontWeight="400"
                    >
                      {data.description}
                    </Typography>
                  </Collapse>
                  {!expandedItems.includes(index) && (
                    <Typography
                      variant="h5"
                      fontWeight="400"
                      color="textSecondary"
                    >
                      {truncateText(data.description, 45)}
                    </Typography>
                  )}
                  <Typography
                    variant="h5"
                    fontWeight="400"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    {data.date}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      mt: 1,
                      border: "1px solid #DEDEDE",
                      height: "32px",
                      width: "128px",
                      borderRadius: "4px",

                    }}
                  >
                    <IconButton aria-label="check" sx={{padding:'6px 15px' , borderRadius:"0px",width:"64px"}}>
                      <CheckIcon
                        style={{ height: "20px", width: "20px", color: "#000" }}
                      />
                    </IconButton>
                    <div
                      style={{
                        borderRight: "1px solid #DEDEDE",
                        height: "100%",
                      }}
                    ></div>
                    <IconButton aria-label="close" sx={{padding:'6px 15px', borderRadius:"0px", width:"64px"}}>
                      <CloseIcon
                        style={{ height: "20px", width: "20px", color: "#000" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </React.Fragment>
        ))}
      </Card>
    </>
  );
};

export default ActiveNotifications;
