import { z, ZodString, ZodTypeAny } from "zod";
import { Field, FormFields } from "../Models/models";

const messageSecurityModeDropdown = ["BESTAVAILABLE", "NONE", "SIGN","SIGNENCRYPT"];
const securityPolicyDropdown = ["BESTAVAILABLE", "NONE", "BASIC128RSA15", "BASIC256", "BASIC256SHA256", "AES128_SHA256_RSAOAEP", "AES256_SHA256_RSAPSS"];

export const opcUaFormFields: FormFields = {
  sections: [
    {
      title: "General",
      fields: [
        { label: "Session Name", type: "text", name: "name", required: true, placeholder: "Enter session name", validation: { regex: /(?=\S)(?:(?!^\s*$).*)/, max: 50 , regexMessage: "Name cannot be empty"  } },
        { label: "Endpoint URL", type: "text", name: "endpointUrl", required: true, placeholder: "://0.000.00.0", validation: { regex: /^opc\.tcp:\/\/([a-zA-Z0-9.-]+)(:\d{1,5})?(\/[a-zA-Z0-9._/-]*)?$/i, regexMessage: "Enter correct endpoint URL"  } },
        { label: "Username", type: "text", name: "username", required: true, placeholder: "Enter username" },
        { label: "Password", type: "password", name: "password", required: true, placeholder: "Enter password" }
      ]
    },
    {
      title: "Security",
      fields: [
        { label: "Message Security Mode", type: "dropdown", name: "messageSecurityMode", required: true, placeholder: "Select message security mode", dropdownValues: messageSecurityModeDropdown , defaultValue:"BESTAVAILABLE" },
        { label: "Security Policy", type: "dropdown", name: "securityPolicy", required: true, placeholder: "Select security policy", dropdownValues: securityPolicyDropdown , defaultValue:"BESTAVAILABLE" },
        { label: "Locale IDs", type: "dropdown", name: "localeIds", required: true, placeholder: "Enter localeIds", defaultValue: "en-US", dropdownValues: ["en-US"] }
      ]
    },
    {
      title: "Timeout details",
      fields: [
        { label: "Browse Timeout", type: "number", name: "browseCallTimeout", required: true, defaultValue: 1000, validation: { min: 100, max: 10000 }, unit: "ms" },
        { label: "Discovery Timeout", type: "number", name: "discoveryTimeout", required: true, defaultValue: 1000, validation: { min: 500, max: 30000 }, unit: "ms" },
        { label: "Publish Timeout", type: "number", name: "publishTimeout", required: true, defaultValue: 1000, validation: { min: 100, max: 10000 }, unit: "ms" },
        { label: "Session Timeout", type: "number", name: "sessionTimeout", required: true, defaultValue: 30000, validation: { min: 5000, max: 60000 }, unit: "ms" },
        { label: "Watchdog Timeout", type: "number", name: "watchdogTimeout", required: true, defaultValue: 10000, validation: { min: 1000, max: 30000 }, unit: "ms" },
        { label: "Read Timeout", type: "number", name: "readCallTimeout", required: true, defaultValue: 1000, validation: { min: 100, max: 10000 }, unit: "ms" },
        { label: "Write Timeout", type: "number", name: "writeCallTimeout", required: true, defaultValue: 1000, validation: { min: 100, max: 10000 }, unit: "ms" },
        { label: "Call Timeout", type: "number", name: "callCallTimeout", required: true, defaultValue: 1000, validation: { min: 100, max: 10000 }, unit: "ms" }
      ]
    },
    {
      title: "Reconnection details",
      fields: [
        { label: "Reconnect", type: "number", name: "reconnectDelay", required: true, defaultValue: 5000, validation: { min: 1000, max: 60000 }, unit: "ms" },
        { label: "Persistent", type: "checkbox", name: "persistent", required: false },
        { label: "Auto-reconnect", type: "checkbox", name: "autoReconnect", required: false }
      ]
    },
    {
      title: "Subscribe nodes",
      fields: [ ]
    }
  ]
};

const createStringSchema = (field: Field): ZodString => {
  let schema = z.string().min(1, `${field.label} is required`);
  
  if (field.validation) {
    const { regex, max, regexMessage } = field.validation;
    if (regex) {
      schema = schema.regex(regex, regexMessage || "Incorrect format");
    }
    if (max !== undefined) {
      schema = schema.max(max, `${field.label} should be between 1-${max} chars`);
    }
  }
  
  return schema;
};

const createSchemaNumber = ( validation?: { min?: number, max?: number }) => {
  let numberSchema = z.number();
  if (validation) {
    const { min, max } = validation;
    if (min !== undefined) {
      numberSchema = numberSchema.min(min, `Value must be between ${min}-${max}`);
    }
    if (max !== undefined) {
      numberSchema = numberSchema.max(max, `Value must be between ${min}-${max}`);
    }
  }
  return z.preprocess(val => typeof val === "string" || typeof val === "number" ? Number(val) : val, numberSchema);
};

const createFieldSchema = (field: Field): ZodTypeAny => {
  switch (field.type) {
    case "text":
    case "password":
      return createStringSchema(field);
    case "number":
      return createSchemaNumber(field.validation);
    case "dropdown":
      return z.enum(field.dropdownValues as [string, ...string[]]);
    case "checkbox":
      return z.boolean().optional();
    default:
      return z.any();
  }
};

const createDynamicSchema = (formConfig: FormFields) => {
  const schemaFields: { [key: string]: ZodTypeAny } = {};

  formConfig.sections.forEach(section => {
    section.fields.forEach(field => {
      let fieldSchema = createFieldSchema(field);
      if (!field.required) {
        fieldSchema = fieldSchema.optional();
      }
      schemaFields[field.name] = fieldSchema;
    });
  });
  return z.object(schemaFields);
};

export const opcUaFormSchema = createDynamicSchema(opcUaFormFields);
