import { useApolloClient } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ROLES } from "../../Models/models";
import { useAuthToken } from "../../auth";
import localized from "../../en.json";
import { setUserInitial } from "../ApplicationBar/ApplicationBar";
import { RoleContext } from "../Home/Home";

export const Logout = (props: any) => {
  const [role] = useState<ROLES | null>(null);
  const [userInitials, setUserInitials] = useState("");
  const { instance, accounts } = useMsal();
  const [userFirstName, serUserFirstName] = useState<string | undefined>("");
  const [userEmail, seruserEmail] = useState("");
  const [, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userLastName, serUserLastName] = useState<string | undefined>("");
  const [, , removeAuthToken] = useAuthToken();
  const apolloClient = useApolloClient();
  const [userRole, setUserRole] = useState<string | undefined>("");
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };
  const roleMapping = useMemo(() => {
    return {
      ADMIN: "Administrator",
      NONADMIN: "Non-Administrator",
    };
  }, []);
  const roleContext = useContext(RoleContext);
  const handleLogout = async () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
    await apolloClient.clearStore();
    removeAuthToken();
  };
  useEffect(() => {
    setUserInitial(accounts, setUserInitials);
    if (roleContext?.currRole) {
      setUserRole(roleMapping[roleContext?.currRole]);
    }

    if (accounts) {
      const firstName = accounts[0]?.name?.split(",")[1]?.trim();
      const lastName = accounts[0]?.name?.split(",")[0]?.trim();
      serUserFirstName(firstName?.split("(")[0]?.trim());
      serUserLastName(lastName?.trim());
      seruserEmail(accounts[0]?.username);
    }
  }, [accounts, roleContext?.currRole, roleMapping]);

  function handleProfilePopUp(event: MouseEvent | TouchEvent): void {
    props.handleProfileClose();
  }

  return (
    <Box>
      <ClickAwayListener onClickAway={handleProfilePopUp}>
        <Box
          sx={{ background: "#ffffff" }}
          width="300px"
          alignContent="center"
        >
          <RoleContext.Provider
            value={useMemo(() => ({ currRole: role }), [role])}
          >
            <Box marginTop="16px">
              <Avatar
                sx={{
                  fontSize:"22px !important",
                  lineHeight:"30px",
                  bgcolor: "#EAEAEA",
                  color: "#0D0D0D",
                  marginLeft: "120px",
                  width: "48px",
                  height: "48px",
                }}
              >
                {userInitials}
              </Avatar>
              <Box
                display="flex"
                gap="5px"
                fontWeight="700"
                fontSize="25px"
                justifyContent="center"
              >
                <Typography
                  fontSize="25px"
                  fontWeight="700"
                  data-testid="firstName"
                  sx={{ color: "#1B1534", marginTop: "16px" }}
                >
                  {userFirstName}
                </Typography>
                <Typography
                  fontSize="25px"
                  fontWeight="700"
                  sx={{ color: "#1B1534", marginTop: "16px" }}
                >
                  {userLastName}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              paddingTop="16px"
            >
              <AccountCircleOutlinedIcon
                sx={{
                  marginLeft: "16px",
                  marginRight: "8px",
                  width: "16px"
                }}
              />
              <Typography
                sx={{ color: "#1B1534" }}
                fontSize="12px"
                fontWeight="400px"
              >
                {userRole}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              paddingTop="1px"
              paddingBottom="16px"
            >
              <MailOutlineIcon
                sx={{
                  marginLeft: "16px",
                  marginRight: "8px",
                  width: "16px"
                }}
              />
              <Typography
                sx={{ color: "#1B1534" }}
                fontSize="12px"
                fontWeight="400px"
              >
                {userEmail}
              </Typography>
            </Box>
            <Divider sx={{ color: "#C0C0C0" }} />
            <Box
              display="flex"
              sx={{ justifyContent: "flex-end" }}
              fontSize="12px"
              fontWeight="400"
              marginTop="8px"
              marginBottom="8px"
            >
              <LogoutIcon
                data-testid="icon"
                sx={{
                  color: "#1B1534",
                  marginTop: "7px",
                  width: "20px",
                  height: "20px",
                  marginRight: "-10px",
                }}
              />
              <MenuItem
                data-testid="logout"
                sx={{ color: "#1B1534" }}
                onClick={() => {
                  handleProfileMenuClose();
                  handleLogout();
                }}
              >
                <Typography
                  fontSize="14px"
                  fontWeight="400px"
                  marginRight="0px"
                >
                  {localized["logout"]}
                </Typography>
              </MenuItem>
            </Box>
          </RoleContext.Provider>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};
