import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  backgroundColor: "#FFF",
  "&:hover": {
    backgroundColor: "#FFF",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#393939",
  width: "450px",
  minWidth: "320px",
  maxWidth: "100%",
  borderRadius: "8px",
  border: "1px solid #EAEAEA",
  fontFamily: "SiemensSans,Arial",
  fontSize: "16px",
  lineHeight: "24px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "&:focus": {
        color: "#15131F",
      },
    },
  },
}));
