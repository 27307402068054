import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import CommunicationProtocolForm from "./CommunicationProtocolForm/CommunicationProtocolForm";
import EdgeDeviceListAndTab from "./EdgeDeviceListAndTab/EdgeDeviceListAndTab";

interface PropTypes {
  siteId: number;
}
const EdgeDevices = (props: PropTypes) => {
  const { siteId } = props;
  const [isDeviceConfigUpdated, setIsDeviceConfigUpdated] = useState(false);
  return (
   <Routes>
            <Route
              path="/*"
              element={
                <EdgeDeviceListAndTab
                  siteId={siteId}
                  setIsDeviceConfigUpdated={setIsDeviceConfigUpdated}
                  isDeviceConfigUpdated={isDeviceConfigUpdated}
                />
              }
            />
             <Route
              path="/device/:deviceId/newprotocol/:protocolId"
              element={
               <CommunicationProtocolForm/>
              }
            />
             <Route
              path="/device/:deviceId/editprotocol/:protocolId/:protocolConfigId"
              element={
               <CommunicationProtocolForm/>
              }
            />
          </Routes> 
  );
};

export default EdgeDevices;
