import { FetchResult } from "@apollo/client";
import localized from "../../../../en.json";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";

export const enableDisableApiSuccess = (
  response: FetchResult<any>,
  actionType: string,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
  softwareName:string
) => {
  const errorMessage = `${localized["failed-to"]} ${actionType} ${localized["software"]} '${softwareName}'`;
  const successMessage = `${localized["software"]} '${softwareName}' ${actionType}d ${localized["successfully"]}`;
  const status = response.data?.updateSoftwareStateOnDevice?.status;

  ShowSnackbar(
    status === "done" ? successMessage : errorMessage,
    status === "done",
    enqueueSnackbar
  );
};

export const titleCase = (str: string): string => str.replace(/\b\w/g, char => char.toUpperCase());

