import { Box, Button, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import localized from '../en.json';

export const deviceMonitoringStyles = {
    display: "flex",
    flexWrap: "wrap",
    "& > :not(style)": {
        padding: "24px 40px",
        gap: "24px",
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #C0C0C0",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
    },
    marginTop: "24px",
};

export function renderHeading(headingName: string, onClickViewDetails: React.MouseEventHandler<HTMLButtonElement> | undefined) {
    return (
        <Box display="flex" sx={{ justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ fontSize: "25px" }}>
                {headingName}
            </Typography>
            <Button
                endIcon={
                    <ArrowForwardIcon sx={{ width: "24px", height: "24px" }} />
                }
                onClick={onClickViewDetails}
                sx={{
                    width: "118px",
                    height: "44px",
                    textTransform: "none",
                    borderRadius: "24px",
                }}
            >
                <Typography variant="h5">{localized["history-button"]}</Typography>
            </Button>
        </Box>
    )
}