import { gql, useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import localized from "../../../../en.json";
import { Software,IdAndName } from "../../../../Models/models";

import { SoftwareContext } from "../../../../store/software-list-context";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { arraysEqual, isEqualArraysOfObjects } from "../../../../util/ConstantUtils";

export const SAVE_CUST_SOFTWARE = gql`
  mutation ($softwareID: Int!, $customerIDs: [Int]! ) {
    saveCustomerSoftware(softwareID: $softwareID, customerIDs: $customerIDs ) 
  }
`;

interface PropType {
  open: boolean;
  onDialogClose: Function;
  software: Software;
  allCustomers: IdAndName[];
}

const sortedCustomers = (allCustomers: IdAndName[]) => {
  return allCustomers.sort((a: IdAndName, b: IdAndName) => a.name.localeCompare(b.name))
};

export const CustomerAccessDialog = (props: PropType) => {
  const [selectedCustomers, setSelectedCustomers] = useState<IdAndName[]>(
    []
  );
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const softwareContext = useContext(SoftwareContext);

  useEffect(() => {
    setSelectedCustomers(props.software.customers);
  }, [props.open, props.software.customers]);

  const onCheckedCustomersHandler = (
    customer: IdAndName,
    isChecked: boolean
  ) => {
    const updatedCustomers = isChecked
      ? [...selectedCustomers, customer]
      : selectedCustomers.filter((item) => item.name !== customer.name);
    setSelectedCustomers(updatedCustomers);
  };

  const onAllSelectChangeHandler = (isChecked: boolean) => {
    const updatedCustomers = isChecked ? [...props.allCustomers] : [];
    setSelectedCustomers(updatedCustomers);
  };

  const updateSoftware = () => {
    const selectedCustomerIds = selectedCustomers.map((customer) => customer.id);
    client
      .mutate({
        mutation:  SAVE_CUST_SOFTWARE,
        variables: {
          softwareID: props.software.id,
          customerIDs: selectedCustomerIds,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        props.onDialogClose();
        softwareContext?.softwareListChangeHandler();
        ShowSnackbar(
          localized["customer-access-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        ShowSnackbar(
          localized["customer-access-failed"],
          false,
          enqueueSnackbar
        );
      });
  };
  return (
    <Dialog
      disableEscapeKeyDown
      open={props.open}
      sx={{
        overflowY: "unset",
        overflowX: "unset",
        ".MuiDialogTitle-root": {
          padding: "0 !important",
        },
        ".MuiDialogContent-root ": {
          padding: "0 !important",
        },
        ".MuiIconButton-root ": {
          padding: "0 !important",
        },
        ".MuiTypography-root ": {
          alignSelf: "center !important",
        },
        ".MuiDialog-paper ": {
          minWidth: "596px !important",
        },
        ".MuiDialogActions-root":{
          padding: "0 !important",
        }
      }}
    >
      <DialogTitle id="customer-access-dialog">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding:"32px"
  
          }}
        >
          <Typography variant="h4" width="500px" fontWeight="400">
            {localized["customer-access"]}
          </Typography>

          <IconButton
            data-testid="close-customer-access"
            aria-label="close-icon-button"
            onClick={() => props.onDialogClose()}
          >
            <CloseIcon
              sx={{
                color: "#1B1534",
                width: "32px",
                height: "32px",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Box padding={"32px"}>
            <Typography className="customer-text" variant="h5" color={"#1B1534"}>
              {localized["software"]}
            </Typography>
            <Typography
              variant="body1"
              fontSize="22px"
              textTransform={"none"}
              color={"#1B1534"}
              marginTop={"2px"}
            >
              {props.software.name}
            </Typography>
            <Typography variant="h5"
              marginTop={"24px"}
              marginBottom={"8px"}
               color={"#1B1534"}
            >
              {localized["select-customer-for-access"]}
            </Typography>
            <Box
              sx={{
                borderBottom:"1px solid #C0C0C0",
                borderTop:"1px solid #C0C0C0",
                maxHeight: "332px",
                overflowY: "auto",
                marginRight: "40px",
                "&::-webkit-scrollbar": {
                  width: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f2f2f2",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#c0c0c0",
                  borderRadius: "4px",
                },
              }}
            >
              <Stack
                direction="row"
                padding={"16px"}
                border={"1px solid #C0C0C0"}
                gap={"8px"}
                maxHeight={"54px"}
              >
                <Checkbox
                  onChange={(event) => {
                    onAllSelectChangeHandler(event.target.checked);
                  }}
                  checked={arraysEqual(selectedCustomers, props.allCustomers)}
                  sx={{
                    color: "#8A00E5",
                    padding: 0,
                    ".MuiCheckbox-root": {
                      padding: "0px",
                    },
                  }}
                />
                <Typography
                  fontSize={"18px"}
                  fontWeight={400}
                  lineHeight={"22px"}
                  color={"#000"}
                >
                  {localized["select-all"]}
                </Typography>
              </Stack>
              {sortedCustomers(props.allCustomers)
                .map((customer: IdAndName) => {
                  return (
                    <Stack
                      direction="row"
                      padding={"16px"}
                      border={"1px solid #C0C0C0"}
                      gap={"8px"}
                      key={customer.id}
                    >
                      <Checkbox
                        checked={selectedCustomers?.some(
                          (item: any) => item.name === customer.name
                        )}
                        onChange={(event) => {
                          onCheckedCustomersHandler(
                            customer,
                            event.target.checked
                          );
                        }}
                        sx={{
                          color: "#8A00E5",
                          padding: 0,
                          ".MuiCheckbox-root": {
                            padding: "0px",
                          },
                        }}
                      />
                      <Typography
                        fontSize={"18px"}
                        fontWeight={400}
                        lineHeight={"22px"}
                        color={"#000"}
                      >
                        {customer.name}
                      </Typography>
                    </Stack>
                  );
                })}
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack width={"100%"}>
          <Box/>
          <Stack  padding={"32px"}>
            <Button
              variant="contained"
              onClick={updateSoftware}
              disabled={arraysEqual(props.software.customers, selectedCustomers)}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "93%",
                height: "40px",
                textTransform: "none",
                borderRadius: "24px",
                padding: "8px 16px",
              }}
            >
              <Typography variant="h5">{localized["update-access"]}</Typography>
            </Button>
          </Stack>
        </Stack>
      </DialogActions>  
    </Dialog>
  );
};

