import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import localized from "../../../../en.json";
import { ConfirmationDialog } from "../../../../util/ConfirmationDialog";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { getSearchInputBox } from "../MarkDeviceFatReady/MarkDeviceFatReady";
export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));
interface PropTypes {
  open: boolean;
  handleClose: () => void;
  customerId: string | undefined;
  customerName: string;
  setIsCustomerUpdated:Function;
}

export const GET_UNREGISTERED_DEVICES = gql`
  query ($status: [String]) {
    getDevicesByStatus(status: $status) {
      id
      name
    }
  }
`;

export const GET_ASSIGNED_DEVICES = gql`
  query ($customerID: Int!) {
    getDevicesByCustomerID(customerID: $customerID) {
      id
      name
      site {
        id
      }
    }
  }
`;

export const ASSIGN_DEVICES = gql`
  mutation ($deviceIDs: [String]!, $customerID: Int!) {
    assignDevicesToCustomer(deviceIDs: $deviceIDs, customerID: $customerID)
  }
`;
export const UN_ASSIGN_DEVICE = gql`
  mutation ($deviceID: String!) {
    unregisterDeviceFromCustomer(deviceID: $deviceID)
  }
`;
const AssignEdgeDeviceDialog = (props: PropTypes) => {
  const { open, handleClose, customerId, customerName,setIsCustomerUpdated } = props;
  const [unregisteredDevices, setUnregisteredDevices] = useState([]);
  const [assignedDevices, setAssignedDevices] = useState([]);
  const client = useApolloClient();
  const [loadingUnregistered, setLoadingUnregistered] = useState(true);
  const [loadingAssigned, setLoadingAssigned] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [, setFetchDevices] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedDeviceIdToUnassign, setSelectedDeviceIdToUnassign] =
    useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isUnassignDialogOpen, setIsUnassignDialogOpen] =
    useState<boolean>(false);

  const getUnregisteredDevices = () => {
    client
      .query({
        query: GET_UNREGISTERED_DEVICES,
        variables: {
          status: ["UnRegistered"],
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<any>) => {
        setUnregisteredDevices(response.data.getDevicesByStatus);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-unregistered-devices"],
          false,
          enqueueSnackbar
        );
      })
      .finally(() => {
        setLoadingUnregistered(false);
      });
  };

  const getAssignedDevices = () => {
    client
      .query({
        query: GET_ASSIGNED_DEVICES,
        variables: {
          customerID: customerId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<any>) => {
        setAssignedDevices(response.data.getDevicesByCustomerID);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-assigned-devices"],
          false,
          enqueueSnackbar
        );
      })
      .finally(() => {
        setLoadingAssigned(false);
      });
  };
  useEffect(() => {
    if (open) {
      getAssignedDevices();
      getUnregisteredDevices();
    }
  }, [open]);

  const handleCheckboxChange = (id: string) => {
    setSelectedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((item) => item !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredUnregisteredDevices = filterDevices(
    unregisteredDevices,
    searchQuery
  );
  const filteredAssignedDevices = filterDevices(assignedDevices, searchQuery);
  const assignedDevicesToCustomers = () => {
    const assignDevices = () => {
      client
        .mutate({
          mutation: ASSIGN_DEVICES,
          variables: {
            deviceIDs: selectedIds,
            customerID: customerId,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          getAssignedDevices();
          getUnregisteredDevices();
          ShowSnackbar(
            localized["edge-devices-assigned-to"] + customerName,
            true,
            enqueueSnackbar
          );
        })
        .catch(() => {
          ShowSnackbar(
            localized["failed-assign-devices"],
            false,
            enqueueSnackbar
          );
        })
        .finally(() => {
          setSelectedIds([]);
          setFetchDevices(false);
        });
    };
    assignDevices();
  };
  const unAssignDeviceHandler = () => {
    client
      .mutate({
        mutation: UN_ASSIGN_DEVICE,
        variables: {
          deviceID: selectedDeviceIdToUnassign,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        getAssignedDevices();
        getUnregisteredDevices();
        setIsUnassignDialogOpen(false);
        ShowSnackbar(
          localized["edge-devices-unassigned-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-unassign-device"],
          false,
          enqueueSnackbar
        );
      })
      .finally(() => {
        setSelectedDeviceIdToUnassign("");
      });
  };
  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          border: "1px solid #EAEAEA",
          borderRadius: "8px",
          ".MuiDialog-paper ": {
            maxWidth: "1290px !important",
            width: "1290px !important",
          },
        }}
      >
        <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
          <DialogTitle
            sx={{
              m: 0,
              fontWeight: "400",
              fontSize: "28px",
              lineHeight: "36px",
            }}
            id="customized-dialog-title"
          >
            {localized["edge-devices-assigned-to"]} {customerName}
          </DialogTitle>
          <IconButton
            data-testid="closeBtn"
            aria-label="close"
            onClick={() => {
              setSelectedIds([]);
              handleClose();
              setIsCustomerUpdated(true);
              setAssignedDevices([]);
              setUnregisteredDevices([]);
            }}
            sx={{
              position: "absolute",
              right: 32,
              top: 24,
              color: "#0D0D0D",
            }}
          >
            <CloseIcon
              style={{
                width: "32px",
                height: "32px",
              }}
            />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "32px",
            gap: "16px",
          }}
        >
          {getSearchInputBox(searchQuery, handleSearchChange)}
          <Box
            display="flex"
            justifyContent="space-between"
            height="574px"
            maxHeight="80%"
          >
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: "734px",
                border: "1px solid #DEDEDE",
                borderRadius: "8px",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#FFFFFF",
                  textTransform: "none",
                  color: "#0D0D0D",
                  padding: "16px",
                  borderBottom: "1px solid #DEDEDE",
                }}
              >
                {localized["assigned-devices-header"]}
              </Typography>
              {loadingAssigned ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="50vh"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {" "}
                  {filteredAssignedDevices?.map((iotDevice: any) => (
                    <Box
                      key={iotDevice?.id}
                      display="flex"
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px 20px",
                        borderBottom: "1px solid #DEDEDE",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          maxWidth: "700px",
                          fontWeight: "400",
                          wordWrap: "break-word",
                        }}
                      >
                        {iotDevice?.name}
                      </Typography>
                      <LightTooltip
                        placement="top"
                        title={iotDevice.site !== null ? "Device in use" : ""}
                      >
                        <Link
                          disabled={iotDevice.site !== null}
                          component="button"
                          variant="body2"
                          underline="none"
                          onClick={() => {
                            setSelectedDeviceIdToUnassign(iotDevice.id);
                            setIsUnassignDialogOpen(true);
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color:
                                iotDevice.site !== null ? "#ADA9C2" : "#B96CFF",
                              borderBottom: "1px solid #B96CFF",
                            }}
                          >
                            {localized["unassign"]}
                          </Typography>
                        </Link>
                      </LightTooltip>
                    </Box>
                  ))}
                </>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: "460px",
                border: "1px solid #DEDEDE",
                borderRadius: "8px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  maxHeight: "90%",
                  overflowY: "auto",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#FFFFFF",
                    textTransform: "none",
                    color: "#0D0D0D",
                    padding: "16px",
                    borderBottom: "1px solid #DEDEDE",
                  }}
                >
                  {localized["unregistered-devices-header"]}
                </Typography>

                {loadingUnregistered ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="40vh"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {" "}
                    {filteredUnregisteredDevices?.map((iotDevices: any) => (
                      <FormGroup
                        key={iotDevices.id}
                        sx={{
                          padding: "6px 20px",
                          borderBottom: "1px solid #DEDEDE",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedIds.includes(iotDevices.id)}
                              onChange={() =>
                                handleCheckboxChange(iotDevices.id)
                              }
                            />
                          }
                          label={
                            <Typography
                              variant="h5"
                              sx={{
                                maxWidth: "400px",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              {iotDevices.name}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    ))}
                  </>
                )}
              </Box>
              <Box sx={{ padding: "16px", borderTop: "1px solid #DEDEDE" }}>
                <Button
                  data-testid="assignBtn"
                  disabled={!selectedIds.length}
                  variant="contained"
                  onClick={assignedDevicesToCustomers}
                  startIcon={
                    <ArrowBackIosIcon
                      sx={{
                        ".MuiButton-startIcon": { margin: "0px" },
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  }
                  sx={{ height: "40px", borderRadius: "24px", width: "430px" }}
                >
                  <Typography variant="h5" sx={{ textTransform: "none" }}>
                    {localized["assign-device-btn"]}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        isDialogOpen={isUnassignDialogOpen}
        handleCloseDialog={setIsUnassignDialogOpen}
        title={localized["unassign-device"]}
        description={localized["unassign-confirmation"]}
        leftButtonText={localized["cancel-btn"]}
        rightButtonText={localized["unassign-confirm"]}
        leftButtonClickHandler={setIsUnassignDialogOpen}
        rightButtonClickHandler={unAssignDeviceHandler}
        isWarning={false}
      />
    </>
  );
};
const filterDevices = (devices: any[], query: string) =>
  devices.filter((device) =>
    device.name.toLowerCase().includes(query.toLowerCase())
  );
export default AssignEdgeDeviceDialog;
