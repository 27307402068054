import React, { useEffect, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import localized from "../../en.json";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, Button, FormControlLabel, IconButton, Divider, Box, Typography } from '@mui/material';
import { gql, useApolloClient } from '@apollo/client';
import ShowSnackbar from '../CustomizedSnackbar/ShowSnackbar';
import { useSnackbar } from 'notistack';
type DataPrivacyDialogProps = {
    openDataPrivacyDialog: boolean
    email: string;
    handleDataPrivacyDialogClose: Function;
};
export const ACCEPT_DATA_PRIVACY = gql`
mutation ($emailID: String!) {
 acceptDataPrivacy(emailID: $emailID)
}
`;

export const DataPrivacyDialog = (props: DataPrivacyDialogProps) => {
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(props.openDataPrivacyDialog);

    useEffect(() => {
        setOpen(props.openDataPrivacyDialog);
    }, [props.openDataPrivacyDialog]);

    const client = useApolloClient();
    const { enqueueSnackbar } = useSnackbar();

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleClose = () => {
        setOpen(false);
        props.handleDataPrivacyDialogClose();

    }
    const handleConfirm = async () => {
        client
            .mutate({
                mutation: ACCEPT_DATA_PRIVACY,
                variables: {
                    emailID: props.email,
                },
                fetchPolicy: "no-cache",
            })
            .then((response: any) => {
                setOpen(false);
                ShowSnackbar(
                    localized["data-privacy-success-msg"],
                    true,
                    enqueueSnackbar
                );
            })
            .catch(() => {
                props.handleDataPrivacyDialogClose();
            })
            .finally(() => {
                setOpen(false);
            })
    };

    return (
        <div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "8px",
                    ".MuiDialog-paper ": {
                        maxWidth: "1100px !important",
                        width: "1290px !important",
                    },
                }}
                onClose={handleClose}
            >
                <DialogTitle sx={{
                    m: 0,
                    fontWeight: "400",
                    fontSize: "28px",
                    lineHeight: "36px",
                    width: "400px",
                }}
                    id="dataprivacy-dialog-title">
                    {localized["data-privacy-agreement"]}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#aaa',
                        }}
                    >
                        <CloseIcon />
                    </IconButton></DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>
                        {localized["data-agreement-content1"]}
                        <a
                            href="https://www.siemens-energy.com/global/en/home/general/privacy-notice.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            privacy notice
                        </a>
                        <br /><br />
                        {localized["data-agreement-content2"]}
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions >
                    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" padding='10px'>
                        <FormControlLabel
                            control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
                            label={localized["terms-and-conditions"]}
                        />
                        <Button
                            disableRipple
                            data-testid='confirmButton'
                            variant="contained"
                            sx={{
                                borderRadius: "24px",
                                height: "44px",
                                alignItems: "center",
                            }}
                            disabled={!checked}
                            onClick={handleConfirm}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    textTransform: "none",
                                }}
                            >
                                {localized["confirm"]}
                            </Typography>
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DataPrivacyDialog;
