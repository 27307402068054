export const tableRowStyle = {
    color: "#000",
    fontWeight: "400",
    fontSize: "18px",
    padding: "18px",
    paddingRight: "0px"
}
export const tablePaginationStyle = {
    ".MuiPagination-ul": {
        borderRadius: "4px",
        border: "1px solid #EAEAEA"
    },
    ".MuiButtonBase-root": {
        background: "#fff",
        margin: "0px",
        width: "48px",
        height: "48px",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        borderRadius: "0px",
        border: "0px",
        borderRight: "1px solid #EAEAEA",
    },
    ".Mui-selected": {
        color: "#fff",
        backgroundColor: "#8A00E5 !important",
    }
}
export const tablePaginationDropDownStyle = {
    width: "78px",
    height: "48px",
    border: "1px solid #EAEAEA",
    background: "#fff",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    ".MuiOutlinedInput-notchedOutline": {
        border: "none"
    }
}