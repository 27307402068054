import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Asset } from "../../../Models/models";
import { GpsIcon } from "../../../theme/Icons/IshIcons";
import AssetDetailsDrawer from "../AssetDetailsDrawer/AssetDetailsDrawer";

interface PropType {
  selectedMarkers: Asset[];
  onMarkerClickHandler: Function;
}
const ClusterInfoWindow = (props: PropType) => {
  const [openAssetDetailDrawer, setAssetDetailDrawer] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<Asset>();

  return (
    <Box sx={{ textAlign: "left", maxWidth: "40vh", minWidth: "10vh" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GpsIcon
          sx={{
            height: "16px",
            width: "16px",
            marginTop: props.selectedMarkers[0].location ? "-0.5vh" : "0vh",
            color: "#5D596E",
          }}
        />
        <Typography
          variant="caption"
          sx={{
            paddingBottom: "8px",
            marginLeft: "4px",
            paddingTop: "3px",
          }}
        >
          {props.selectedMarkers[0].location}
        </Typography>
      </Box>

      {props.selectedMarkers.map((marker: Asset) => (
        <>
          <Link
            component="button"
            sx={{
              display: "flex",
              flexDirection: "column",
              border: "0px",
              alignItems: "center",
              "&:focus": {
                outline: "none",
              },
            }}
            onClick={() => {
              props.onMarkerClickHandler(marker);
              setAssetDetailDrawer(true);
              setSelectedMarker(marker);
            }}
            key={marker.id}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  color: "#1B1534",
                  textTransform: "none",
                  padding: "5px",
                  fontSize: "16px",
                }}
              >
                {marker.name.length > 50
                  ? marker.name.substring(0, 47) + "..."
                  : marker.name}
              </Typography>
              <ChevronRightIcon sx={{ width: "16px", height: "16px" }} />
            </Box>
          </Link>
        </>
      ))}
      {selectedMarker && (
        <AssetDetailsDrawer
          open={openAssetDetailDrawer}
          asset={selectedMarker}
          handleOpen={() => {
            setAssetDetailDrawer(!openAssetDetailDrawer);
          }}
        />
      )}
    </Box>
  );
};

export default ClusterInfoWindow;
