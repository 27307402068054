import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Stack, Box, InputLabel, Typography, FormControl, IconButton } from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import localized from "../../../../../en.json";
import CloseIcon from "@mui/icons-material/Close";
import { handleNumberInputKeyDown, signalPathRegex } from '../../../../../util/ConstantUtils';
import { SignalRow } from '../../../../../Models/models';


const pattern: string =
    "^(?![\\s_-])[A-Za-z0-9](?:[A-Za-z0-9 _-]*[A-Za-z0-9])?\\s*$";

export const regex: RegExp = new RegExp(pattern, "i");

const outlinedErrorStyles={ ".MuiOutlinedInput-root": { width: "240px", height: "44px" },   
".Mui-error": {
    margin: "0px ",
}, }
const schema = z.object({
    displayName: z
        .string()
        .min(1, { message: localized["uid"] + localized["is-required"] })
        .regex(regex, {
            message: localized["only-alphanumeric-allowed"],
        })
        .max(50, {
            message: localized["uid"] + localized["name_max_validation"],
        }),
    path: z.string()
        .regex(signalPathRegex, 'Enter a correct Path')
        .min(1, { message: localized["path"] + localized["is-required"] }),
    unit: z.string().min(1, { message: localized["unit"] + localized["is-required"] }),
    pollingTime: z.preprocess(val => {
        const num = typeof val === "string" || typeof val === "number" ? Number(val) : val;
        return Number.isNaN(num) ? undefined : num;
    }, 
    z.number()
        .positive({ message: "Value " + localized["must-be-between"] + "10 - 60000" })
        .min(10, { message: "Value " + localized["must-be-between"] + "10 - 60000" })
        .max(60000, { message: "Value " + localized["must-be-between"] + "10 - 60000" })
    ),
    threshold: z.preprocess(val => {
        const num = typeof val === "string" || typeof val === "number" ? Number(val) : val;
        return Number.isNaN(num) ? undefined : num;
    }, 
    z.number()
        .positive({ message: "Value " + localized["must-be-a-positive-number"] })
    ),
});

type FormValues = z.infer<typeof schema>;
type AddNodeDialogProps = {
    isOpen: boolean;
    setIsOpen: Function;
    addNodeHandler?: (node: FormValues) => void;
    tableData: SignalRow[];
};

export const AddNodeDialog = (props: AddNodeDialogProps) => {
    const { register, handleSubmit, setValue, formState: { errors, isValid }, reset, watch } = useForm<FormValues>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            pollingTime: 1000
        }
    });

    const [pathError, setPathError] = useState<string | null>(null);

    useEffect(() => {
        const subscription = watch((value) => {
            if (props.tableData.some(item => item.path === value.path)) {
                setPathError('Node Path already exists.');
            } else {
                setPathError(null);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, props.tableData]);

    const onSubmit = (data: FormValues) => {
        reset();
        if (props.addNodeHandler) {
            props.addNodeHandler(data);
        }
    };

    const handleClose = () => {
        reset();
        props.setIsOpen(false);
    };

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={props.isOpen}
            fullWidth
            maxWidth="md"
            sx={{ border: "1px solid #EAEAEA", borderRadius: "8px" }}
        >
            <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
                <DialogTitle
                    sx={{
                        m: 0,
                        fontWeight: "400",
                        fontSize: "28px",
                        lineHeight: "36px",
                    }}
                    id="customized-dialog-title"
                >
                    {localized["new-node"]}
                </DialogTitle>
                <IconButton
                    data-testid="closeBtn"
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 32,
                        top: 24,
                        color: "#0D0D0D",
                    }}
                >
                    <CloseIcon
                        style={{
                            width: "32px",
                            height: "32px",
                        }}
                    />
                </IconButton>
            </Box>
            <DialogContent sx={{ padding: "32px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="row" gap={"16px"} flexWrap={"wrap"}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <InputLabel htmlFor="uid">
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: "8px",
                                        color: "#15131F",
                                        span: {
                                            color: "#DA1E28",
                                        },
                                    }}
                                >
                                    {localized["uid"]}
                                    <span>*</span>
                                </Typography>
                            </InputLabel>
                            <FormControl sx={{ marginBottom: "16px" }}>
                                <TextField
                                    data-testid="uid"
                                    id="uid"
                                    placeholder={localized["uid"]}
                                    {...register("displayName")}
                                    error={!!errors.displayName}
                                    helperText={errors.displayName?.message}
                                    sx={outlinedErrorStyles}
                                />
                            </FormControl>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <InputLabel htmlFor="path">
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: "8px",
                                        color: "#15131F",
                                        span: {
                                            color: "#DA1E28",
                                        },
                                    }}
                                >
                                    {localized["path"]}
                                    <span>*</span>
                                </Typography>
                            </InputLabel>
                            <FormControl sx={{ marginBottom: "16px" }}>
                                <TextField
                                    id="path"
                                    placeholder={localized["path"]}
                                    {...register("path")}
                                    error={!!errors.path || !!pathError}
                                    helperText={errors.path?.message || pathError}
                                    sx={outlinedErrorStyles}
                                />
                            </FormControl>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <InputLabel htmlFor="unit">
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: "8px",
                                        color: "#15131F",
                                        span: {
                                            color: "#DA1E28",
                                        },
                                    }}
                                >
                                    {localized["unit"]}
                                    <span>*</span>
                                </Typography>
                            </InputLabel>
                            <FormControl sx={{ marginBottom: "16px" }}>
                                <TextField
                                    id="unit"
                                    placeholder={localized["unit"]}
                                    {...register("unit")}
                                    error={!!errors.unit}
                                    helperText={errors.unit?.message}
                                    sx={outlinedErrorStyles}
                                />
                            </FormControl>
                        </Box>
                    </Stack>
                    <Stack direction="row" gap={"16px"} flexWrap={"wrap"}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <InputLabel htmlFor="pollingTime">
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: "8px",
                                        color: "#15131F",
                                        span: {
                                            color: "#DA1E28",
                                        },
                                    }}
                                >
                                    {localized["polling-time (ms)"]}
                                    <span>*</span>
                                </Typography>
                            </InputLabel>
                            <FormControl sx={{ marginBottom: "16px" }}>
                                <TextField
                                    id="pollingTime"
                                    placeholder={localized["polling-time"]}
                                    type="number"
                                    {...register("pollingTime", { valueAsNumber: true })}
                                    error={!!errors.pollingTime}
                                    helperText={errors.pollingTime?.message}
                                    onKeyDown={handleNumberInputKeyDown}
                                    sx={outlinedErrorStyles}
                                />
                            </FormControl>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <InputLabel htmlFor="Threshold">
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: "8px",
                                        color: "#15131F",
                                        span: {
                                            color: "#DA1E28",
                                        },
                                    }}
                                >
                                    {localized["threshold"]}
                                    <span>*</span>
                                </Typography>
                            </InputLabel>
                            <FormControl sx={{ marginBottom: "16px" }}>
                                <TextField
                                    id="Threshold"
                                    placeholder={localized["threshold"]}
                                    type="number"
                                    {...register("threshold", { valueAsNumber: true })}
                                    error={!!errors.threshold}
                                    helperText={errors.threshold?.message}
                                    onKeyDown={handleNumberInputKeyDown}
                                    sx={outlinedErrorStyles}
                                />
                            </FormControl>
                        </Box>
                    </Stack>
                    <DialogActions
                        sx={{
                            flexDirection: "column",
                            alignItems: "center",
                            paddingTop: "42px",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: "none",
                                borderRadius: "24px",
                                width: "100%",
                            }}
                            type="submit"
                            disabled={!isValid || !!pathError}
                        >
                            {localized["add"]}
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddNodeDialog;
