import CloseIcon from "@mui/icons-material/Close";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import localized from "../../../../../en.json";

interface PropTypes {
    open: boolean;
    handleClose: () => void;
    openAssignDialog: Function
}

const NewCustomerSuccessDialog = (props: PropTypes) => {
    const {open, handleClose, openAssignDialog} = props;
    const openAssignDialogFn = () => {
        handleClose()
        openAssignDialog(true)
    }
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth="md"
            sx={{border: "1px solid #EAEAEA", borderRadius: "8px"}}
        >
            <Box display="flex" sx={{p: "16px", pl: "8px"}}>
                <DialogTitle
                    sx={{m: 0, fontWeight: "400", fontSize: "28px", lineHeight: "36px"}}
                    id="customized-dialog-title"
                >
                    {localized["new-customer-success-dialog-title"]}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 32,
                        top: 32,
                        color: "#0D0D0D",
                        width: "32px",
                        height: "32px",
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
            <DialogContent sx={{padding: "32px"}}>
                <Typography variant="body1">
                    {localized["new-customer-success-dialog-description"]}
                </Typography>
            </DialogContent>
            <DialogActions sx={{alignItems: "center", padding: "32px"}}>
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: "none",
                        borderRadius: "24px",
                        width: "100%",
                    }}
                    onClick={handleClose}
                >
                    {localized["i-will-do-it-later-button"]}
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        borderRadius: "24px",
                        width: "100%",
                    }}
                    onClick={openAssignDialogFn}
                >
                    {localized["assign-iot-devices-button"]}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewCustomerSuccessDialog;
