import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEventHandler, useContext, useEffect, useState } from "react";
import localized from "../../../../en.json";
import { DeviceNameAndID } from "../../../../Models/models";
import { ProjectListContext } from "../../../../store/projectList-context";
import { ConfirmationDialog } from "../../../../util/ConfirmationDialog";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../util/SearchStyleUtil";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";

interface PropTypes {
  open: boolean;
  handleClose: Function;
  customerId: number | undefined;
}

export const IS_DEVICE_CONFIG_ELG_FOR_DEL = gql`
  query ($deviceID: String!) {
    isDeviceConfigEligibleForDeletion(deviceID: $deviceID)
  }
`;

export const GET_FAT_READY_DEVICES = gql`
  query ($customerID: Int!, $status: [String]) {
    getDevicesByCustomerID(customerID: $customerID, status: $status) {
      id
      name
    }
  }
`;
export const UPDATE_DEVICE_FOR_FAT_READY = gql`
  mutation ($deviceID: String!) {
    updateDeviceForFatReady(deviceID: $deviceID)
  }
`;
const MarkDeviceFatReady = (props: PropTypes) => {
  const { open, handleClose, customerId } = props;
  const [fatReadyDevices, setFatReadyDevices] = useState<DeviceNameAndID[]>([]);
  const [filteredFatReadyDevices, setFilteredFatReadyDevices] = useState<
    DeviceNameAndID[]
  >([]);
  const projectListContext = useContext(ProjectListContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isFatReadyConfirmDialogOpen, setIsFatReadyConfirmDialogOpen] =
    useState<boolean>(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(
    undefined
  );
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setFilteredFatReadyDevices(
      fatReadyDevices.filter((device: DeviceNameAndID) =>
        device.name.toLowerCase().includes(event.target.value)
      )
    );
  };
  useEffect(() => {
    getFatReadyDevices();
  }, [customerId]);
  const getFatReadyDevices = () => {
    client
      .query({
        query: GET_FAT_READY_DEVICES,
        variables: {
          customerID: customerId,
          status: ["Configured"],
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<any>) => {
        setFatReadyDevices(response.data.getDevicesByCustomerID);
        setFilteredFatReadyDevices(response.data.getDevicesByCustomerID);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-fat-ready-eligible-devices"],
          false,
          enqueueSnackbar
        );
      });
  };
  const checkIfEligibleForConfigDeletion = (deviceId: string) => {
    client
      .query({
        query: IS_DEVICE_CONFIG_ELG_FOR_DEL,
        variables: {
          deviceID: deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<any>) => {
        setIsFatReadyConfirmDialogOpen(true);
        if (response.data.isDeviceConfigEligibleForDeletion) {
          setTitle(localized["mark-fat-ready-delete-config-title"]);
          setDescription(localized["mark-fat-ready-delete-config"]);
        } else {
          setTitle(localized["mark-fat-ready-not-delete-config-title"]);
          setDescription(localized["mark-fat-ready-not-delete-config"]);
        }
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-check-if-ready-for-config-delete"],
          false,
          enqueueSnackbar
        );
      });
  };
  const markDeviceFatReady = () => {
    client
      .mutate({
        mutation: UPDATE_DEVICE_FOR_FAT_READY,
        variables: {
          deviceID: selectedDeviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        ShowSnackbar(
          localized["edge-devices-marked-fat-ready-succes"],
          true,
          enqueueSnackbar
        );
        getFatReadyDevices();
        setIsFatReadyConfirmDialogOpen(false);
        setSelectedDeviceId(undefined);
      })
      .catch(() => {
        ShowSnackbar(
          localized["edge-devices-marked-fat-ready-failed"],
          false,
          enqueueSnackbar
        );
      });
  };
  const getTextList = (
    listItem1: string,
    listItem2: string,
    listItem3: string
  ) => {
    return (
      <List sx={{ listStyleType: "disc", marginBottom: "32px" }}>
        <ListItem
          sx={{
            display: "list-item",
            marginLeft: "25px",
            padding: "4px",
          }}
        >
          <Typography variant="h5" fontWeight={400}>
            {listItem1}
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            display: "list-item",
            marginLeft: "25px",
            padding: "4px",
          }}
        >
          <Typography variant="h5" fontWeight={400}>
            {listItem2}
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            display: "list-item",
            marginLeft: "25px",
            padding: "1px",
          }}
        >
          <Typography variant="h5" fontWeight={400}>
            {listItem3}
          </Typography>
        </ListItem>
      </List>
    );
  };

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          border: "1px solid #EAEAEA",
          borderRadius: "8px",
          ".MuiDialog-paper ": {
            maxWidth: "1290px !important",
            width: "1290px !important",
          },
        }}
      >
        <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
          <DialogTitle
            sx={{
              m: 0,
              fontWeight: "400",
              fontSize: "28px",
              lineHeight: "36px",
              width: "400px",
            }}
            id="customized-dialog-title"
          >
            {localized["mark-fat-ready"]}
          </DialogTitle>
          <IconButton
            data-testid="closeBtn"
            aria-label="close"
            onClick={() => {
              handleClose(false);
              projectListContext?.getProjectsByCustId(customerId);
            }}
            sx={{
              position: "absolute",
              right: 32,
              top: 24,
              color: "#0D0D0D",
            }}
          >
            <CloseIcon
              style={{
                width: "32px",
                height: "32px",
              }}
            />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "32px",
            gap: "16px",
          }}
        >
          {getSearchInputBox(searchQuery, handleSearchChange)}

          <Box display="flex" gap={"32px"} height="574px" maxHeight="80%">
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: "793px",
                border: "1px solid #DEDEDE",
                borderRadius: "8px",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#FFFFFF",
                  textTransform: "none",
                  color: "#0D0D0D",
                  padding: "16px",
                  borderBottom: "1px solid #DEDEDE",
                }}
              >
                {localized["configured-devices"]}
              </Typography>

              <>
                {" "}
                {filteredFatReadyDevices?.map((iotDevice: any) => (
                  <Box
                    key={iotDevice?.id}
                    display="flex"
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "16px 20px",
                      borderBottom: "1px solid #DEDEDE",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        maxWidth: "700px",
                        fontWeight: "400",
                        wordWrap: "break-word",
                      }}
                    >
                      {iotDevice?.name}
                    </Typography>

                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={() => {
                        setSelectedDeviceId(iotDevice.id);
                        checkIfEligibleForConfigDeletion(iotDevice.id);
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#B96CFF",
                          borderBottom: "1px solid #B96CFF",
                        }}
                      >
                        {localized["fat-ready"]}
                      </Typography>
                    </Link>
                  </Box>
                ))}
              </>
            </Box>
            <Stack maxWidth={"380px"}>
              <Typography variant="h5">
                {localized["before-fat-ready"]}
              </Typography>
              {getTextList(
                localized["before-fat-ready-list-item-1"],
                localized["before-fat-ready-list-item-2"],
                localized["before-fat-ready-list-item-3"]
              )}
              <Divider />
              <Typography variant="h5" marginTop={"32px"}>
                {localized["after-fat-ready"]}
              </Typography>
              {getTextList(
                localized["after-fat-ready-list-item-1"],
                localized["after-fat-ready-list-item-2"],
                localized["after-fat-ready-list-item-3"]
              )}
            </Stack>
          </Box>
          {isFatReadyConfirmDialogOpen && (
            <ConfirmationDialog
              isDialogOpen={isFatReadyConfirmDialogOpen}
              handleCloseDialog={setIsFatReadyConfirmDialogOpen}
              title={title}
              description={description}
              leftButtonText={localized["cancel-btn"]}
              rightButtonText={localized["yes-proceed"]}
              leftButtonClickHandler={setIsFatReadyConfirmDialogOpen}
              rightButtonClickHandler={markDeviceFatReady}
              isWarning={false}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MarkDeviceFatReady;
export const getSearchInputBox = (
  value: string,
  handleSearch: ChangeEventHandler
) => {
  return (
    <Search
      sx={{
        display: "flex",
        height: "44px",
        width: "240px !important",
        marginLeft: "0px !important",
      }}
    >
      <SearchIconWrapper>
        <SearchIcon sx={{ width: "24px", height: "24px", color: "#5D596E" }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        sx={{ minWidth: "240px" }}
        value={value}
        onChange={handleSearch}
      />
    </Search>
  );
};
