import { gql, useApolloClient } from "@apollo/client";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Menu, MenuItem, Tabs } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import localized from "../../../../../../en.json";
import { ConfirmationDialog } from "../../../../../../util/ConfirmationDialog";
import { RenderTab, TabPanel } from "../../../../../../util/TabPanelProps";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";
import CommunicationProtocolTab from "./CommunicationProtocolTab";
import EdgeDeviceConfigurationForm from "./EdgeDeviceConfigurationForm";
import SignalMappingTree from "./SignalMappingTree";

interface PropTypes {
  setIsDeviceConfigUpdated: Function;
}

export const UNASSIGN_DEVICE = gql`
  mutation ($deviceID: String!) {
    unAssignDeviceFromSite(deviceID: $deviceID)
  }
`;

const EdgeDevicesTab = (props: PropTypes) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setIsDeviceConfigUpdated } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [
    isUnassignDeviceConfirmationDialogOpen,
    setIsUnassignDeviceConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const location = useLocation();

  // Determine the current tab based on the current route
  const getTabValue = () => {
    const match = location.pathname.match(/\/tab(\d+)$/);
    if (match) {
      return parseInt(match[1], 10) - 1; // Convert 'tab1' to 0, 'tab2' to 1, etc.
    }
    return 0;
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIsDeviceConfigUpdated(false);
    setTabValue(newValue);
    const basePath = location.pathname.replace(/\/tab\d+$/, ""); // Remove the existing tab part
    navigate(`${basePath}/tab${newValue + 1}`); // Navigate to the new tab
  };
  const [tabValue, setTabValue] = useState(getTabValue());
  const { numberOfAssetsInDevice } = location.state || {};

  const { siteId, deviceId } = useParams<{
    siteId: string;
    deviceId: string;
  }>();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseUnassignDialog = () => {
    setIsWarning(false);
    setIsUnassignDeviceConfirmationDialogOpen(false);
  };
  const onUnassignDeviceConfirmHandler = () => {
    client
      .mutate({
        mutation: UNASSIGN_DEVICE,
        variables: {
          deviceID: deviceId,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        setIsUnassignDeviceConfirmationDialogOpen(false);
        ShowSnackbar(
          localized["edge-device-unassign-success"],
          true,
          enqueueSnackbar
        );
        navigate(`/engineering/site/${Number(siteId)}/edgedevices`);
      })
      .catch(() => {
        setIsUnassignDeviceConfirmationDialogOpen(false);
        ShowSnackbar(
          localized["edge-device-unassign-failed"],
          false,
          enqueueSnackbar
        );
      });
  };

  const handleUnassignClick = () => {
    setAnchorEl(null);
    setIsUnassignDeviceConfirmationDialogOpen(true);
    if (numberOfAssetsInDevice > 0) {
      setIsWarning(true);
      setTitle(localized["cannot-unassign-edge-device"]);
      setDescription(localized["unassign-edge-device-warning"]);
    } else {
      setTitle(localized["unassign-device"]);
      setDescription(localized["edge-device-unassign-confirmation"]);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" id="test">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Tabs
          id="tabstest"
          sx={{
            width: "calc(100vw - 814px)",
            "& .MuiTabs-indicator": { borderBottom: "4px solid #8A00E5" },
            "& .MuiTab-root": { padding: "16px 24px", minHeight: "54px" },
            "& .MuiTab-root.Mui-selected": { padding: "16px 24px" },
          }}
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="edge devices tabs"
        >
          {RenderTab(localized["configuration-tab"], 0)}
          {RenderTab(localized["signal-mapping-tab"], 1)}
          {RenderTab(localized["Communication-protocols-tab"], 2)}
        </Tabs>
        <IconButton
          data-testid="unassign-icon"
          aria-label="more"
          aria-controls="options-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          sx={{ marginRight: "16px" }}
        >
          <MoreVertIcon
            style={{
              color: "#8A00E5",
            }}
          />
        </IconButton>
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={handleUnassignClick}
            sx={{
              width: "Fixed (182px)px",
              height: "Hug (48px)px",
            }}
          >
            {localized["unassign-device"]}
          </MenuItem>
        </Menu>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <EdgeDeviceConfigurationForm
          setIsDeviceConfigUpdated={setIsDeviceConfigUpdated}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SignalMappingTree
          setIsDeviceConfigUpdated={setIsDeviceConfigUpdated}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CommunicationProtocolTab
          setIsDeviceConfigUpdated={setIsDeviceConfigUpdated}
        />
      </TabPanel>
      {isUnassignDeviceConfirmationDialogOpen && (
        <ConfirmationDialog
          isDialogOpen={isUnassignDeviceConfirmationDialogOpen}
          handleCloseDialog={handleCloseUnassignDialog}
          title={title}
          description={description}
          leftButtonText={localized["cancel-btn"]}
          rightButtonText={localized["yes-unassign-device"]}
          leftButtonClickHandler={handleCloseUnassignDialog}
          rightButtonClickHandler={onUnassignDeviceConfirmHandler}
          isWarning={isWarning}
        />
      )}
    </Box>
  );
};

export default EdgeDevicesTab;
