import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import localized from "../../../../../../en.json";
import {
  DeviceTestConnection,
  GetDevicesById,
} from "../../../../../../Models/models";
import { handleNumberInputKeyDown, handleNumberInputKeyDownNegative, ipv4Regex, ipv6Regex } from "../../../../../../util/ConstantUtils";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";

export const GET_DEVICE_DETAIL = gql`
  query ($deviceId: ID!) {
    getDeviceById(deviceId: $deviceId) {
      id
      name
      macAddress
      ipAddress
      portNumber
      deviceUsername
      devicePassword
      deviceStatus
      latitude
      longitude
      firmwareVersion
      serialId
      type
      numberOfAssetsInDevice
    }
  }
`;
export const DEVICE_TEST_CONNECTION = gql`
  query (
    $deviceId: String!
    $ipAddress: String!
    $portNumber: Int!
    $deviceUsername: String!
    $devicePassword: String!
  ) {
    deviceTestConnection(
      deviceConnectionRequest: {
        deviceId: $deviceId
        ipAddress: $ipAddress
        portNumber: $portNumber
        deviceUsername: $deviceUsername
        devicePassword: $devicePassword
      }
    ) {
      macAddress
      serialId
      firmwareVersion
      deviceType
    }
  }
`;

export const DEVICE_SAVE_CONFIG = gql`
  mutation (
    $deviceId: ID!
    $macAddress: String!
    $ipAddress: String!
    $portNumber: Int!
    $deviceUsername: String!
    $devicePassword: String!
    $latitude: Float!
    $longitude: Float!
    $firmwareVersion: String!
    $serialId: String!
    $type: String!
  ) {
    saveDeviceConfiguration(
      deviceId: $deviceId
      deviceConfigurationRequest: {
        macAddress: $macAddress
        ipAddress: $ipAddress
        portNumber: $portNumber
        deviceUsername: $deviceUsername
        devicePassword: $devicePassword
        latitude: $latitude
        longitude: $longitude
        firmwareVersion: $firmwareVersion
        serialId: $serialId
        type: $type
      }
    ) {
      id
    }
  }
`;

const inputPropsStyle = {
  width: "260px",
  height: "7px",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
};
const labelDisableStyle = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20px",
  marginBottom: "8px",
  color: "#969696",
};
const labelEnableStyle = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20px",
  marginBottom: "8px",
  color: "#0D0D0D",
};

const isIPv4 = (value: string) => {
  return ipv4Regex.test(value);
};

const isIPv6 = (value: string) => {
  return ipv6Regex.test(value);
};

function getInputType(showPassword: boolean) {
  return showPassword ? "text" : "password";
}

function renderPasswordVisibilityIcon(showPassword: boolean) {
  return showPassword ? <VisibilityOff /> : <Visibility />;
}

let validationSchemaTestConnection = z.object({
  portNumber: z
    .preprocess(
      (value) => {
        if (value === "" || value === undefined) return undefined;
        const number = Number(value);
        return isNaN(number) ? undefined : number;
      },
      z
        .number()
        .int()
        .positive()
        .min(1, localized["port-number-valid-range"])
        .max(65535, localized["port-number-valid-range"])
        .refine((value) => !Number.isNaN(value), {
          message: "Invalid number provided.",
        })
    )
    .nullable(),
  ipAddress: z
    .string()
    .min(1, { message: localized["required-field"] })
    .refine(
      (value) => (isIPv4(value) || isIPv6(value)) && !value.startsWith("127."),
      {
        message: localized["enter-correct-ip-address"],
      }
    ),
  deviceUsername: z
    .string()
    .min(1, { message: localized["required-field"] })
    .trim(),
  devicePassword: z
    .string()
    .min(1, { message: localized["required-field"] })
    .trim(),
});

let validationSchemaSaveConfig = z.object({
  latitude: z
    .preprocess((value) => {
      if (value === "" || value === undefined) return undefined;
      const number = Number(value);
      return isNaN(number) ? undefined : number;
    }, z.number().min(-90, localized["latitude-valid-range"]).max(90, localized["latitude-valid-range"]))
    .refine((value) => !Number.isNaN(value), {
      message: "Invalid number provided.",
    })
    .nullable(),
  longitude: z
    .preprocess((value) => {
      if (value === "" || value === undefined) return undefined;
      const number = Number(value);
      return isNaN(number) ? undefined : number;
    }, z.number().min(-180, localized["longitude-valid-range"]).max(180, localized["longitude-valid-range"]))
    .refine((value) => !Number.isNaN(value), {
      message: "Invalid number provided.",
    })
    .nullable(),
});

type FormValuesTestConnection = z.infer<typeof validationSchemaTestConnection>;
type FormValuesSaveConfig = z.infer<typeof validationSchemaSaveConfig>;

const initialValuesTestConnection: FormValuesTestConnection = {
  portNumber: null,
  ipAddress: "",
  deviceUsername: "",
  devicePassword: "",
};

const initialValuesSaveConfig: FormValuesSaveConfig = {
  latitude: null,
  longitude: null,
};

interface PropTypes {
  setIsDeviceConfigUpdated: Function;
}

const EdgeDeviceConfigurationForm = (props: PropTypes) => {
  const { setIsDeviceConfigUpdated } = props;
  let { deviceId } = useParams();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [portNumber, setPortNumber] = useState<number | null>();
  const [ipAddress, setIpAddress] = useState("");
  const [deviceUsername, setDeviceUsername] = useState<string | undefined>();
  const [devicePassword, setDevicePassword] = useState<string | undefined>();
  const [macAddress, setMacAddress] = useState("");
  const [serialId, setSerialId] = useState("");
  const [firmwareVersion, setFirmwareVersion] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [formValuesTestConnection, setFormValuesTestConnection] =
    useState<FormValuesTestConnection>(initialValuesTestConnection);
  const [formValuesSaveConfig, setFormValuesSaveConfig] =
    useState<FormValuesSaveConfig>(initialValuesSaveConfig);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [isConnectionSuccess, setIsConnectionSuccess] = useState(false);
  const [isConnectionFailed, setIsConnectionFailed] = useState(false);

  const {
    register: registerFormTestConnection,
    handleSubmit: handleSubmitFormTestConnection,
    reset: resetFormTestConnection,
    formState: {
      isDirty: isDirtyFormTestConnection,
      isValid: isValidFormTestConnection,
      errors: errorsFormTestConnection,
    },
  } = useForm<FormValuesTestConnection>({
    mode: "onChange",
    resolver: zodResolver(validationSchemaTestConnection),
    defaultValues: formValuesTestConnection,
  });

  const handleDeviceTestConnection = async (
    values: FormValuesTestConnection
  ) => {
    client
      .query({
        query: DEVICE_TEST_CONNECTION,
        variables: {
          deviceId: deviceId,
          ipAddress: values.ipAddress,
          portNumber: values.portNumber,
          deviceUsername: values.deviceUsername,
          devicePassword: values.devicePassword,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<DeviceTestConnection>) => {
        setMacAddress(response.data?.deviceTestConnection?.macAddress);
        setSerialId(response.data?.deviceTestConnection?.serialId);
        setDeviceType(response.data?.deviceTestConnection?.deviceType);
        setFirmwareVersion(
          response.data?.deviceTestConnection?.firmwareVersion
        );
        setIsConnectionSuccess(true);
        setIsConnectionFailed(false);
        setPortNumber(values.portNumber);
        setIpAddress(values.ipAddress);
        setDeviceUsername(values?.deviceUsername);
        setDevicePassword(values?.devicePassword);
      })
      .catch(() => {
        setIsConnectionSuccess(false);
        setIsConnectionFailed(true);
        setMacAddress("");
        setSerialId("");
        setFirmwareVersion("");
        ShowSnackbar(
          localized["failed-to-establish-connection"],
          false,
          enqueueSnackbar
        );
      });
  };

  const onSubmitTestConnection: SubmitHandler<FormValuesTestConnection> = (
    data
  ) => {
    handleDeviceTestConnection(data);
  };

  const {
    register: registerFormSaveConfig,
    handleSubmit: handleSubmitFormSaveConfig,
    reset: resetFormSaveConfig,
    formState: {
      isDirty: isDirtyFormSaveConfig,
      isValid: isValidFormSaveConfig,
      errors: errorsFormSaveConfig,
    },
    setValue: setValueFormSaveConfig,
  } = useForm<FormValuesSaveConfig>({
    mode: "onChange",
    resolver: zodResolver(validationSchemaSaveConfig),
    defaultValues: formValuesSaveConfig,
  });

  const handleDeviceSaveConfiguration = async (
    values: FormValuesSaveConfig
  ) => {
    client
      .mutate({
        mutation: DEVICE_SAVE_CONFIG,
        variables: {
          deviceId: deviceId,
          macAddress: macAddress,
          ipAddress: ipAddress,
          portNumber: portNumber,
          deviceUsername: deviceUsername,
          devicePassword: devicePassword,
          latitude: values.latitude,
          longitude: values.longitude,
          firmwareVersion: firmwareVersion,
          serialId: serialId,
          type: deviceType,
        },
      })
      .then(() => {
        setIsDeviceConfigUpdated(true);
        resetFormSaveConfig(values);
        ShowSnackbar(
          localized["saved-config-detail-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-save-config-detail"],
          false,
          enqueueSnackbar
        );
      });
  };

  const onSubmitSaveConfiguration: SubmitHandler<FormValuesSaveConfig> = (
    data
  ) => {
    handleDeviceSaveConfiguration(data);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: "latitude" | "longitude",
    decimalPlaces: number
  ) => {
    const { value } = event.target;
    const decimalIndex = value.indexOf(".");

    if (
      decimalIndex !== -1 &&
      value.length - decimalIndex - 1 > decimalPlaces
    ) {
      const truncatedValue = value.slice(0, decimalIndex + decimalPlaces + 1);
      setValueFormSaveConfig(field, Number(truncatedValue));
      event.target.value = truncatedValue;
    }
  };

  useEffect(() => {
    const getDeviceDetailById = async () => {
      client
        .query({
          query: GET_DEVICE_DETAIL,
          variables: {
            deviceId: deviceId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response: ApolloQueryResult<GetDevicesById>) => {
          const deviceDetails = response.data?.getDeviceById;
          resetFormTestConnection(deviceDetails);
          resetFormSaveConfig(deviceDetails);
          setFormValuesTestConnection(deviceDetails);
          setFormValuesSaveConfig(deviceDetails);
          setPortNumber(deviceDetails?.portNumber);
          setIpAddress(deviceDetails?.ipAddress);
          setDeviceUsername(deviceDetails?.deviceUsername);
          setDevicePassword(deviceDetails?.devicePassword);
          setMacAddress(deviceDetails?.macAddress);
          setSerialId(deviceDetails?.serialId);
          setFirmwareVersion(deviceDetails?.firmwareVersion);
          setDeviceType(deviceDetails?.type);
        })
        .catch(() => {
          setFormValuesTestConnection(initialValuesTestConnection);
          setFormValuesSaveConfig(initialValuesSaveConfig);
          setMacAddress("");
          setSerialId("");
          setFirmwareVersion("");
          ShowSnackbar(
            localized["failed-to-fetch-device-detail"],
            false,
            enqueueSnackbar
          );
        });
    };

    setIsConnectionSuccess(false);
    setIsConnectionFailed(false);
    getDeviceDetailById();
  }, [deviceId, client, enqueueSnackbar]);

  return (
    <>
      {deviceId !== undefined ? (
        <Stack
          sx={{
            gap: "32px",
            height: "auto",
            maxHeight: "calc(100vh - 301px)",
            overflowY: "auto",
          }}
        >
          <form
            onSubmit={handleSubmitFormTestConnection(onSubmitTestConnection)}
          >
            <Stack
              direction="row"
              sx={{
                flexWrap: "wrap",
                alignItems: "flex-start",
                marginTop: "24px",
                gap: "16px",
                maxWidth: "calc(100vw - 814px)",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="ip-address">
                  <Typography variant="h5" sx={labelEnableStyle}>
                    {localized["ip-address-label"]}
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="ip-address"
                    placeholder={localized["ip-address-label"]}
                    type="text"
                    {...registerFormTestConnection("ipAddress")}
                    error={errorsFormTestConnection.ipAddress !== undefined}
                    helperText={errorsFormTestConnection.ipAddress?.message}
                    inputProps={{
                      style: inputPropsStyle,
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="port-number">
                  <Typography variant="h5" sx={labelEnableStyle}>
                    {localized["port-number-label"]}
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="port-number"
                    type="number"
                    onKeyDown={handleNumberInputKeyDown}
                    placeholder={localized["port-number-label"]}
                    {...registerFormTestConnection("portNumber", {
                      valueAsNumber: true,
                    })}
                    error={errorsFormTestConnection.portNumber !== undefined}
                    helperText={errorsFormTestConnection.portNumber?.message}
                    inputProps={{
                      style: inputPropsStyle,
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="device-username">
                  <Typography variant="h5" sx={labelEnableStyle}>
                    {localized["device-username-label"]}
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="device-username"
                    placeholder={localized["username-placeholder"]}
                    type="text"
                    {...registerFormTestConnection("deviceUsername")}
                    error={
                      errorsFormTestConnection.deviceUsername !== undefined
                    }
                    helperText={
                      errorsFormTestConnection.deviceUsername?.message
                    }
                    inputProps={{
                      style: inputPropsStyle,
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="device-password">
                  <Typography variant="h5" sx={labelEnableStyle}>
                    {localized["device-password-label"]}
                  </Typography>
                </InputLabel>
                <FormControl>
                  <OutlinedInput
                    id="device-password"
                    type={getInputType(showPassword)}
                    placeholder={localized["password-placeholder"]}
                    {...registerFormTestConnection("devicePassword")}
                    error={
                      errorsFormTestConnection.devicePassword !== undefined
                    }
                    sx={{ background: "#fff" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {renderPasswordVisibilityIcon(showPassword)}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        width: "212px",
                        height: "7px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                      },
                    }}
                  />
                  <FormHelperText sx={{ color: "#DA1E28", marginLeft: "0px" }}>
                    {errorsFormTestConnection.devicePassword?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: "flex-start",
                marginTop: "20px",
                gap: "8px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                disabled={
                  !(isDirtyFormTestConnection && isValidFormTestConnection)
                }
                sx={{
                  textTransform: "none",
                  borderRadius: "24px",
                }}
              >
                <Typography variant="h5">
                  {localized["test-connection-button"]}
                </Typography>
              </Button>
              {isConnectionSuccess && (
                <Box
                  display="flex"
                  sx={{
                    marginTop: "5px",
                    marginLeft: "10px",
                    gap: "4px",
                  }}
                >
                  <CheckCircleOutlineIcon
                    sx={{
                      path: {
                        fill: "#007942",
                      },
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <Typography variant="caption" color="#007942">
                    {localized["connection-established-success"]}
                  </Typography>
                </Box>
              )}
              {isConnectionFailed && (
                <Box
                  display="flex"
                  sx={{
                    marginTop: "5px",
                    marginLeft: "10px",
                    gap: "4px",
                  }}
                >
                  <CancelIcon
                    sx={{
                      path: {
                        fill: "#DA1E28",
                      },
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <Typography variant="caption" color="#DA1E28">
                    {localized["failed-to-establish-connection"]}
                  </Typography>
                </Box>
              )}
            </Stack>
          </form>
          <form
            onSubmit={handleSubmitFormSaveConfig(onSubmitSaveConfiguration)}
          >
            <Stack
              direction="row"
              sx={{
                flexWrap: "wrap",
                alignItems: "flex-start",
                gap: "16px",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="latitude">
                  <Typography variant="h5" sx={{
                    lineHeight: "20px",
                    fontWeight: 400,
                    marginBottom: "8px",
                    fontSize: "12px",
                      color: "#0D0D0D",
                      span: {
                        color: "#DA1E28",
                      }
                    }}>
                    {localized["latitude-label"]}
                    <span>*</span>
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="latitude"
                    type="number"
                    onKeyDown={handleNumberInputKeyDownNegative}
                    placeholder={localized["latitude-label"]}
                    {...registerFormSaveConfig("latitude", {
                      valueAsNumber: true,
                    })}
                    error={errorsFormSaveConfig.latitude !== undefined}
                    helperText={errorsFormSaveConfig.latitude?.message}
                    inputProps={{
                      style: inputPropsStyle,
                      step: "0.00000001",
                      onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(event, "latitude", 8);
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="longitude">
                  <Typography variant="h5" sx={{
                      fontSize: "12px",
                      lineHeight: "20px",
                      marginBottom: "8px",
                      fontWeight: 400,
                      color: "#0D0D0D",
                      span: {
                        color: "#DA1E28",
                      }
                    }}>
                    {localized["longitude-label"]}
                    <span>*</span>
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="longitude"
                    type="number"
                    onKeyDown={handleNumberInputKeyDownNegative}
                    placeholder={localized["longitude-label"]}
                    {...registerFormSaveConfig("longitude", {
                      valueAsNumber: true,
                    })}
                    error={errorsFormSaveConfig.longitude !== undefined}
                    helperText={errorsFormSaveConfig.longitude?.message}
                    inputProps={{
                      style: inputPropsStyle,
                      step: "0.00000001",
                      onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(event, "longitude", 8);
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="device-type">
                  <Typography variant="h5" sx={labelDisableStyle}>
                    {localized["device-type-label"]}
                  </Typography>
                </InputLabel>
                <FormControl sx={{ textAlign: "left" }}>
                  <TextField
                    id="device-type"
                    placeholder={localized["device-type-label"]}
                    value={deviceType}
                    disabled
                    inputProps={{
                      style: inputPropsStyle,
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Stack>
            <Stack
              direction="row"
              sx={{
                flexWrap: "wrap",
                alignItems: "flex-start",
                gap: "16px",
                marginTop: "16px",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="mac-address">
                  <Typography variant="h5" sx={labelDisableStyle}>
                    {localized["mac-address-label"]}
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="mac-address"
                    placeholder={localized["mac-address-label"]}
                    type="text"
                    value={macAddress}
                    disabled
                    inputProps={{
                      style: inputPropsStyle,
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="serial-id">
                  <Typography variant="h5" sx={labelDisableStyle}>
                    {localized["serial-id-label"]}
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="serial-id"
                    placeholder={localized["serial-id-label"]}
                    value={serialId}
                    disabled
                    inputProps={{
                      style: inputPropsStyle,
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <InputLabel htmlFor="firmware-version">
                  <Typography variant="h5" sx={labelDisableStyle}>
                    {localized["firmware-version-label"]}
                  </Typography>
                </InputLabel>
                <FormControl>
                  <TextField
                    id="firmware-version"
                    placeholder={localized["firmware-version-label"]}
                    value={firmwareVersion}
                    disabled
                    inputProps={{
                      style: inputPropsStyle,
                    }}
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                disabled={!(isDirtyFormSaveConfig && isValidFormSaveConfig)}
                sx={{
                  textTransform: "none",
                  borderRadius: "24px",
                  height: "40px",
                  ":focus, :active, :enabled": {
                    background: "#8A00E5",
                  },
                  "&:disabled": {
                    backgroundColor: "#ADA9C2",
                  },
                }}
              >
                <Typography variant="h5" color="#fff">
                  {localized["save-configuration-button"]}
                </Typography>
              </Button>
            </Stack>
          </form>
        </Stack>
      ) : (
        <Typography>Select Device</Typography>
      )}
    </>
  );
};

export default EdgeDeviceConfigurationForm;
