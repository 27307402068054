import * as React from "react";
import { Box, CardContent } from '@mui/material';
import CardMedia from "@mui/material/CardMedia";
import seAppImage from "../theme/Icons/SE_App_Image.svg";
import { cardContentStyle, cardMediaStyle } from './CardStyleUtil';

type Props = {
    children: React.ReactNode
 }
const SoftwareCardUtil = (props: Props) => {
    return (
        <>
            <CardMedia
                sx={cardMediaStyle}
                image={seAppImage}
                title="ctrlx App"
            />
            <Box
                display="flex"
                flexDirection="column"
                sx={{ flex: "1 0 0", justifyContent: "space-between", gap: "24px" }}
            >
                <CardContent
                    sx={cardContentStyle}
                >
                    {props.children}
                </CardContent>
            </Box>
        </>
    )
}

export default SoftwareCardUtil;