import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { ArcElement, Chart, Legend, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { useLocation, useNavigate } from "react-router-dom";
import localized from "../../../en.json";
import { MemoryType } from "../../../Models/models";
import { deviceMonitoringStyles, renderHeading } from "../../../util/DeviceMonitoringUtil";

Chart.register(Tooltip, Title, ArcElement, Legend, ChartDataLabels);

function getValue(value: number | undefined) {
  return value ?? 0;
}
function getTotalMemoryValue(value: number | undefined) {
  return value ?? 1;
}

function renderMemoryLinearProgressBar(memory: number | undefined, totalMemory: number | undefined, labelText: string) {
  return <>
    <Box
      display="flex"
      sx={{ flexGrow: 1, gap: "21px", alignItems: "center" }}
    >
      <Typography
        variant="h6"
        color="#1B1534"
        sx={{ textAlign: "start", marginTop: "10px", width: "100px" }}
      >
        {labelText}
      </Typography>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Typography
          variant="overline"
          sx={{
            color: "#1B1534",
            textTransform: "none",
            textAlign: "start",
          }}
        >
          {memory} MB of{" "}
          {totalMemory} MB
        </Typography>
        <LinearProgress
          color="success"
          sx={{
            height: 8,
            backgroundColor: "#D9D9D9",
          }}
          variant="determinate"
          value={
            (getValue(memory) * 100) /
            getTotalMemoryValue(totalMemory)
          }
        />
      </Box>
    </Box>
    <Divider
      sx={{
        marginTop: "12px",
        marginBottom: "24px",
        borderBottomWidth: "1px",
        background: "#C0C0C0",
        width: "100%",
      }}
    />
  </>
}

interface PropsType {
  memory: MemoryType | undefined;
  setSignalsSelected: Function;
}

const Memory = (props: PropsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const assetDetails = location.state;

  const calcMemoryInPercentage = (value: number) => {
    return ((value * 100) / getTotalMemoryValue(props.memory?.totalMemory?.value)).toFixed(2);
  };
  const data = {
    labels: [
      localized["memory-used-label-chart"],
      localized["cache-label-chart"],
      localized["buffer-label-chart"],
      localized["free-label-chart"],
    ],
    datasets: [
      {
        data: [
          calcMemoryInPercentage(getValue(props.memory?.memoryUsed?.value)),
          calcMemoryInPercentage(getValue(props.memory?.cache?.value)),
          calcMemoryInPercentage(getValue(props.memory?.buffer?.value)),
          calcMemoryInPercentage(getValue(props.memory?.freeMemory?.value)),
        ],
        backgroundColor: ["#DA1E28", "#8A00E5", "#9747FF", "#007942"],
        hoverOffset: 4,
        label: "",
      },
    ],
  };

  const onClickViewDetails = () => {
    navigate("../datavisualization", {
      state: assetDetails,
    });
    props.setSignalsSelected([
      "framework/metrics/system/memused-mb",
      "framework/metrics/system/memfree-mb",
      "framework/metrics/system/memavailable-mb",
      "framework/metrics/system/memcache-mb",
      "framework/metrics/system/membuffers-mb",
    ]);
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        borderRadius: "5px",
        labels: {
          boxWidth: 10,
        },
      },

      datalabels: {
        formatter: (value: number) => {
          return value + "%";
        },
        display: true,
        color: "#ffffff",
        font: {
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#f2f2f2",
        titleColor: "#1B1534",
        titleFont: { weight: "bold" },
        bodyFont: { size: 14 },
        bodyColor: "#1B1534",
      },
    },
  };

  return (
    <Box sx={deviceMonitoringStyles}>
      <Paper elevation={0}>
        {renderHeading(localized["memory"], onClickViewDetails)}
        <Stack flexDirection="row" gap="80px" marginLeft="40px">
          <Box
            display="flex"
            sx={{ marginTop: "24px", width: "321px", height: "200px" }}
          >
            <Doughnut data={data} height={180} options={options} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ flexGrow: 1, marginTop: "20px", paddingRight: "100px" }}
          >
            {renderMemoryLinearProgressBar(props.memory?.freeMemory?.value, props.memory?.totalMemory?.value, localized["free-text"])}
            {renderMemoryLinearProgressBar(props.memory?.usageMemory?.value, props.memory?.totalMemory?.value, localized["usage-text"])}
            {renderMemoryLinearProgressBar(props.memory?.availableMemory?.value, props.memory?.totalMemory?.value, localized["available-text"])}
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default Memory;
