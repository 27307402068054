import { SelectChangeEvent } from "@mui/material";
import React from "react";
import { Order } from "../Models/models";

function toLowerCaseIfString(value: unknown): string | number {
  if (typeof value === "string") {
    return value.toLowerCase();
  }
  return value as number;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = toLowerCaseIfString(a[orderBy]);
  const bValue = toLowerCaseIfString(b[orderBy]);

  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
// Function to handle page change
export const handlePageChange =
  (setPage: React.Dispatch<React.SetStateAction<number>>) =>
  (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

// Function to handle rows per page change
export const handleRowsPerPageChange =
  (
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>,
    setPage: React.Dispatch<React.SetStateAction<number>>
  ) =>
  (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page
  };

// Function to calculate empty rows
export const calculateEmptyRows = (
  page: number,
  rowsPerPage: number,
  filteredRowsLength: number
) => {
  return page - 1 > 0
    ? Math.max(0, page * rowsPerPage - filteredRowsLength)
    : 0;
};

export const handleClick = (
  event: React.MouseEvent<unknown>,
  id: number,
  selected: readonly number[],
  setSelected: Function
) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected: readonly number[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  setSelected(newSelected);
};
