import { gql } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import localized from "../../../../../en.json";
import { ProjectListContext } from "../../../../../store/projectList-context";
import DetailsCard from "../../../../../util/DetailsCard";

export const GET_PROJECT_LIST_BY_CUSTID = gql`
  query ($customerID: ID!) {
    getProjects(customerID: $customerID) {
      id
      name
      numberOfAssets
      numberOfDevices
    }
  }
`;

interface PropTypes {
  customerId: string | undefined;
}

const ProjectCardList = (props: PropTypes) => {
  const { customerId } = props;
  const navigate = useNavigate();
  const projectListContext = useContext(ProjectListContext);

  const onProjectClickHandler = (projectId: number) => {
    navigate(`/engineering/project/${projectId}/sites`);
  };

  useEffect(() => {
    projectListContext?.getProjectsByCustId(customerId);
  }, [customerId]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        overflowY: "auto",
        scrollbarWidth: "thin",
        maxHeight: "calc(100vh - 510px)",
      }}
    >
      <>
        {projectListContext?.projectList.length ? (
          projectListContext?.projectList.map((project) => (
            <DetailsCard
              key={project.id}
              cardDetails={project}
              onClickHandler={onProjectClickHandler}
            />
          ))
        ) : (
          <Typography
            variant="overline"
            sx={{
              color: "#959595",
              textTransform: "none",
              fontWeight: "700",
              marginTop: "8px",
            }}
          >
            {localized["no-projects-to-show"]}
          </Typography>
        )}
      </>
    </Box>
  );
};

export default ProjectCardList;
