import { createContext, useMemo, useState } from "react";

interface ContexType {
  breadcrumbArray: any;
  setBreadCrumbsArray: Function;
}
export const BreadCrumbContext = createContext<ContexType | undefined>(
  undefined
);

export const BreadCrumbContextProvider = (props: any) => {
  const [breadcrumbArray, setBreadCrumbsArray] = useState([
    { name: "Monitor", link: "/dashboard" },
  ]);
  const contextValue = useMemo(() => {
    return {
      breadcrumbArray: breadcrumbArray,
      setBreadCrumbsArray: setBreadCrumbsArray,
    };
  }, [breadcrumbArray,setBreadCrumbsArray]);
  return (
    <BreadCrumbContext.Provider
      value={contextValue}
    >
      {props.children}
    </BreadCrumbContext.Provider>
  );
};

export default BreadCrumbContextProvider;