import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import {
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TableSortLabel,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useState } from "react";
import { Asset, Order } from "../../../Models/models";
import localized from "../../../en.json";
import { dropDownMenuLengths } from "../../../util/ConstantUtils";
import { selectMenuPropsStyle } from "../../../util/StyleUtil";
import {
  tablePaginationDropDownStyle,
  tablePaginationStyle,
  tableRowStyle,
} from "../../../util/TableStyleUtil";
import { getComparator, stableSort } from "../../../util/TableUtil";
import AssetDetailsDrawer from "../AssetDetailsDrawer/AssetDetailsDrawer";

interface Data {
  id: number;
  assetStatus: string;
  assetName: string;
  alarms: number;
  warnings: number;
  installedCapacity: string;
  assetType: string;
  siteOrProject: string;
  connectedEdgeDevices: string;
}

function createData(
  id: number,
  assetStatus: string,
  assetName: string,
  alarms: number,
  warnings: number,
  installedCapacity: string,
  assetType: string,
  siteOrProject: string,
  connectedEdgeDevices: string
): Data {
  return {
    id,
    assetStatus,
    assetName,
    alarms,
    warnings,
    installedCapacity,
    assetType,
    siteOrProject,
    connectedEdgeDevices,
  };
}

interface HeadCell {
  id: keyof Data;
  label: string | JSX.Element;
}

const getBackgroundColor = (id: keyof Data) => {
  if (id === "alarms") {
    return "#FCE9EA";
  } else if (id === "warnings") {
    return "#FDFBE6";
  } else {
    return "";
  }
};
const headCells: readonly HeadCell[] = [
  {
    id: "assetName",
    label: localized["asset-name-label"],
  },
  {
    id: "alarms",
    label: <ErrorOutlineOutlinedIcon sx={{ color: "#DA1E28" }} />,
  },
  {
    id: "warnings",
    label: <ErrorOutlineOutlinedIcon sx={{ color: "#E2D000" }} />,
  },
  {
    id: "installedCapacity",
    label: localized["installed-capacity-label"],
  },
  {
    id: "assetType",
    label: localized["asset-type-label"],
  },
  {
    id: "siteOrProject",
    label: localized["site"],
  },
  {
    id: "connectedEdgeDevices",
    label: localized["connected-edge-devices-label"],
  },
];

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        <TableCell key="statusCol" align="left" padding="none" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "alarms" || headCell.id === "warnings"
                ? "center"
                : "left"
            }
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#000000",
              fontWeight: "700",
              fontSize: "18px",
              background: getBackgroundColor(headCell.id),
              paddingRight:
                headCell.id === "alarms" || headCell.id === "warnings"
                  ? "0px !important"
                  : "",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="arrowRightCol" align="left" padding="none" />
      </TableRow>
    </TableHead>
  );
}

interface PropTypes {
  assets: Asset[];
}
const AssetTableView = (props: PropTypes) => {
  const { assets } = props;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Data[]>([]);
  const [selectedRow, setSelectedRow] = useState<Asset>();
  const [openAssetDetailDrawer, setAssetDetailDrawer] = useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  useEffect(() => {
    const rowsData: Data[] = [];
    assets.forEach((assetData) => {
      rowsData.push(
        createData(
          assetData.id,
          assetData.status,
          assetData.name,
          assetData.alarm,
          assetData.warning,
          assetData.capacity + " " + assetData.capacityUnit,
          assetData.type,
          assetData.site.name,
          assetData.devicesInAsset.map((device) => device.name).join(", ")
        )
      );
    });
    setRows(rowsData);
  }, [assets.length]);

  return (
    <>
      {rows.length > 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          alignItems="flex-start"
        >
          <Box
            sx={{
              width: "100%",
              minHeight: "calc(100vh - 475px)",
              border: "1px solid #DEDEDE",
              borderRadius: "8px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: "calc(100vh - 475px)",
                boxShadow: "none",
                borderRadius: "8px",
              }}
            >
              <Table stickyHeader aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        data-testid={`tableRow-${index}`}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          const selectedAsset = assets.find(
                            (id) => (id.id === row.id)
                          );
                          setSelectedRow(selectedAsset);
                          setAssetDetailDrawer(true);
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "18px !important",
                            paddingRight: "0px !important",
                          }}
                        >
                          <WifiOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color:
                                row.assetStatus === "connected"
                                  ? "#14DA79"
                                  : "rgba(0, 0, 0, 0.6)",
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={tableRowStyle}
                        >
                          {row?.assetName}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            fontWeight: "400",
                            fontSize: "18px",
                            padding: "18px",
                            paddingLeft: "0px",
                            paddingRight: "5px",
                            background:
                              row?.alarms > 0 ? "rgba(218, 30, 40, 0.1)" : "",
                          }}
                        >
                          {row?.alarms}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            fontWeight: "400",
                            fontSize: "18px",
                            padding: "18px",
                            paddingLeft: "0px",
                            paddingRight: "5px",
                            background:
                              row?.warnings > 0 ? "rgba(226, 208, 0, 0.1)" : "",
                          }}
                        >
                          {row?.warnings}
                        </TableCell>
                        <TableCell align="left" sx={tableRowStyle}>
                          {row?.installedCapacity}
                        </TableCell>
                        <TableCell align="left" sx={tableRowStyle}>
                          {row?.assetType}
                        </TableCell>
                        <TableCell align="left" sx={tableRowStyle}>
                          {row?.siteOrProject}
                        </TableCell>
                        <TableCell align="left" sx={tableRowStyle}>
                          {row?.connectedEdgeDevices}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "18px !important",
                            paddingRight: "0px !important",
                          }}
                        >
                          <IconButton
                            sx={{
                              padding: "0px !important",
                              marginRight: "12px",
                            }}
                          >
                            <KeyboardArrowRightOutlinedIcon
                              sx={{
                                width: "22px",
                                height: "22px",
                                color: "#8A00E5",
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} sx={{ borderBottom: "none" }} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box display="flex" sx={{ alignSelf: "center", gap: "16px" }}>
            <Pagination
              count={Math.ceil(rows?.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={tablePaginationStyle}
            />
            <Select
              labelId="row-per-page-select-label"
              id="row-per-page-select"
              value={rowsPerPage.toString()}
              onChange={handleChangeRowsPerPage}
              sx={tablePaginationDropDownStyle}
              MenuProps={selectMenuPropsStyle}
            >
              {dropDownMenuLengths.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      ) : (
        <Box height="calc(100vh - 410px)">
          <Typography
            variant="overline"
            sx={{ color: "#959595", fontWeight: "700", textTransform: "none" }}
          >
            {localized["start-by-creating-a-new-asset"]}
          </Typography>
        </Box>
      )}

      {selectedRow && (
        <AssetDetailsDrawer
          open={openAssetDetailDrawer}
          asset={selectedRow}
          handleOpen={() => {
            setAssetDetailDrawer(!openAssetDetailDrawer);
          }}
        />
      )}
    </>
  );
};

export default AssetTableView;
