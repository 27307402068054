import { Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BreadCrumbContext } from "../../store/breadcrumb-context";

const Notifications = () => {
  const location = useLocation();
  const breadCrumbContext = useContext(BreadCrumbContext);
  useEffect(() => {
    breadCrumbContext?.setBreadCrumbsArray([
      {
        name: "Active notifications",
        link: "/notifications/",
      },
    ]);
  }, [breadCrumbContext?.setBreadCrumbsArray, location]);
  return (
    <>
      <Typography variant="h4" sx={{ color: "#0D0D0D", padding: "10px" }}>
        Notifications
      </Typography>
    </>
  );
};

export default Notifications;
