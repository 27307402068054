import { Box, DialogTitle } from "@mui/material";
import { getCloseIcon } from "../components/Engineering/ProjectManagement/SitesDetails/CreateSite/CreateSiteDialog";

interface DialogHeaderProps {
  titleKey: string;
  defaultValues?: any;
  localized: { [key: string]: string };
  onClose: () => void;
}

export  const DialogHeader: React.FC<DialogHeaderProps> = ({
  titleKey,
  defaultValues,
  localized,
  onClose,
}) => {
  return (
    <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
      <DialogTitle
        sx={{
          m: 0,
          fontWeight: "400",
          fontSize: "28px",
          lineHeight: "36px",
        }}
        id="customized-dialog-title"
      >
        {defaultValues
          ? localized[`edit-${titleKey}`]
          : localized[`new-${titleKey}`]}
      </DialogTitle>
      {getCloseIcon(onClose)}
    </Box>
  );
};
