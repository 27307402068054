import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import localized from "../../../../en.json";

import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  Site,
  getAllSitesByProjectIdResponse,
} from "../../../../Models/models";
import { SearchIcon } from "../../../../theme/Icons/IshIcons";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../util/SearchStyleUtil";
import { mergedStylesWithWidth, selectMenuPropsStyle, selectMenuPropsStyleSite } from "../../../../util/StyleUtil";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { GET_ALL_SITES_FOR_PROJECT } from "../SitesDetails/SitesDetails";

type Device = {
  id: string;
  name: string;
};
type Props = {
  projectId: string | undefined;
  customerId: number | undefined;
};

export const GET_UNASSIGNED_DEVICES = gql`
  query ($customerID: Int!, $status: [String]) {
    getDevicesByCustomerID(customerID: $customerID, status: $status) {
      id
      name
    }
  }
`;
export const ASSIGN_DEVICES_TO_SITE = gql`
  mutation ($deviceIDs: [String]!, $siteID: Int!) {
    assignDevicesToSite(deviceIDs: $deviceIDs, siteID: $siteID)
  }
`;
export const AssignEdgeDevices = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [unAssignedDevices, setUnAssignedDevices] = useState([]);
  const [filteredUnAssignedDevices, setFilteredUnAssignedDevice] = useState<
    Device[]
  >([]);
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [siteList, setSiteList] = useState<Site[]>([]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setFilteredUnAssignedDevice(
      unAssignedDevices.filter((device: Device) =>
        device.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };
  const onSelectSiteHandler = (selectedValue: string) => {
    setSelectedSite(selectedValue);
  };
  const onDeviceCheckedHandler = (id: string) => {
    setSelectedDeviceIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((item) => item !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };
  const getAllSites = () => {
    client
      .query({
        query: GET_ALL_SITES_FOR_PROJECT,
        fetchPolicy: "no-cache",
        variables: {
          projectID: props.projectId,
        },
      })
      .then((response: ApolloQueryResult<getAllSitesByProjectIdResponse>) => {
        setSiteList(response.data.getAllSitesByProjectId);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-sites"],
          false,
          enqueueSnackbar
        );
      });
  };
  const getRegisteredDevices = () => {
    client
      .query({
        query: GET_UNASSIGNED_DEVICES,
        variables: {
          customerID: props.customerId,
          status: ["Registered"],
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<any>) => {
        setUnAssignedDevices(response.data.getDevicesByCustomerID);
        setFilteredUnAssignedDevice(response.data.getDevicesByCustomerID);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-un-assigned-devices"],
          false,
          enqueueSnackbar
        );
      });
  };
  const assignedDevicesToSite = () => {
    client
      .mutate({
        mutation: ASSIGN_DEVICES_TO_SITE,
        variables: {
          deviceIDs: selectedDeviceIds,
          siteID: selectedSite,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        ShowSnackbar(
          localized["edge-devices-assigned-to-site"],
          true,
          enqueueSnackbar
        );
        getRegisteredDevices();
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-assign-devices"],
          false,
          enqueueSnackbar
        );
      })
      .finally(() => {
        setSelectedDeviceIds([]);
        setSelectedSite("");
      });
  };
  useEffect(() => {
    getAllSites();
    if (props.customerId) {
      getRegisteredDevices();
    }
  }, [props.customerId, props.projectId]);
  return (
    <Stack
      direction={"row"}
      padding={"24px 32px"}
      gap={"32px"}
      flexWrap={"wrap"}
      height={"100%"}
      overflow={"auto"}
      sx={{ scrollbarWidth: "thin" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          width: "563px",
          border: "1px solid #DEDEDE",
          borderRadius: "8px",
          overflowY: "auto",
          height: "fit-content",
          maxHeight: "95%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: "22px",
            padding: "16px",
          }}
        >
          {localized["unassigned-edge-devices"]}
        </Typography>
        <Box>
          <Search
            sx={{
              display: "flex",
              height: "44px",
              width: "563px !important",
              marginLeft: "0px !important",
            }}
          >
            <SearchIconWrapper>
              <SearchIcon sx={{ marginLeft: "14px" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              sx={{
                minWidth: "563px !important",
                padding: "10px 24px",
                borderRadius: "0px",
                borderTop:"none !important"
              }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Search>
        </Box>
        <Box
          maxHeight={"100%"}
          sx={{ overflowY: "auto", scrollbarWidth: "thin" }}
        >
          {unAssignedDevices.length === 0 && (
            <Typography
              padding={"10px 15px"}
              color={"#959595"}
              fontWeight={700}
              fontSize={"14px"}
            >
              {localized["no-unassigned-edge-device"]}
            </Typography>
          )}
          {filteredUnAssignedDevices?.map((iotDevice: any) => (
            <FormGroup
              key={iotDevice.id}
              sx={{
                padding: "10px 20px",
                borderBottom: "1px solid #DEDEDE",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDeviceIds.includes(iotDevice.id)}
                    onChange={() => onDeviceCheckedHandler(iotDevice.id)}
                  />
                }
                sx={{ height: "36px" }}
                label={
                  <Typography
                    variant="h5"
                    sx={{
                      maxWidth: "400px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    {iotDevice.name}
                  </Typography>
                }
              />
            </FormGroup>
          ))}
        </Box>
      </Box>
      <Stack gap={"32px"} height="30%">
        <Stack gap={"10px"}>
          <Typography
            fontSize={"22px"}
            fontWeight={400}
            lineHeight={"30px"}
            color={
              siteList.length === 0 || unAssignedDevices.length === 0
                ? "#969696"
                : "#0D0D0D"
            }
          >
            {localized["site"]}
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="select-site"
              id="select-site"
              displayEmpty
              value={selectedSite}
              onChange={(event) => onSelectSiteHandler(event.target.value)}
              sx={mergedStylesWithWidth}
              MenuProps={selectMenuPropsStyleSite}
              disabled={siteList.length === 0 || unAssignedDevices.length === 0}
              renderValue={selectedSite ? undefined : () => siteList.length > 0
                ? localized["select-site"]
                : localized["no-site-available"]}
            >
                {siteList.map((site: Site) => {
                  return (
                    <MenuItem value={site.id} key={site.id}>
                      {site.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Stack>
        <Button
          disableRipple
          variant="contained"
          sx={{
            borderRadius: "24px",
            height: "40px",
            width: "140px",
            alignItems: "center",
          }}
          disabled={selectedDeviceIds.length === 0 || selectedSite === ""}
          onClick={() => {
            assignedDevicesToSite();
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "none",
            }}
          >
            {localized["assign-device"]}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
