import { Typography } from "@mui/material";
import { SaveDoumentIcon } from "../theme/Icons/IshIcons";

type PropsType = {
  fileName: string;
};
export const UploadUtil = (props: PropsType) => {
  return (
    <>
      <SaveDoumentIcon
        sx={{
          width: "32px",
          height: "32px",
        }}
      />
      <Typography
        variant="caption"
        sx={{
          color: "#1B1534",
          lineHeight: "16px",
        }}
      >
        {props.fileName}
      </Typography>
    </>
  );
};
