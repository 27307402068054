import React from "react";
import { SignalRow } from "../../../Models/models";
import { TableComponent } from "../../../util/TableComponent";
import { HeadCell } from "./EnergyAssets/AssetsTable/AssetsTable";

const headCells: readonly HeadCell<SignalRow>[] = [
  { id: "displayName", label: "UID" },
  { id: "path", label: "Path" },
  { id: "unit", label: "Unit" },
  { id: "pollingTime", label: "Polling Time" },
  { id: "threshold", label: "Threshold" },
];

type SignalTableProps = {
  tableData: SignalRow[];
  deleteNodeHandler: (row: SignalRow) => void;
};

const SignalTable: React.FC<SignalTableProps> = ({
  tableData,
  deleteNodeHandler,
}) => {
  return (
    <TableComponent
      tableData={tableData}
      headCells={headCells}
      deleteHandler={deleteNodeHandler}
      orderByDefault="displayName"
      rowsPerPageDefault={10}
      enableCheckboxSelection={false}
    />
  );
};

export default SignalTable;
