import localized from "../en.json";

export const assetTypes =["PV Panel","Wind Farm","Diesel Generator","BESS"]

export const units =["W","kW"]


export const pvPanelParamsWithoutUnit: Record<string, Record<string, any>> = {    soilingLosses: { title: 'Soiling Losses' ,unitShow:false,optional:false ,type :"number",defaultValue:"0.01"},
    reflectionLosses: { title: 'Reflection Losses' ,unitShow:false ,optional:false,type :"number",defaultValue:"0.01"},
    spectralLosses: { title: 'Spectral Losses',unitShow:false ,optional:false,type :"number",defaultValue:"0.005"  },
    mismatchLosses: { title: 'Mismatch Losses',unitShow:false ,optional:false ,type :"number",defaultValue:"0.005"},
    dcCablingLosses: { title: 'DC Cabling Losses',unitShow:false,optional:false,type :"number",defaultValue:"0.01"  },
    inverterEfficiencyLosses: { title: 'Inverter Efficiency Losses',unitShow:false,optional:false ,type :"number",defaultValue:"0.02"  },
    transformerEfficiencyLosses: { title: 'Transformer Efficiency Losses' ,unitShow:false,optional:true ,type :"number",defaultValue:"0.015" },
    acCablingLosses: { title: 'AC Cabling Losses' ,unitShow:false ,optional:false ,type :"number",defaultValue:"0.005"},
    otherLosses: { title: 'Other Losses',unitShow:false ,optional:false ,type :"number",defaultValue:"0" },
    curtailPercLimit: { title: 'Curtail Percentage Limit' ,unitShow:false ,optional:false ,type :"number",defaultValue:"" },
    dateOperationStarted: { title: 'Date of Operation Start' ,unitShow:false,optional:true ,type :"date",defaultValue:"" },
    manufacturer: { title: 'Manufacturer',unitShow:false ,optional:true,type :"text",defaultValue:""  },
    albedo: { title: 'Albedo',unitShow:false,optional:false ,type :"number",defaultValue:"0.2" },
    moduleStcEfficiency: { title: 'Module STC Efficiency' ,unitShow:false ,optional:false ,type :"number",defaultValue:"0.15" },
};

export const pvPanelParamsWithUnit : Record<string, Record<string, string|boolean|string[]>>={
    area: { title: 'Area',unitShow:true ,optional:false ,units:["m²","hectare","km²"],type :"number",defaultValue:"" },
    nomPeakPower: { title: 'Nominal Peak Power',unitShow:true ,optional:false, units:["W","kW","MW"],type :"number",defaultValue:""},
    nomAcVoltage: { title: 'Nominal AC Voltage' ,unitShow:true,optional:true ,units:["V","kV"],type :"number",defaultValue:""},
    nomDcVoltage: { title: 'Nominal DC Voltage',unitShow:true ,optional:true,units:["V","kV"],type :"number",defaultValue:"" },
    modulePlaneInclination: { title: 'Module Plane Inclination' ,unitShow:true ,optional:false ,units:["rad","degree"],type :"number",defaultValue:"0.436",defaultUnit:"rad" },
    modulePlaneAzimuth: { title: 'Module Plane Azimuth',unitShow:true,optional:false  ,units:["rad","degree"] ,type :"number",defaultValue:"0",defaultUnit:"rad"},
    investmentCost: { title: 'Investment Cost' ,unitShow:true,optional:true ,units:["€","k€"],type :"number",defaultValue:""  },
    operationalCost: { title: 'Operational Cost' ,unitShow:true ,optional:true ,units:["€","k€"],type :"number",defaultValue:""},
    maintenanceCost: { title: 'Maintenance Cost',unitShow:true ,optional:true ,units:["€","k€"],type :"number",defaultValue:""},
    noctCoefficient: { title: 'NOCT Coefficient', unitShow:true ,optional:false,units:["°C"],type :"number",defaultValue:"47" },
    moduleStcPower: { title: 'Module STC Power' ,unitShow:true ,optional:false,units:["W"],type :"number",defaultValue:"300"},
    modulePowerTemperatureCoefficient: { title: 'Module Power Temperature Coefficient',unitShow:true,optional:false ,units:["1%/°C"],type :"number",defaultValue:"-0.0045"  },
    lowerThresholdOfSunElevationForDniCalculation: { title: 'Lower Threshold of Sun Elevation' ,unitShow:true ,optional:false ,units:["rad","degree"],type :"number",defaultValue:"0.087",defaultUnit:"rad" },
    stcIrradiation: { title: 'STC Irradiation' ,unitShow:true ,optional:false,units:["W/m²"] ,type :"number",defaultValue:"1000"},
    stcTemperature: { title: ' STC Temperature',unitShow:true,optional:false ,units:["°C"],type :"number",defaultValue:"25" },
    noctIrradiation: { title: ' NOCT Irradiation' ,unitShow:true,optional:false ,units:["W/m²"],type :"number",defaultValue:"800"},
    noctTemperature: { title: ' NOCT Temperature' ,unitShow:true,optional:false ,units:["°C"] ,type :"number",defaultValue:"20"}
}

export const bess: Record<string, Record<string, string>> = {
    deviceId: { title: 'Device ID' },
    description: { title: 'Description' },
    siteId: { title: 'Site ID' },
    dateOperationStarted: { title: 'Date of Operation Start' }, 
    dateOfEolTarget: { title: 'Nominal Peak Power' },
    manufacturer: { title: 'Manufacturer' },
    nomDcVoltage: { title: 'Nominal DC Voltage' },
    curtailPercLimit: { title: 'Curtailment Percentage Limit' },
    batteryType :{title:'Battery Type'},
    investmentCost: { title: 'Investment Cost' },
    operationalCost: { title: 'Operational Cost' },
    maintenanceCost: { title: 'Maintenance Cost' },
    bessParametersId: { title: 'BESS Parameters ID' },
    vNom: { title: 'Nominal Voltage [V]' },
    vMax: { title: 'Maximum Voltage [V]' },
    vMin: { title: 'Minimum Voltage [V]' },
    eNom: { title: 'Module Plane Inclination' },
    socMin: { title: 'Module Plane Azimuth' },
    socMax: { title: 'Albedo' },
    socReserve: { title: 'NOCT Coefficient' },
    cchMax: { title: 'Module STC Efficiency' },
    cdchMax: { title: 'Module STC Power' },
    pchMin: { title: 'Module Power Temperature Coefficient' },
    chEff: { title: 'Soiling Losses' },
    dischEff: { title: 'Reflection Losses' },
    eolCriterion: { title: 'Spectral Losses' },
    invSNom: { title: 'Module Mismatch Losses' },
    invVNom: { title: 'DC Cabling Losses' },
    invMaxIdc: { title: 'Inverter Efficiency Losses' },
};

export const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,7}:$|^::([0-9a-fA-F]{1,4}:){0,6}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,6}:([0-9a-fA-F]{1,4}:)?[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}$|^([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}$|^([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}$|^([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}$|^[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})$|^::(ffff(:0{1,4})?:)?((\d{1,3}\.){3}\d{1,3})$/;
export const ipv4Regex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
export const signalPathRegex = /^[a-zA-Z0-9]+\/[a-zA-Z0-9\s-]+\/i=\d+\/ns=\d+;i=\d+\/ns=\d+;s=[a-zA-Z0-9\/]+\/ns=\d+;s=[a-zA-Z0-9\/]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/;
export const dropDownMenuLengths = [10, 20, 30];

export const STATUS_RUNNING = localized["running"];
export const STATUS_DISABLED = localized["disabled"];
export const STATUS_UPDATING = localized["updating"];
export const STATUS_DISABLING = localized["disabling"];
export const STATUS_ENABLING = localized["enabling"];
export const STATUS_UNINSTALLING = localized["uninstalling"];

export const truncateText = (text:string) => {
    if (text.length > 18) {
        return `${text.slice(0, 18)}...`;
    } else {
        return text;
    }
};

export const isAllTrue = (...booleans: boolean[]) => {
    return booleans.every(Boolean);
}

export const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
];
export const sheetNames = ['General', 'Security', 'Timeout', 'Subscribe nodes'];
export const generalDetailsKeys = ['Session Name', 'Endpoint URL', 'User', 'Password'];
export const securityDetailsKeys = ['Message Security Mode', 'Security Policy', 'Locale IDs'];
export const timeoutDetailsKeys = ['Browse Timeout', 'Discovery Timeout', 'Publish Timeout', 'Session Timeout', 'Watchdog Timeout', 'Read Timeout', 'Write Timeout', 'Call Timeout', 'Reconnect', 'Persistent', 'Auto Reconnect'];
export const subscribeNodesKeys = ['Tag Name', 'Node Path', 'Unit', 'Polling time', 'Threshold'];

export const deviceTypeList = [
    { id: 1, type: "X3" },
    { id: 2, type: "X5" },
    { id: 3, type: "X7" },
  ];

  export function checkIfDataLossMessage(name: string, actionType: string) {
    return (
      (name.includes("influxdb") || name.includes("telegraf")) &&
      actionType !== "update" &&
      actionType !== "enable"
    );
  }

  export const isEqualArraysOfString = (arr1: string[], arr2: string[]) => {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index])
    );
  };
  export const isEqualArraysOfObjects = (arr1: any[], arr2: any[]) => {
    return (
      arr1?.length === arr2?.length &&
      arr1?.every((o: any, i: any) =>
        Object.keys(o).every((prop) => arr2[i]["id"] && arr2[i][prop] === o[prop])
      )
    );
  };

  export const arraysEqual = (a1: any, a2: any) => {
    let sortedArrayA = a1?.sort((a: any, b: any) => a.id - b.id);
    let sortedArrayB = a2?.sort((a: any, b: any) => a.id - b.id);
    return isEqualArraysOfObjects(sortedArrayA, sortedArrayB);
  };


  export const handleNumberInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
      e.preventDefault();
    }
  };

  export const handleNumberInputKeyDownNegative = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === 'E' || e.key === '+') {
      e.preventDefault();
    }
  };
