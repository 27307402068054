import { Tabs } from '@mui/material';
import React from 'react';
import localized from '../../en.json';
import { LocationIcon, TableIcon } from '../../theme/Icons/IshIcons';
import { RenderTab } from '../../util/TabPanelProps';

interface PropTypes {
    tabValue: number;
    setTabValue: Function;
}
const MapTableTabs = (props: PropTypes) => {
    const { tabValue, setTabValue } = props;
    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Tabs
            sx={{
                "& .MuiTabs-indicator": { borderBottom: "4px solid #8A00E5" },
                "& .MuiTab-root": { padding: "16px 24px", minHeight: "54px", mt: "20px" },
                "& .MuiTab-root.Mui-selected": { padding: "16px 24px" },
            }}
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
        >
            {RenderTab(localized['map-tab'], 0, <LocationIcon sx={{ width: "20px", height: "20px" }} />)}
            {RenderTab(localized['table-tab'], 1, <TableIcon sx={{ width: "20px", height: "20px" }} />)}
        </Tabs>
    )
}

export default MapTableTabs