import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import localized from "../../en.json";
import { WarningIcon } from "../../theme/Icons/IshIcons";

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: #170d32;
  }
  .MuiDialog-paper {
    height: 174px;
    width: 55vh;
    max-width: 483px;
  }
  .MuiDialogContent-root {
    padding: 40px;
  }
`;
type UnAuthorisedDialogProps = {
  openAuthErrorDialog: boolean;
  handleDialogClose: Function;
};
export const UnAuthorisedDialog = (props: UnAuthorisedDialogProps) => {
  return (
    <StyledDialog
      open={props.openAuthErrorDialog}
      onClose={() => props.handleDialogClose()}
      aria-labelledby="auth-error-dialog"
      aria-describedby="auth-error-dialog"
      fullWidth
    >
      <DialogContent>
        <Stack direction={"row"} gap={"4px"}>
          {" "}
          <WarningIcon style={{ color: "#DA1E28" }} />
          <Typography
            variant="body2"
            sx={{ marginTop: "3px", color: "#DA1E28" }}
          >
            {localized["un-authorized-user"]}
          </Typography>
        </Stack>
        <Typography sx={{ marginTop: "8px", fontSize: "16px" }} variant="body2">
          {localized["access-denied"]}
        </Typography>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Button
            onClick={() => props.handleDialogClose()}
            sx={{
              marginTop: "20px",
              height: "22px",
              textTransform: "none",
            }}
          >
            <Typography variant="body1" fontSize={"14px"}>
              {localized["back-to-login"]}
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
    </StyledDialog>
  );
};
