import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EngineeringIcon,
  MonitorIcon,
  NotificationIcon,
  UserIconNavBar,
  WarningIcon
} from "../../theme/Icons/IshIcons";
import MoreInfo from "./MoreInfo";


const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "56px",

  ".MuiListItemButton-root": {
    padding: "16px !important",
    height: "56px",
    width: "56px",
  },
  ".MuiIconButton-root ": {
    padding: "16px !important",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  width: "56px !important",
  height: "56px !important",
  ...theme.mixins.toolbar,
  minHeight: "56px !important",
}));

interface PropType {
  setIsDrawerOpen: Function;
}
export const MuiNavbar = (props: PropType) => {
  const { setIsDrawerOpen } = props;

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const getDrawer = () => {
    return open ? MuiDrawer : Drawer;
  }

  function getBorderLeft(icon: string) {
    return iconClicked === icon
      ? "4px solid #9747FF !important"
      : "4px solid transparent !important";
  }
  function getColor(icon: string) {
    return iconClicked === icon ? " #9747FF !important" : "#1B1534";
  }
  function getColorIcon(icon: string) {
    return iconClicked === icon ? " #9747FF !important" : "#1B1534";
  }

  const [iconClicked, setIconClicked] = useState("");
  const [isMoreIconClicked, setIsMoreIconClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawer = () => {
    setOpen(false);
    setIsDrawerOpen(false);
    setIsMoreIconClicked(false);
    setOpenMoreDrawer(false);
  }

  const onClickHome = () => {
    handleDrawer()
    setIconClicked("dashboard");
    navigate("/dashboard");
  };
  const onClickNotification = () => {
    handleDrawer()
    setIconClicked("notify");
    navigate("/notifications");
  };
  const onClickAccount = () => {
    handleDrawer()
    setIconClicked("users");
    navigate("/user-management");
  };
  const onClickRemote = () => {
    handleDrawer()
    setIconClicked("engineering");
    navigate("/engineering/customer");
  };
  
  const onClickMore = () => {
    setIconClicked("more");
    setOpenMoreDrawer(!openMoreDrawer);
    setIsMoreIconClicked(!isMoreIconClicked);
    setIsDrawerOpen((prevState: boolean) => !prevState);
  };

  const NavigationIcons = useMemo(() => {
    return [
      { navigateRoute: "engineering", icon: "engineering" },
      { navigateRoute: "notifications", icon: "notify" },
      { navigateRoute: "user-management", icon: "users" },
      { navigateRoute: "more", icon: "more" },
      { navigateRoute: "software-management", icon: "softwares" },
    ];
  }, []);

  const [open, setOpen] = useState(false);
  const [openMoreDrawer, setOpenMoreDrawer] = useState(false);

  useEffect(() => {
    const findIconClicked = (pathName: string) => {
      const foundIcon = NavigationIcons.find(({ navigateRoute }) =>
        pathName.includes(navigateRoute)
      );
      if(location.pathname==="/software-management"){
        setIsMoreIconClicked(true)
      }
      return foundIcon ? foundIcon.icon : "dashboard";
    };
    isMoreIconClicked ? setIconClicked(findIconClicked("/more")) : setIconClicked(findIconClicked(location.pathname));
    if (isMoreIconClicked) {
      setIsMoreIconClicked(true);
    }
  }, [iconClicked, location.pathname, NavigationIcons, open, isMoreIconClicked]);

  const menuItems = [
    {
      text: "Monitor",
      icon: <MonitorIcon />,
      onClick: onClickHome,
      key: "dashboard",
    },
    {
      text: "Notifications",
      icon: <NotificationIcon />,
      onClick: onClickNotification,
      key: "notify",
    },
    {
      text: "Engineering",
      icon: <EngineeringIcon />,
      onClick: onClickRemote,
      key: "engineering",
    },
    {
      text: "Users",
      icon: <UserIconNavBar />,
      onClick: onClickAccount,
      key: "users",
    },
    {
      text: "More",
      icon: <MoreHorizIcon />,
      onClick: onClickMore,
      key: "more",
    },
  ];
  const bottomIcons = [
    { text: "About & Legal", icon: <WarningIcon /> },
    { text: "Help", icon: <HelpOutlineOutlinedIcon /> },
  ];
  const handleDrawerClose = () => {
    setOpen(!open);
    setIsDrawerOpen(false);
    setOpenMoreDrawer(false);
    setIsMoreIconClicked(false);
  };

  const UpdatedDrawer = getDrawer();

  return (
    <>
      <UpdatedDrawer
        variant={open ? "temporary" : "permanent"}
        open={open}
        onClose={handleDrawerClose}
        sx={{
          ".MuiDrawer-paper": {
            marginTop: "80px",
            border: "1px solid #EAEAEA",
            height: "calc(100vh - 80px)",
          },
          zIndex: (!open && isMoreIconClicked) ? (theme) => theme.zIndex.drawer + 1 : (theme) => theme.zIndex.drawer
        }}
      >
        <Box display="flex" sx={{ height: "100vh" }}>
          <Box display="flex" flexDirection="column" sx={{ justifyContent: "space-between" }}>
            <Box display="flex" flexDirection="column">
              <DrawerHeader>
                <IconButton
                  data-testid="headerIcon"
                  disableRipple
                  onClick={handleDrawerClose}
                  sx={{ color: "#1B1534", justifyContent: "center", marginLeft: open ? "120px" : "0px" }}
                >
                  {!open && <KeyboardDoubleArrowRightIcon />}
                  {open && <KeyboardDoubleArrowLeftIcon />}
                </IconButton>
              </DrawerHeader>
              {menuItems.map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    data-testid={item.key}
                    onClick={item.onClick}
                    sx={{
                      minHeight: "56px !important",
                      minWidth: "56px !important",
                      borderLeft: getBorderLeft(item.key),
                      color: getColor(item.key),
                      justifyContent: "center",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: getColorIcon(item.key),
                        fill: getColorIcon(item.key),
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        "& .MuiTypography-root": {
                          font: "16px SiemensSans,Arial",
                          fontWeight: "520",
                        },
                      }}
                      primary={item.text}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
            <List sx={{ mt: "auto", paddingBottom: "1rem" }}>
              {bottomIcons.map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 56,
                      justifyContent: "center",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: "#1B1534",
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        "&.MuiListItemIcon-root": {
                          marginRight: open ? "15px " : "",
                        },
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        "& .MuiTypography-root": {
                          font: "16px SiemensSans,Arial",
                        },
                      }}
                      primary={item.text}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          {isMoreIconClicked &&
            <>
              <Divider orientation="vertical" />
              <MoreInfo handleDrawer={handleDrawer} />
            </>}
        </Box>
      </UpdatedDrawer>
      {(!open && isMoreIconClicked) &&
        <MuiDrawer
          open={openMoreDrawer}
          onClose={() => {
            setIsMoreIconClicked(false);
            setOpenMoreDrawer(false);
            setIsDrawerOpen(false);
          }}
          sx={{
            ".MuiDrawer-paper": {
              marginTop: "80px",
              border: "1px solid #EAEAEA",
              height: "calc(100vh - 80px)",
              marginLeft: "56px"
            },
          }}>
          <MoreInfo handleDrawer={handleDrawer} />
        </MuiDrawer>
      }
    </>
  );
};
