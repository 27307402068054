import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import localized from "../../en.json";
import { gql, useApolloClient } from "@apollo/client";
import { DeviceType, Software } from "../../Models/models";
import { ReloadIcon } from "../../theme/Icons/IshIcons";
import { useSnackbar } from "notistack";
import { SoftwareContext } from "../../store/software-list-context";
import {
  deviceTypeList,
  isEqualArraysOfString,
} from "../../util/ConstantUtils";
import { selectMenuPropsStyle } from "../../util/StyleUtil";
import ShowSnackbar from "../CustomizedSnackbar/ShowSnackbar";

interface PropsType {
  open: boolean;
  onCloseEditSoftware: () => void;
  softwareDetails: Software;
}

function renderValidationText(error: string) {
  return (
    <FormHelperText
      error={true}
      sx={{
        marginBottom: "10px !important",
      }}
    >
      {error}
    </FormHelperText>
  );
}

export const UPDATE_SOFTWARE = gql`
  mutation ($id: ID!, $description: String, $compatibleDeviceType: String) {
    updateSoftware(
      softwareDtoReq: {
        id: $id
        description: $description
        compatibleDeviceType: $compatibleDeviceType
      }
    ) {
      id
    }
  }
`;

const EditSoftwareDetails = (props: PropsType) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const {
    open,
    onCloseEditSoftware,
    softwareDetails: { compatibleDeviceType,description ,id,name} 
  } = props;
  const deviceTypeArray = useMemo(() => {
    return compatibleDeviceType ?compatibleDeviceType.split(",") : [];
  }, [compatibleDeviceType]);
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [compatibleDeviceTypeValue, setCompatibleDeviceTypeValue] =
    useState<string[]>(deviceTypeArray);
  const [disableUpdateButton, setDisableUpdateButton] = useState(false);
  const softwareContext = useContext(SoftwareContext);
  const handleChangeCompatibleDevices = (
    event: SelectChangeEvent<typeof compatibleDeviceTypeValue>
  ) => {
    const { value } = event.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    setCompatibleDeviceTypeValue(newValue);
  };

  const descriptionLengthValidation = descriptionValue.length > 500;

  const devicesTypeChanged = (
    selectedDevices: string[],
    originalDevices: string[]
  ): boolean => {
    const selectedDeviceTypes = [...selectedDevices].sort((a, b) => a.localeCompare(b));
    const originalDeviceTypes = [...originalDevices].sort((a, b) => a.localeCompare(b));
    return isEqualArraysOfString(selectedDeviceTypes, originalDeviceTypes);
  };

  useEffect(() => {
    const disableUpdateButtonFn = () => {
      const isDescriptionEqual = descriptionValue === description;
      const isDeviceTypeEqual = devicesTypeChanged(
        compatibleDeviceTypeValue,
        deviceTypeArray
      );
      setDisableUpdateButton(
        (isDescriptionEqual && isDeviceTypeEqual) ||
          !compatibleDeviceTypeValue.length ||
          descriptionLengthValidation
      );
    };
    disableUpdateButtonFn();
  }, [
    descriptionValue,
    compatibleDeviceTypeValue,
    description,
    deviceTypeArray,
    descriptionLengthValidation,
  ]);

  const updateSoftware = () => {
    client
      .mutate({
        mutation: UPDATE_SOFTWARE,
        variables: {
          id: id,
          description: descriptionValue,
          compatibleDeviceType: compatibleDeviceTypeValue.toString(),
        },
      })
      .then(() => {
        onCloseEditSoftware();
        softwareContext?.softwareListChangeHandler();
        ShowSnackbar(
          localized["update-software-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        ShowSnackbar(
          localized["update-software-failed"],
          false,
          enqueueSnackbar
        );
      });
  };

  const reloadDescription = () => {
    setDescriptionValue(description);
  };

  const onCloseEditDialog = () => {
    onCloseEditSoftware();
    setDescriptionValue(description);
    setCompatibleDeviceTypeValue(deviceTypeArray);
  };

  return (
    <Dialog
      open={open}
      sx={{
        ".MuiDialogTitle-root": {
          padding: "0 !important",
        },
        ".MuiDialogContent-root ": {
          padding: "0 !important",
        },
        ".MuiIconButton-root ": {
          padding: "0 !important",
        },

        ".MuiDialog-paper ": {
          minWidth: "584px !important",
        },
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="software-dialog-title">
        <Box
          sx={{ display: "flex", justifyContent: "space-between", p: "32px" }}
        >
          <Typography variant="h4" fontSize="28px" fontWeight="400">
            {localized["edit-software"]}
          </Typography>

          <IconButton
            data-testid="close-button"
            aria-label="close-icon-button"
            onClick={onCloseEditDialog}
          >
            <CloseIcon
              sx={{
                color: "#1B1534",
                width: "32px",
                height: "32px",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box sx={{ padding: "32px", color: "#1B1534" }}>
            <Typography variant="h5">{localized["software"]}:</Typography>
            <Typography variant="body1" fontSize="22px">
              {name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  padding: "24px 0px 8px 0px",
                }}
              >
                {localized["description-label"]}
              </Typography>
              <IconButton
                data-testid="reloadDescription"
                aria-label="reload-icon-button"
                onClick={reloadDescription}
                sx={{
                  mr: "1%",
                  "&:hover": {
                    backgroundColor: "rgb(138, 0, 229,0.10) !important",
                  },
                }}
              >
                <ReloadIcon></ReloadIcon>
              </IconButton>
            </Box>
            <TextField
              sx={{
                width: "510px",
                fontSize: "16px",
                paddingBottom: descriptionLengthValidation ? "0px" : "24px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: descriptionLengthValidation ? "#E85960" : "",
                  },
                },
              }}
              id="outlined-multiline-flexible"
              value={descriptionValue}
              inputProps={{ "data-testid": "description" }}
              onChange={(event) => setDescriptionValue(event.target.value)}
              multiline
              rows={6}
              variant="outlined"
            />
            {descriptionLengthValidation
              ? renderValidationText(localized["description-length-validation"])
              : ""}

            <Typography
              variant="h5"
              sx={{
                paddingBottom: "8px",
                "& span": {
                  color: "#E85960",
                },
              }}
            >
              {localized["compatible-device-type-label"]} <span>*</span>
            </Typography>

            <Select
              multiple
              value={compatibleDeviceTypeValue}
              labelId="demo-simple-select-label"
              id="select-compatible-devices"
              renderValue={(selected) => selected.join(", ")}
              inputProps={{ "data-testid": "compatible-device-type" }}
              onChange={handleChangeCompatibleDevices}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: !compatibleDeviceTypeValue.length
                    ? "#E85960"
                    : "",
                },
                color: "#1B1534",
                "&.MuiOutlinedInput-root": {
                  fontSize: "16px",
                },
                height: "40px",
                width: "510px",
              }}
              MenuProps={selectMenuPropsStyle}
            >
              {deviceTypeList?.map((deviceType: DeviceType) => (
                <MenuItem key={deviceType.id} value={deviceType.type}>
                  <Checkbox
                    size="small"
                    checked={
                      compatibleDeviceTypeValue.indexOf(deviceType.type) > -1
                    }
                  />
                  <ListItemText
                    sx={{
                      "& .MuiTypography-root": {
                        font: "14px SiemensSans,Arial",
                      },
                    }}
                    primary={deviceType.type}
                  />
                </MenuItem>
              ))}
            </Select>

            {!compatibleDeviceTypeValue.length
              ? renderValidationText(
                  localized["device-type-selection-required"]
                )
              : ""}
          </Box>

          <Button
            variant="contained"
            onClick={updateSoftware}
            disabled={disableUpdateButton}
            sx={{
              margin: "34px 34px 42px 34px",
              width: "510px",
              height: "40px",
              textTransform: "none",
              borderRadius: "24px",
              padding: "8px 16px",
              ":disabled": {
                backgroundColor: "#ADA9C2",
                color: "#fff",
              },
            }}
          >
            <Typography variant="h5">{localized["update-button"]}</Typography>
          </Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default EditSoftwareDetails;
