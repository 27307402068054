import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import { Box, Divider, Link, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import React from "react";
import localized from "../../../../en.json";
import SoftwareNotUploadedDialog from "./SoftwareNotUploadedDialog";

interface PropsType {
  setShowAlert: Function;
  setOpenInstallSoftwares: Function;
  openSoftwareUploadDialog: boolean;
}

const SoftwaresOnDeviceAlert = (props: PropsType) => {
  const { setShowAlert, setOpenInstallSoftwares, openSoftwareUploadDialog } =
    props;
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowAlert(false);
            }}
          >
            <CloseIcon
              sx={{ color: "#1B1534", width: "24px", height: "24px" }}
            />
          </IconButton>
        }
        sx={{
          "&.MuiAlert-action": {
            paddingLeft: "24px !important",
          },
          "&.MuiAlert-root": {
            border: "2px solid red",
            borderRadius: "4px",
            //marginLeft: "24px",
          },
          width: "62rem",
        }}
      >
        <Box display="flex" gap="16px">
          <Typography variant="h6" fontWeight="400" color="#1B1534">
            {localized["software-on-device-alert"]}
          </Typography>
          <Link
            component="button"
            variant="overline"
            underline="none"
            sx={{
              textDecorationColor: "#8A00E5",
              textDecorationThickness: "1.5px",
            }}
            onClick={() => {
              openSoftwareUploadDialog
                ? setOpenDialog(true)
                : setOpenInstallSoftwares(true);
            }}
          >
            <Box display="flex" gap="4px">
              <Typography
                variant="overline"
                sx={{ textTransform: "none", color: "#1B1534" }}
              >
                {localized["click-to-install-link"]}
              </Typography>
              <LinkIcon
                sx={{ color: "#1B1534", width: "16px", height: "16px" }}
              />
            </Box>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: "#8A00E5", borderBottomWidth: "2px" }}
            />
          </Link>
        </Box>
      </Alert>
      <SoftwareNotUploadedDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
};

export default SoftwaresOnDeviceAlert;
