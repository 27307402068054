import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getEmptyRow,
  getPagination,
  getSearchbar,
  getTableCell,
  HeadCell,
} from "../components/Engineering/SiteManagement/EnergyAssets/AssetsTable/AssetsTable";
import localized from "../en.json";
import { DeleteIcon, EditIcon } from "../theme/Icons/IshIcons";
import { tableRowStyle } from "./TableStyleUtil";
import {
  calculateEmptyRows,
  getComparator,
  handleClick,
  handlePageChange,
  handleRowsPerPageChange,
  stableSort,
} from "./TableUtil";

type GenericTableProps<T extends { [key in keyof T]: string | number }> = {
  tableData: T[];
  headCells: readonly HeadCell<T>[];
  deleteHandler?: (row: T) => void;
  deleteAction?: boolean;
  orderByDefault?: string;
  rowsPerPageDefault?: number;
  enableCheckboxSelection: boolean;
  editHandler?: (row: T) => void;
  deleteMultipleHandler?: (userIds: readonly number[]) => void;
  customHeight?: string;
  customColumn?: {
    [key in keyof T]?: (row: T) => React.ReactNode;
  };
};

export type Order = "asc" | "desc";

export interface EnhancedTableProps<
  T extends { [key in keyof T]: string | number }
> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: readonly HeadCell<T>[];
  numSelected: number;
  enableCheckboxSelection: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function EnhancedTableHead<
  T extends { [key in keyof T]: string | number }
>(props: Readonly<EnhancedTableProps<T>>) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    numSelected,
    rowCount,
    onSelectAllClick,
  } = props;
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.enableCheckboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all rows",
              }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => {
          return getTableCell<T>(headCell, order, orderBy, createSortHandler);
        })}
        <TableCell key="deleteCol" align="left" padding="none" />
      </TableRow>
    </TableHead>
  );
}

export const TableComponent = <T extends { [key in keyof T]: string | number }>(
  props: GenericTableProps<T>
) => {
  const {
    tableData,
    headCells,
    orderByDefault = "displayName",
    rowsPerPageDefault = 10,
  } = props;

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>(orderByDefault);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault);
  const [filteredRows, setFilteredRows] = useState<T[]>(tableData);
  const [selected, setSelected] = useState<readonly number[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  useEffect(() => {
    setFilteredRows(tableData);
    if(!props.editHandler && !props.deleteHandler)
    setPage(1);
  }, [tableData]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    setFilteredRows(
      tableData.filter((row) =>
        Object.values(row).some((val) =>
          val?.toString().toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map(
        (n: T) => (n as any).id || undefined
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      width={"100%"}
      alignItems="flex-start"
    >
      {props.enableCheckboxSelection && (
        <Box display="flex" justifyContent="space-between" alignSelf="stretch">
          {getSearchbar(searchQuery, handleSearchChange)}
          <Button
            onClick={() => {
              props.deleteMultipleHandler?.(selected);
            }}
            disabled={selected?.length === 0}
            sx={{
              "&:disabled": { color: "#ADA9C2" },
              padding: "6px 16px",
              borderRadius: "24px",
            }}
          >
            <Typography variant="h5" sx={{ textTransform: "none" }}>
              {localized["delete-button"]}
            </Typography>
          </Button>
        </Box>
      )}

      {filteredRows.length > 0 ? (
        <Box
          width={"100%"}
          display="flex"
          flexDirection="column"
          gap="16px"
          alignItems="flex-start"
        >
          <Box
            sx={{
              width: "99%",
              height: props.customHeight ? props.customHeight : "56vh",
              border: "1px solid #DEDEDE",
              borderRadius: "8px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                height: "100%",
                boxShadow: "none",
                borderRadius: "8px",
                scrollbarWidth: "thin",
              }}
            >
              <Table stickyHeader aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead<T>
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                  rowCount={filteredRows.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  enableCheckboxSelection={props.enableCheckboxSelection}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const uniquePart = JSON.stringify(row).slice(0, 10);
                    const key = `${uniquePart}-${index}`;
                    const isItemSelected = isSelected(
                      Number((row as any).id) || 0
                    );
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={key}
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                      >
                        {props.enableCheckboxSelection && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onClick={(event) =>
                                handleClick(
                                  event,
                                  Number((row as any).id),
                                  selected,
                                  setSelected
                                )
                              }
                              inputProps={{
                                "aria-labelledby": `enhanced-table-checkbox-${index}`,
                              }}
                            />
                          </TableCell>
                        )}

                        {headCells.map((headCell) => (
                          <TableCell key={headCell.id as string} align="left"
                            sx={tableRowStyle}>
                            {props.customColumn?.[headCell.id as keyof T]
                              ? props.customColumn[headCell.id as keyof T]?.(row)
                              : (row as any)[headCell.id as keyof string]}
                          </TableCell>
                        ))}
                        <TableCell
                          align="left"
                          sx={{
                            padding: "18px !important",
                            paddingRight: "0px !important",
                          }}
                        >
                          {props.editHandler && (
                            <IconButton
                              data-testid={`edit-single-row-${index}`}
                              sx={{ padding: "0px", marginRight: "12px" }}
                              onClick={() => {
                                props.editHandler!(row);
                              }}
                            >
                              <EditIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                fill="#8A00E5"
                              />
                            </IconButton>
                          )}

                          {props.deleteHandler && (
                            <IconButton
                              data-testid={`delete-single-row-${index}`}
                              onClick={() => {
                                props.deleteHandler!(row);
                              }}
                              sx={{ padding: "0px" }}
                            >
                              <DeleteIcon
                                sx={{ width: "20px", height: "20px" }}
                                fill="#8A00E5"
                              />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {calculateEmptyRows(page, rowsPerPage, filteredRows.length) >
                    0 &&
                    getEmptyRow(
                      calculateEmptyRows(page, rowsPerPage, filteredRows.length)
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {getPagination(
            visibleRows,
            filteredRows,
            rowsPerPage,
            page,
            handlePageChange(setPage),
            handleRowsPerPageChange(setRowsPerPage, setPage)
          )}
        </Box>
      ) : (
        <Typography
        variant="overline"
        sx={{
          color: "#959595",
          fontWeight: "700",
          textTransform: "none",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        No Data Available
      </Typography>
      
      )}
    </Box>
  );
};
