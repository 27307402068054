import { LoadingButton } from "@mui/lab";
import { Chip, Typography } from "@mui/material";
import { STATUS_ENABLING, STATUS_RUNNING } from "../../../../util/ConstantUtils";

const isStatusChange = (status: string) => {
    return (status === STATUS_RUNNING || status === STATUS_ENABLING);
}

export const renderStatusAndVersion = (
    status: string,
    version: string,
    loading: boolean
) => {
    return (
        <>
            <LoadingButton
                loading={loading}
                loadingPosition="start"
                sx={{
                    textTransform: "none",
                    fontFamily: "SiemensSans,Arial",
                    color:
                        isStatusChange(status)
                            ? "#fff !important" : "#1B1534 !important",
                    background:
                        isStatusChange(status)
                            ? "#007942 !important" : "#E2B000 !important",
                    borderRadius: "20px",
                    height: "22px",
                    ".MuiCircularProgress-root": {
                        width: "10px !important",
                        height: "10px !important",
                    },
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: "10px",
                        paddingLeft: loading ? "15px" : "0px",
                    }}
                >
                    {status}
                </Typography>
            </LoadingButton>
            <Chip
                label={version}
                variant="outlined"
                sx={{
                    fontSize: "10px",
                    fontWeight: 700,
                    fontFamily: "SiemensSans,Arial",
                    color: "#8A00E5",
                    border: "1px solid #8A00E5",
                    borderRadius: "20px",
                    height: "22px",
                    ".MuiChip-label": { padding: "0px 8px" },
                }}
            />
        </>
    );
};
