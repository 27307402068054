import { gql, useApolloClient } from "@apollo/client";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { ExportIcon, SearchIcon } from "../../../theme/Icons/IshIcons";
import { convertDateTime, convertToUTC } from "../../../util/DateUtil";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../util/SearchStyleUtil";
import { TableComponent } from "../../../util/TableComponent";
import ShowSnackbar from "../../CustomizedSnackbar/ShowSnackbar";
import { HeadCell } from "../../Engineering/SiteManagement/EnergyAssets/AssetsTable/AssetsTable";
import { TimeFilter } from "../DataVisualization/Charts/TimeFilter";
import {
  EventLogsResult,
  EventLogsResultFormatted
} from "./../../../Models/models";
import localized from "../../../en.json";

export const GET_EVENT_LOGS = gql`
  query ($deviceID: String!, $startTime: String!, $endTime: String!) {
    getEventLogForDevice(
      deviceID: $deviceID
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      timestamp
      action
      description
      user {
        id
        firstName
        lastName
      }
      device {
        name
      }
    }
  }
`;

const EventLog = () => {
  const headCells: readonly HeadCell<EventLogsResultFormatted>[] = [
    { id: "timestamp", label: "Date" },
    { id: "action", label: "Performed action" },
    { id: "description", label: "Description" },
    { id: "user", label: "User" },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { deviceId } = useParams();
  const client = useApolloClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [rowData, setRowData] = useState<EventLogsResultFormatted[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<EventLogsResultFormatted[]>([]);
  const [currentDate] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<Date>(
    new Date(currentDate.setDate(currentDate.getDate() - 1))
  );
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [deviceName,setDeviceName] = useState();

  const fetchEventLogs = () => {
    setLoading(true);
    client
      .query({
        query: GET_EVENT_LOGS,
        variables: {
          deviceID: deviceId,
          startTime: convertToUTC(startTime),
          endTime: convertToUTC(endTime),
        },
        fetchPolicy: "no-cache",
        context: { apiName: "eventlog" }
      })
      .then((response: any) => {
        setDeviceName(response.data.getEventLogForDevice[0]?.device?.name)
        const formattedData = response.data.getEventLogForDevice
          .sort((a: any, b: any) => {
            const timestampComparison = b.timestamp.localeCompare(a.timestamp);
            if (timestampComparison === 0) {
              return b.id.localeCompare(a.id);
            }
            return timestampComparison;
          })
          .map((log: EventLogsResult) => ({
            ...log,
            user: `${log.user.firstName} ${log.user.lastName}`,
            timestamp: convertDateTime(log.timestamp),
          }));
        setRowData(formattedData);
        setFilteredLogs(formattedData);
        setSearchQuery(""); 
      })
      .catch(() => {
        ShowSnackbar(localized["failed-event-logs-fetch"], false, enqueueSnackbar);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEventLogs();
  }, [deviceId,startTime,endTime]);

  useEffect(() => {
    const filtered = rowData.filter(
      (log) =>
        log.action.toLowerCase().includes(searchQuery.toLowerCase()) ||
        log.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        log.timestamp.toLowerCase().includes(searchQuery.toLowerCase()) ||
        log.user.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredLogs(filtered);
  }, [searchQuery, rowData]);

  const exportToExcel = () => {
    const dataToExport = filteredLogs.map((event) => ({
      Date: event.timestamp,
      "Perfomed Action": event.action,
      Description: event.description,
      User: event.user,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "Event_Logs_"+deviceName+".csv");
  };

  return (
    <Box height="100%" sx={{ backgroundColor: "#fff" }} width={"100%"}>
      <Box padding={"24px"}>
        <Stack paddingBottom="24px" direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap="12px">
        <Search
          sx={{
            display: "flex",
            height: "44px",
            width: "240px !important",
            marginLeft: "0px !important",
          }}
        >
          <SearchIconWrapper>
            <SearchIcon sx={{ width: "24px", height: "24px", color: "#5D596E" }} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            sx={{ minWidth: "240px" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Search>
        <Typography variant="body1" sx={{ color: "#5D596E" }}>
          {localized["total-events"]} :  {filteredLogs.length}
        </Typography>
      </Stack>
          <Stack flexDirection="row" gap="24px">
            <TimeFilter
              setstartTime={setStartTime}
              setEndTime={setEndTime}
              isEventPage={true}
            />
            <Button
              variant="text"
              startIcon={<ExportIcon />}
              onClick={exportToExcel}
              sx={{ marginRight: "24px" }}
            >
              <Typography variant="h5" sx={{ textTransform: "none" }}>
                {localized["export"]}
              </Typography>
            </Button>
          </Stack>
        </Stack>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifyContent="center"
            height="500px"
          >
            <CircularProgress />
          </Box>
        ):(<TableComponent<EventLogsResultFormatted>
          tableData={filteredLogs}
          headCells={headCells}
          enableCheckboxSelection={false}
          
        />)}
        
      </Box>
    </Box>
  );
};

export default EventLog;
