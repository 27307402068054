import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { ArcElement, Chart, Title, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useLocation, useNavigate } from "react-router-dom";
import localized from "../../../en.json";
import { CpuUsageType } from "../../../Models/models";
import { deviceMonitoringStyles, renderHeading } from "../../../util/DeviceMonitoringUtil";

Chart.register(Tooltip, Title, ArcElement);

const lowerThresholdValue = 50;
const upperThresholdValue = 80;

function getCoreCpuUsage(value: number | undefined) {
  return value ?? 0;
}

const fillColorCpuCore = (cpuCoreUsage: number) => {
  if (cpuCoreUsage < lowerThresholdValue) {
    return "success";
  } else if (
    cpuCoreUsage >= lowerThresholdValue &&
    cpuCoreUsage <= upperThresholdValue
  ) {
    return "warning";
  } else {
    return "error";
  }
};

function renderCpuCoreLinearProgressBar(cpuCore: number | undefined, labelText: string) {
  return <>
    <Box
      display="flex"
      sx={{ flexGrow: 1, gap: "21px", alignItems: "center" }}
    >
      <Typography
        variant="h6"
        color="#1B1534"
        sx={{ textAlign: "start", marginTop: "10px", width: "100px" }}
      >
        {labelText}
      </Typography>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Typography
          variant="overline"
          sx={{ color: "#1B1534", textAlign: "start" }}
        >
          {getCoreCpuUsage(cpuCore)}%
        </Typography>
        <LinearProgress
          color={fillColorCpuCore(
            getCoreCpuUsage(cpuCore)
          )}
          sx={{
            height: 8,
            backgroundColor: "#D9D9D9",
          }}
          variant="determinate"
          value={getCoreCpuUsage(cpuCore)}
        />
      </Box>
    </Box>
    <Divider
      sx={{
        marginTop: "12px",
        marginBottom: "24px",
        borderBottomWidth: "1px",
        background: "#C0C0C0",
        width: "100%",
      }}
    />
  </>
}

interface PropsType {
  cpuUsage: CpuUsageType | undefined;
  setSignalsSelected: Function;
}

const CpuUsage = (props: PropsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const assetDetails = location.state;

  const totalCpuUsage =
    (getCoreCpuUsage(props.cpuUsage?.core0?.value) +
      getCoreCpuUsage(props.cpuUsage?.core1?.value) +
      getCoreCpuUsage(props.cpuUsage?.core2?.value) +
      getCoreCpuUsage(props.cpuUsage?.core3?.value)) /
    4;

  const colorList = ["#007942", "#E2D000", "#DA1E28"];

  const fillColorOuterCircle = () => {
    if (totalCpuUsage < lowerThresholdValue) {
      return colorList[0];
    } else if (
      totalCpuUsage >= lowerThresholdValue &&
      totalCpuUsage <= upperThresholdValue
    ) {
      return colorList[1];
    } else {
      return colorList[2];
    }
  };

  const data = {
    datasets: [
      {
        data: [totalCpuUsage, 100 - totalCpuUsage],
        backgroundColor: [fillColorOuterCircle(), "#C0C0C0"],
        circumference: 180,
        rotation: 270,
        cutout: "65%",
      },
    ],
  };

  const gaugeText = {
    id: "gaugeText",
    beforeDraw: (chart: any) => {
      const width = chart.width;
      const height = chart.height;
      const ctx = chart.ctx;
      ctx.restore();
      const xCenter = width / 2 - 30;
      const yCenter = height / 2 + 85;
      ctx.font = "700 18px SiemensSans,Arial";
      ctx.fillStyle = "#1B1534";
      ctx.textBaseline = "top";
      ctx.fillText(localized["usage"], xCenter, yCenter);
      ctx.save();
    },
  };

  const onClickViewDetails = () => {
    navigate("../datavisualization", {
      state: assetDetails,
    });
    props.setSignalsSelected([
      "framework/metrics/system/cpu-utilisation-percent",
      "system/resources/cpu/core0/usage",
      "system/resources/cpu/core1/usage",
      "system/resources/cpu/core2/usage",
      "system/resources/cpu/core3/usage",
    ]);
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const insideCircleData = {
    datasets: [
      {
        data: [49, 31, 20],
        backgroundColor: colorList,
        circumference: 180,
        rotation: 270,
        borderWidth: 1,
        cutout: "90%",
      },
    ],
  };

  const insideCircleOptions = {
    aspectRation: 1.5,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <Box sx={deviceMonitoringStyles}>
      <Paper elevation={0}>
        {renderHeading(localized["cpu-usage"], onClickViewDetails)}
        <Stack flexDirection="row" gap="150px" marginLeft="40px">
          <Box sx={{ width: "250px", height: "220px", marginTop: "45px" }}>
            <Doughnut
              data={data}
              height={220}
              options={options}
              plugins={[gaugeText]}
            />
            <Box
              sx={{
                marginTop: "-160px",
                marginLeft: "51px",
                marginRight: "70px",
                width: "147px",
                height: "147px",
              }}
            >
              <Doughnut
                data={insideCircleData}
                options={insideCircleOptions}
                width={147}
                height={147}
              />
            </Box>
            <Divider
              sx={{
                marginBottom: "24px",
                borderBottomWidth: "1px",
                background: "#C0C0C0",
                width: "100%",
                marginTop: "-20px",
                height: "0px",
              }}
            />
            <Typography
              variant="h3"
              sx={{
                color: fillColorOuterCircle(),
                marginTop: "-73px",
                marginLeft: "85px"
              }}
            >
              {Math.round(totalCpuUsage)}%
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ flexGrow: 1, paddingRight: "100px" }}
          >
            {renderCpuCoreLinearProgressBar(props.cpuUsage?.core0?.value, localized["core-0"])}
            {renderCpuCoreLinearProgressBar(props.cpuUsage?.core1?.value, localized["core-1"])}
            {renderCpuCoreLinearProgressBar(props.cpuUsage?.core2?.value, localized["core-2"])}
            {renderCpuCoreLinearProgressBar(props.cpuUsage?.core3?.value, localized["core-3"])}
          </Box>
        </Stack>
      </Paper>
    </Box >
  );
};

export default CpuUsage;
