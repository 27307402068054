import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  CircularProgress,
  Link,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TableSortLabel,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetAllAssetsBySiteId, Order } from "../../../../../Models/models";
import localized from "../../../../../en.json";
import { DeleteIcon, EditIcon } from "../../../../../theme/Icons/IshIcons";
import { ConfirmationDialog } from "../../../../../util/ConfirmationDialog";
import { dropDownMenuLengths } from "../../../../../util/ConstantUtils";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../../util/SearchStyleUtil";
import { selectMenuPropsStyle } from "../../../../../util/StyleUtil";
import {
  tablePaginationDropDownStyle,
  tablePaginationStyle,
  tableRowStyle,
} from "../../../../../util/TableStyleUtil";
import {
  getComparator,
  handleClick,
  stableSort,
} from "../../../../../util/TableUtil";
import ShowSnackbar from "../../../../CustomizedSnackbar/ShowSnackbar";

interface Data {
  id: number;
  assetName: string;
  assetType: string;
  installedCapacity: string;
  connectedEdgeDevices: string;
  createdBy: string;
}

function createData(
  id: number,
  assetName: string,
  assetType: string,
  installedCapacity: string,
  connectedEdgeDevices: string,
  createdBy: string
): Data {
  return {
    id,
    assetName,
    assetType,
    installedCapacity,
    connectedEdgeDevices,
    createdBy,
  };
}

export const GET_ALL_ASSETS_BY_SITE_ID = gql`
  query ($siteID: Int!) {
    getAllAssetsBySiteId(siteID: $siteID) {
      id
      name
      type
      capacity
      capacityUnit
      createdBy
      numberOfDevicesInAsset
      devicesInAsset {
        id
        name
      }
    }
  }
`;
export const DELETE_ASSET = gql`
  mutation deleteAsset($assetIDs: [Int]!) {
    deleteAsset(assetIDs: $assetIDs)
  }
`;

export interface HeadCell<T> {
  id: keyof T;
  label: string;
}

const headCells: readonly HeadCell<Data>[] = [
  {
    id: "assetName",
    label: localized["asset-name-label"],
  },
  {
    id: "assetType",
    label: localized["asset-type-label"],
  },
  {
    id: "installedCapacity",
    label: localized["installed-capacity-label"],
  },
  {
    id: "connectedEdgeDevices",
    label: localized["connected-edge-devices-label"],
  },
  {
    id: "createdBy",
    label: localized["created-by-label"],
  },
];

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all assets",
            }}
          />
        </TableCell>
        {headCells.map((headCell: HeadCell<Data>) => {
          return getTableCell<Data>(
            headCell,
            order,
            orderBy,
            createSortHandler
          );
        })}
        <TableCell key="deleteCol" align="left" padding="none" />
      </TableRow>
    </TableHead>
  );
}

const AssetsTable = () => {
  let { siteId } = useParams();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [rows, setRows] = useState<Data[]>([]);
  const [filteredRows, setFilteredRows] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAssetsDeleted, setIsAssetsDeleted] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [selectedAssetToDelete, setSelectedAssetToDelete] = useState<
    readonly number[]
  >([]);
  const [isAssetsAssignedDevice, setIsAssetsAssignedDevice] = useState(false);

  const checkDevicesAssignedToAssets = () => {
    const selectedAssets = filteredRows.filter((asset) =>
      selected.includes(asset.id)
    );
    const devicesAssignedToAssets = selectedAssets.filter(
      (asset) => asset.connectedEdgeDevices !== ""
    );
    setIsAssetsAssignedDevice(devicesAssignedToAssets.length > 0);
  };

  const renderText = (
    singleAssetText: string,
    multiAssetText: string,
    canNotDeleteSingleAssetText: string,
    canNotDeleteMultiAssetText: string
  ) => {
    if (!isAssetsAssignedDevice) {
      return selectedAssetToDelete.length === 1
        ? singleAssetText
        : multiAssetText;
    } else {
      return selectedAssetToDelete.length === 1 
        ? canNotDeleteSingleAssetText 
        : canNotDeleteMultiAssetText;
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    setFilteredRows(
      rows.filter((row) =>
        Object.values(row).some((val) =>
          val?.toString().toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleDeleteSuccess = (selectedAssetsIds: readonly number[]) => {
    setIsAssetsDeleted(true);
    setOpenDeleteConfirmDialog(false);

    const message =
      selectedAssetsIds.length > 1
        ? localized["assets-deleted-success-msg"]
        : localized["asset-deleted-success-msg"];

    ShowSnackbar(message, true, enqueueSnackbar);

    setSelected((selected) =>
      selected.filter((item) => !selectedAssetToDelete.includes(item))
    );
  };

  const handleDeleteFailure = (selectedAssetsIds: readonly number[]) => {
    const message =
      selectedAssetsIds.length > 1
        ? localized["assets-deleted-failed-msg"]
        : localized["asset-deleted-failed-msg"];

    ShowSnackbar(message, false, enqueueSnackbar);
  };

  const handleDelete = (selectedAssetsIds: readonly number[]) => {
    client
      .mutate({
        mutation: DELETE_ASSET,
        variables: {
          assetIDs: selectedAssetsIds,
        },
      })
      .then(() => handleDeleteSuccess(selectedAssetsIds))
      .catch(() => handleDeleteFailure(selectedAssetsIds));
  };
  const emptyRows =
    page - 1 > 0 ? Math.max(0, page * rowsPerPage - filteredRows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  const fetchAllAssetsBySiteId = async (siteId: string | undefined) => {
    try {
      setLoading(true);
      const response: ApolloQueryResult<GetAllAssetsBySiteId> =
        await client.query({
          query: GET_ALL_ASSETS_BY_SITE_ID,
          variables: { siteID: siteId },
          fetchPolicy: "no-cache",
        });
      handleFetchAssetsSuccess(response);
    } catch (error) {
      handleFetchAssetsFailure();
    } finally {
      setLoading(false);
    }
  };

  const handleFetchAssetsSuccess = (
    response: ApolloQueryResult<GetAllAssetsBySiteId>
  ) => {
    const rowsData: Data[] = [];
    const allAssetsDataBySiteId = response.data.getAllAssetsBySiteId;

    allAssetsDataBySiteId.forEach((assetData) => {
      rowsData.push(
        createData(
          Number(assetData.id),
          assetData.name,
          assetData.type,
          assetData.capacity + " " + assetData.capacityUnit,
          assetData.devicesInAsset.map((device) => device.name).join(", "),
          assetData.createdBy
        )
      );
    });

    setRows(rowsData);
    setFilteredRows(rowsData);
  };

  const handleFetchAssetsFailure = () => {
    ShowSnackbar(
      localized["failed-to-fetch-site-details"],
      false,
      enqueueSnackbar
    );
  };

  useEffect(() => {
    fetchAllAssetsBySiteId(siteId);

    if (isAssetsDeleted) {
      fetchAllAssetsBySiteId(siteId);
      setIsAssetsDeleted(false);
    }
  }, [siteId, client, enqueueSnackbar, isAssetsDeleted]);

  return (
    <>
      {rows?.length > 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          alignItems="flex-start"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignSelf="stretch"
          >
            {getSearchbar(searchQuery, handleSearchChange)}
            <Button
              onClick={() => {
                checkDevicesAssignedToAssets();
                setSelectedAssetToDelete(selected);
                setOpenDeleteConfirmDialog(true);
              }}
              disabled={selected?.length === 0}
              sx={{
                "&:disabled": { color: "#ADA9C2" },
                padding: "6px 16px",
                borderRadius: "24px",
              }}
            >
              <Typography variant="h5" sx={{ textTransform: "none" }}>
                {localized["delete-button"]}
              </Typography>
            </Button>
          </Box>
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              alignSelf="center"
              height="500px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                minHeight: "calc(100vh - 424px)",
                border: "1px solid #DEDEDE",
                borderRadius: "8px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "calc(100vh - 424px)",
                  boxShadow: "none",
                  borderRadius: "8px",
                }}
              >
                <Table stickyHeader aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={filteredRows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(Number(row.id));
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onClick={(event) =>
                                handleClick(
                                  event,
                                  Number(row?.id),
                                  selected,
                                  setSelected
                                )
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={tableRowStyle}
                          >
                            {row.assetName}
                          </TableCell>
                          <TableCell align="left" sx={tableRowStyle}>
                            {row.assetType}
                          </TableCell>
                          <TableCell align="left" sx={tableRowStyle}>
                            {row.installedCapacity}
                          </TableCell>
                          <TableCell align="left" sx={tableRowStyle}>
                            {row.connectedEdgeDevices === "" ? (
                              <Link underline="none" component="button">
                                <Typography
                                  variant="overline"
                                  sx={{
                                    textTransform: "none",
                                    lineHeight: "22px",
                                    borderBottom: "1.5px solid #8A00E5",
                                  }}
                                >
                                  {localized["map-edge-device-link"]}
                                </Typography>
                              </Link>
                            ) : (
                              row.connectedEdgeDevices
                            )}
                          </TableCell>
                          <TableCell align="left" sx={tableRowStyle}>
                            {row.createdBy}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "18px !important",
                              paddingRight: "0px !important",
                            }}
                          >
                            <IconButton
                              data-testid={`edit-asset-${row.id}`}
                              sx={{ padding: "0px", marginRight: "12px" }}
                              onClick={() => {
                                navigate(
                                  `/engineering/site/${siteId}/energyassets/editAsset/${row.id}`
                                );
                              }}
                            >
                              <EditIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </IconButton>
                            <IconButton
                              data-testid={`delete-single-asset-${row.id}`}
                              onClick={() => {
                                setSelectedAssetToDelete([row.id]);
                                setIsAssetsAssignedDevice(
                                  row.connectedEdgeDevices !== ""
                                );
                                setOpenDeleteConfirmDialog(true);
                              }}
                              sx={{ padding: "0px" }}
                            >
                              <DeleteIcon
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && getEmptyRow(emptyRows)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {getPagination(
            visibleRows,
            filteredRows,
            rowsPerPage,
            page,
            handleChangePage,
            handleChangeRowsPerPage
          )}
          <ConfirmationDialog
            isDialogOpen={openDeleteConfirmDialog}
            handleCloseDialog={() => {
              setOpenDeleteConfirmDialog(false);
            }}
            title={renderText(
              localized["delete-single-assets-title"],
              localized["delete-multiple-assets-title"],
              localized["cannot-delete-single-asset-title"],
              localized["cannot-delete-multi-asset-title"]
            )}
            description={renderText(
              localized["delete-single-assets-desc"],
              localized["delete-multiple-assets-desc"],
              localized["cannot-delete-asset-desc"],
              ""
            )}
            leftButtonText={localized["cancel-btn"]}
            rightButtonText={localized["confirm-delete-btn"]}
            leftButtonClickHandler={() => {
              setOpenDeleteConfirmDialog(false);
            }}
            rightButtonClickHandler={() => {
              handleDelete(selectedAssetToDelete);
            }}
            isWarning={isAssetsAssignedDevice}
          />
        </Box>
      ) : (
        <Typography
          variant="overline"
          sx={{ color: "#959595", fontWeight: "700", textTransform: "none" }}
        >
          {localized["start-by-creating-a-new-asset"]}
        </Typography>
      )}
    </>
  );
};
export default AssetsTable;

export const getTableCell = <T extends {}>(
  headCell: HeadCell<T>,
  order: Order,
  orderBy: string,
  createSortHandler: Function
) => {
  const key =
    typeof headCell.id === "string" || typeof headCell.id === "number"
      ? headCell.id
      : 0;
  return (
    <TableCell
      key={key}
      align="left"
      padding="normal"
      sortDirection={orderBy === headCell.id ? order : false}
      sx={{ color: "#000000", fontWeight: "700", fontSize: "18px" }}
    >
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : "asc"}
        onClick={createSortHandler(headCell.id)}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
          <Box component="span" sx={visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};
export const getPagination = (
  visibleRows: any,
  filteredRows: any,
  rowsPerPage: any,
  page: any,
  handleChangePage: any,
  handleChangeRowsPerPage: any
) => {
  return (
    <Box
      display="flex"
      sx={{
        alignSelf: "center",
        gap: "16px",
        marginTop: visibleRows?.length > 0 ? "0px" : "calc(100vh - 444px)",
      }}
    >
      <Pagination
        count={Math.ceil(filteredRows?.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
        variant="outlined"
        shape="rounded"
        sx={tablePaginationStyle}
      />
      <Select
        labelId="row-per-page-select-label"
        id="row-per-page-select"
        value={rowsPerPage.toString()}
        onChange={handleChangeRowsPerPage}
        sx={tablePaginationDropDownStyle}
        MenuProps={selectMenuPropsStyle}
      >
        {dropDownMenuLengths.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
export const getSearchbar = (
  searchQuery: string,
  handleSearchChange: React.ChangeEventHandler
) => {
  return (
    <Search
      sx={{
        display: "flex",
        height: "44px",
        width: "240px !important",
        marginLeft: "0px !important",
      }}
    >
      <SearchIconWrapper>
        <SearchIcon sx={{ width: "24px", height: "24px", color: "#5D596E" }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        sx={{ minWidth: "240px" }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </Search>
  );
};
export const getEmptyRow = (emptyRows: number) => {
  return (
    <TableRow
      style={{
        height: emptyRows,
      }}
    >
      <TableCell colSpan={6} sx={{ borderBottom: "none" }} />
    </TableRow>
  );
};
