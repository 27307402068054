import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import localized from "../en.json";
import { DeleteIcon, EditIcon } from "../theme/Icons/IshIcons";
import { convertDateTime } from "./DateUtil";

type PropType = {
  name: string | undefined;
  description: string | undefined;
  createdOn: string | undefined;
  onEditClickHandler: Function;
  onDeleteClickHandler: Function;
};
export const NameAndDescriptionCard = (props: PropType) => {
  return (
    <Card
      data-testid="customerCard"
      sx={{
        width: "100%",
        boxShadow: "none !important",
        borderBottom: "1px solid #DEDEDE",
      }}
    >
      <CardContent sx={{ padding: "0px !important", width: "100%" }}>
        <Stack padding="24px 32px 16px 32px">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h4" lineHeight="35px" fontWeight="400">
              {props?.name}
            </Typography>
            <Typography color="#393939" fontSize="12px">
              {localized["created-on"]}
              <span style={{ marginRight: "4px" }}>:</span>{" "}
              {convertDateTime(props?.createdOn)}
            </Typography>
          </Stack>
          <Typography
            variant="h5"
            fontWeight="400"
            color="#393939"
            maxWidth={"100%"}
            minHeight={"60px"}
            maxHeight={"65px"}
            sx={{
              wordBreak: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props?.description}
          </Typography>
          <Box display="flex" sx={{ justifyContent: "flex-end" }}>
            <Box display="flex">
              <IconButton
                data-testid="edit"
                onClick={() => {
                  props.onEditClickHandler();
                }}
              >
                <EditIcon
                  fill="#8A00E5"
                  sx={{
                    color: "#8A00E5",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  props.onDeleteClickHandler();
                }}
              >
                <DeleteIcon
                  fill="#8A00E5"
                  data-testid="deleteButton"
                  sx={{
                    color: "#8A00E5",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
