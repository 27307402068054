import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { FormDetail, FormField } from "../Models/models";
import { regex } from "../components/Engineering/ProjectManagement/SitesDetails/CreateSite/CreateSiteDialog";
import localized from "../en.json";

interface PropTypes {
  open: boolean;
  handleClose: () => void;
  formDetail: FormDetail;
  onSubmitHandler: Function;
  submitStatus: boolean;
  defaultDetail?: FormField;
}

const CreateDialog = (props: PropTypes) => {
  const {
    open,
    handleClose,
    formDetail,
    onSubmitHandler,
    submitStatus,
    defaultDetail,
  } = props;

  const schema = z.object({
    name: z
      .string()
      .min(1, { message: localized["required"] })
      .regex(regex, {
        message: localized["only-alphanumeric-allowed"],
      })
      .max(50, {
        message: formDetail?.nameLabel + localized["name_max_validation"],
      }),
    description: z.string().max(300, {
      message:
        formDetail?.descriptionLabel + localized["description_max_validation"],
    }),
  });

  type FormValues = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, isValid, errors },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: defaultDetail,
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    onSubmitHandler(data);
  };

  useEffect(() => {
    reset(defaultDetail);
  }, [defaultDetail, reset]);

  useEffect(() => {
    if (submitStatus) {
      reset();
      handleClose();
    }
  }, [submitStatus]);

  const handleNameChange = (event: any) => {
    const { value } = event.target;
    const trimmedValue = value.replace(/^ +/, "");
    setValue("name", trimmedValue);
    event.target.value = trimmedValue;
    return;
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ border: "1px solid #EAEAEA", borderRadius: "8px" }}
      >
        <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
          <DialogTitle
            sx={{
              m: 0,
              fontWeight: "400",
              fontSize: "28px",
              lineHeight: "36px",
            }}
            id="customized-dialog-title"
          >
            {formDetail?.title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              reset();
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: 32,
              top: 24,
              color: "#0D0D0D",
            }}
          >
            <CloseIcon
              style={{
                width: "32px",
                height: "32px",
              }}
            />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ padding: "32px" }}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel htmlFor="name">
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                    span: {
                      color: "#DA1E28",
                    },
                  }}
                >
                  {formDetail?.nameLabel}
                  <span>*</span>
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "16px" }}>
                <TextField
                  id="name"
                  placeholder={formDetail?.namePlaceholder}
                  {...register("name")}
                  error={errors.name !== undefined}
                  helperText={errors.name?.message}
                  sx={{ ".MuiOutlinedInput-root": { width: "240px" } }}
                  //defaultValue={defaultDetail?.name}
                  inputProps={{
                    style: {
                      width: "240px",
                      height: "11px",
                    },
                    onInput: (event: any) => {
                      handleNameChange(event);
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <InputLabel
                htmlFor="description"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    marginBottom: "8px",
                    color: "#15131F",
                  }}
                >
                  {formDetail?.descriptionLabel}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 400,
                    marginBottom: "8px",
                    color: "#15131F",
                  }}
                >
                  {watch("description")?.length || 0}/300
                </Typography>
              </InputLabel>
              <FormControl sx={{ marginBottom: "20px" }}>
                <TextField
                  id="description"
                  placeholder={formDetail?.descriptionPlaceholder}
                  type="text"
                  {...register("description")}
                  error={errors.description !== undefined}
                  helperText={errors.description?.message}
                  //defaultValue={defaultDetail?.description}
                  multiline
                  minRows={6}
                  inputProps={{
                    style: {
                      width: "808px",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px",
                    },
                  }}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              flexDirection: "column",
              alignItems: "center",
              padding: "32px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "24px",
                width: "100%",
              }}
              type="submit"
              disabled={!(isDirty && isValid)}
            >
              {formDetail?.saveBtnText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateDialog;
