import {
  ApolloError,
  ApolloQueryResult,
  gql,
  useApolloClient,
} from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormDetail, FormField } from "../../../../Models/models";
import localized from "../../../../en.json";
import { ProjectListContext } from "../../../../store/projectList-context";
import CreateDialog from "../../../../util/CreateDialog";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import CustomerDetailsCard from "./CustomerDetailsCard/CustomerDetailsCard";
import ProjectCardList from "./ProjectCardList/ProjectCardList";

export const GET_CUSTOMER_DETAILS_BY_ID = gql`
  query ($customerID: ID!) {
    getCustomerById(customerID: $customerID) {
      id
      name
      description
      createdAt
      projects {
        id
      }
      devices {
        id
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation ($name: String!, $description: String, $customerID: Int!) {
    createProject(
      projectDtoReq: {
        name: $name
        description: $description
        customerID: $customerID
      }
    ) {
      id
    }
  }
`;

interface PropTypes {
  isCustomerUpdated: boolean;
  setIsCustomerUpdated: Function;
}

const CustomerDetails = (props: PropTypes) => {
  const { isCustomerUpdated, setIsCustomerUpdated } = props;
  let { customerId } = useParams();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [customerDetail, setCustomerDetail] = useState();
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const projectListContext = useContext(ProjectListContext);

  const handleClickOpenProjectDialog = () => {
    setOpenProjectDialog(true);
  };
  const handleCloseProjectDialog = () => {
    setOpenProjectDialog(false);
  };

  const getCustomerDetailsById = () => {
    client
      .query({
        query: GET_CUSTOMER_DETAILS_BY_ID,
        variables: {
          customerID: customerId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<any>) => {
        setCustomerDetail(response.data.getCustomerById);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-customer-details"],
          false,
          enqueueSnackbar
        );
      });
  };

  useEffect(() => {
    getCustomerDetailsById();
    if (isCustomerUpdated) {
      getCustomerDetailsById();
      setIsCustomerUpdated(false);
    }
  }, [customerId, isCustomerUpdated]);

  const projectFormDetail: FormDetail = {
    title: localized["new-project-dialog-title"],
    nameLabel: localized["project-name-label"],
    namePlaceholder: localized["project-name-placeholder"],
    descriptionLabel: localized["description-label"],
    descriptionPlaceholder: localized["project-description-placeholder"],
    saveBtnText: localized["create-button"],
  };
  const createNewProject = (createDataProject: any) => {
    setSubmitStatus(false);
    const { name, description } = createDataProject;
    client
      .mutate({
        mutation: CREATE_PROJECT,
        variables: {
          name: name,
          description: description,
          customerID: customerId,
        },
      })
      .then(() => {
        setSubmitStatus(true);
        ShowSnackbar(
          localized["project-creation-success-msg"],
          true,
          enqueueSnackbar
        );
        projectListContext?.getProjectsByCustId(customerId);
        getCustomerDetailsById();
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["project-creation-failed-msg"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };

  const onSubmitHandlerProject = (data: FormField) => {
    createNewProject(data);
  };

  return (
    <>
      {customerDetail && (
        <Stack sx={{ margin: "24px 32px", width: "78%", gap: "24px" }}>
          <CustomerDetailsCard
            customerDetail={customerDetail}
            setIsCustomerUpdated={setIsCustomerUpdated}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
            <Typography
              variant="h4"
              sx={{ lineHeight: "34px", color: "#0D0D0D" }}
            >
              {localized["projects"]}
            </Typography>
            <Button
              disableRipple
              variant="contained"
              sx={{
                borderRadius: "24px",
                height: "44px",
                alignItems: "center",
              }}
              startIcon={<AddIcon sx={{ width: "24px", height: "24px" }} />}
              onClick={handleClickOpenProjectDialog}
            >
              <Typography
                variant="h5"
                sx={{
                  textTransform: "none",
                }}
              >
                {localized["new-project-button"]}
              </Typography>
            </Button>
          </Box>
          <ProjectCardList customerId={customerId} />
        </Stack>
      )}
      <CreateDialog
        open={openProjectDialog}
        handleClose={handleCloseProjectDialog}
        formDetail={projectFormDetail}
        onSubmitHandler={onSubmitHandlerProject}
        submitStatus={submitStatus}
      />
    </>
  );
};

export default CustomerDetails;
