import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import localized from '../../../../en.json';

const CustomizedDialog = styled(Dialog)(() => ({
    '& .MuiDialogContent-root': {
        padding: "25px",
    },
    '& .MuiDialogActions-root': {
        padding: "16px",
        marginRight: "22px"
    },
}));

interface PropsType {
    openDialog: boolean;
    setOpenDialog: Function;
}
const SoftwareNotUploadedDialog = (props: PropsType) => {
    const { openDialog, setOpenDialog } = props;
    const navigate = useNavigate();
    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleVisitSoftwareStore = () => {
        navigate(`/software-management`);
    }

    return (
        <React.Fragment>
            <CustomizedDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Typography variant='h4' color="#1B1534">{localized['software-not-uploaded-dialog-title']}</Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 24,
                        top: 8,
                        color: "#1B1534",
                    }}
                >
                    <CloseIcon sx={{
                        width: "32px",
                        height: "32px",
                    }} />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>{localized['software-not-uploaded-dialog-content']}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleVisitSoftwareStore} sx={{ width: "184px", height: "44px", borderRadius: "24px" }}>
                        <Typography variant='h5' sx={{ textTransform: "none" }}>{localized['software-not-uploaded-dialog-action-btn']}</Typography>
                    </Button>
                </DialogActions>
            </CustomizedDialog>
        </React.Fragment>
    )
}

export default SoftwareNotUploadedDialog