import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import localized from "../../en.json";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../util/SearchStyleUtil";
import { selectMenuPropsStyle, selectSxStyle } from "../../util/StyleUtil";
import { ChangeEvent } from "react";

interface PropTypes {
  selectedProject: string;
  setSelectedProject: Function;
  onSearch: Function;
}
const EnergyAssetsFilter = (props: PropTypes) => {
  const { selectedProject, setSelectedProject, onSearch } = props;
  const handleProjectChange = (event: SelectChangeEvent) => {
    setSelectedProject(event.target.value);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
};

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ gap: "16px", alignItems: "flex-start" }}
    >
      <Typography variant="h4" sx={{ color: "#0D0D0D" }}>
        {localized["energy-assets"]}
      </Typography>
      <Box display="flex" gap="24px">
        <FormControl
          sx={{
            width: "240px",
            borderRadius: "4px",
            textAlign: "left",
          }}
        >
          <Select
            value={selectedProject}
            onChange={handleProjectChange}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
              "data-testid": "select-project",
            }}
            sx={selectSxStyle}
            MenuProps={selectMenuPropsStyle}
          >
            <MenuItem value="Fleet View">Fleet View</MenuItem>
            <MenuItem value="Project 1">Project 1</MenuItem>
            <MenuItem value="Project 2">Project 2</MenuItem>
            <MenuItem value="Project 3">Project 3</MenuItem>
          </Select>
        </FormControl>
        <Search
          sx={{
            display: "flex",
            height: "44px",
            width: "240px !important",
            marginLeft: "0px !important",
          }}
        >
          <SearchIconWrapper>
            <SearchIcon
              sx={{ width: "24px", height: "24px", color: "#5D596E" }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            sx={{ minWidth: "240px" }}
            onChange={handleSearch}
          />
        </Search>
      </Box>
    </Stack>
  );
};

export default EnergyAssetsFilter;
