import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { BreadCrumbContext } from "../../../store/breadcrumb-context";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import CustomerList from "./CustomerList/CustomerList";

export const CustomerManagement = () => {
  const [isCustomerUpdated, setIsCustomerUpdated] = useState(false);
  return (
    <Box display="flex" height="calc(100vh - 130px)" width={"100%"}>
      <CustomerList isCustomerUpdated={isCustomerUpdated} />
      <Routes>
        <Route path="/:customerId" element={<CustomerDetails isCustomerUpdated={isCustomerUpdated} setIsCustomerUpdated={setIsCustomerUpdated} />} />
      </Routes>
    </Box>
  );
};
