import { Box, Stack, Typography } from "@mui/material";

import { Route, Routes, useParams } from "react-router-dom";
import localized from "../../../../../en.json";
import EdgeDevicesList from "./EdgeDevicesList/EdgeDevicesList";
import EdgeDevicesTab from "./EdgeDevicesTabs/EdgeDevicesTabs";
interface PropTypes {
  siteId: number;
  setIsDeviceConfigUpdated: Function;
  isDeviceConfigUpdated: boolean;
}

const EdgeDeviceListAndTab = (props: PropTypes) => {
  const { siteId, isDeviceConfigUpdated, setIsDeviceConfigUpdated } = props;
  const deviceId = useParams();
  return (
    <Stack flexDirection="row" sx={{ padding: "24px 32px", width: "96%" }}>
      <Box display="flex" alignItems="flex-start" width={"100%"}>
        <EdgeDevicesList
          siteId={Number(siteId)}
          isDeviceConfigUpdated={isDeviceConfigUpdated}
        />
        <Box
          display="flex"
          flexDirection="column"
          marginLeft="32px"
          width={"80%"}
          sx={{ overflowX: "auto" }}
        >
          <Routes>
            <Route
              path="/device/:deviceId/tab1"
              element={
                <EdgeDevicesTab
                  setIsDeviceConfigUpdated={setIsDeviceConfigUpdated}
                />
              }
            />
            <Route
              path="/device/:deviceId/tab2"
              element={
                <EdgeDevicesTab
                  setIsDeviceConfigUpdated={setIsDeviceConfigUpdated}
                />
              }
            />
            <Route
              path="/device/:deviceId/tab3"
              element={
                <EdgeDevicesTab
                  setIsDeviceConfigUpdated={setIsDeviceConfigUpdated}
                />
              }
            />
          </Routes>
          {!deviceId["*"] && (
            <Typography
              variant="overline"
              sx={{
                color: "#959595",
                fontWeight: "700",
                textTransform: "none",
              }}
            >
              {localized["select-device-to-view-details"]}
            </Typography>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default EdgeDeviceListAndTab;
