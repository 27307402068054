import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { useSnackbar } from "notistack";
import localized from "../../../../en.json";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { SignalNameAndPath } from "../../../../Models/models";

interface PropType {
  setSignalsSelected: Function;
  selectedSignals: string[];
  deviceId: string | undefined;
  signalList: SignalNameAndPath[];
}

export const ParameterFilter = (props: PropType) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    MenuListProps: {
      sx: {
        "&.MuiList-root": {
          paddingTop: "0",
          paddingBottom: "0",
        },
      },
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
      sx: {
        "& .MuiMenuItem-root": {
          border: "1px solid #C0C0C0",
          height: "45px",
          width: "343px",
        },

        "&.MuiPaper-root": {
          borderRadius: 0,
        },
      },
    },
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    if (event.target.value.length > 5) {
      ShowSnackbar(
        localized["max-signal-selection-message"],
        false,
        enqueueSnackbar
      );
    } else {
      props.setSignalsSelected(event.target.value as string[]);
    }
  };

  return (
    <div>
      <FormControl
        sx={{ m: 1.5, width: 300, background: "#FFFFFF", textAlign: "left" }}
      >
        <Select
          data-testid="selection"
          sx={{ height: 45 }}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={props.selectedSignals}
          inputProps={{ "data-testid": "content-input" }}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected: any[]) =>
            selected.length === 0 ? (
              <>{localized["select-signals"]}</>
            ) : (
              selected
                .map(
                  (n) =>
                    props.signalList.find((signal) => signal.path === n)
                      ?.displayName ?? ""
                )
                .join(", ")
            )
          }
          displayEmpty
          MenuProps={MenuProps}
        >
          <MenuItem value="" disabled>
            {localized["select-signals"]}
          </MenuItem>
          {props.signalList.map((signal) => (
            <MenuItem key={signal.path} value={signal.path}>
              <Checkbox
                checked={props.selectedSignals.indexOf(signal.path) > -1}
              />
              <ListItemText primary={signal.displayName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
