import { ApolloError, gql, useApolloClient } from "@apollo/client";

import { useSnackbar } from "notistack";
import { useState } from "react";
import localized from "../../../../en.json";
import { FormDetail, ProjectInfo } from "../../../../Models/models";
import CreateDialog from "../../../../util/CreateDialog";
import { NameAndDescriptionCard } from "../../../../util/NameAndDescriptionCard";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { ConfirmationDialog } from "../../../../util/ConfirmationDialog";
import { useNavigate } from "react-router-dom";

interface PropTypes {
  projectId: string | undefined;
  projectInfo:ProjectInfo ;
  setProjectUpdated: Function;
}

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $customerID: Int!
    $id: Int!
    $name: String!
    $description: String
  ) {
    updateProject(
      projectDtoReq: {
        customerID: $customerID
        id: $id
        name: $name
        description: $description
      }
    ) {
      id
      name
    }
  }
`;
export const DELETE_PROJECT = gql`
  mutation ($projectID: Int!) {
    deleteProject(projectID: $projectID)
  }
`;
export const ProjectDetails = (props: PropTypes) => {
  const {
    projectInfo,
    projectId,
    setProjectUpdated,
  } = props;
  const [openProjectEditDialog, setOpenProjectEditDialog] = useState(false);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const handleEditDialogOpenClose = () => {
    setOpenProjectEditDialog(!openProjectEditDialog);
  };
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [
    isDeleteSiteConfirmationDialogOpen,
    setIsDeleteSiteConfirmationDialogOpen,
  ] = useState<boolean>(false);

  const handleCloseDeleteDialog = () => {
    setIsWarning(false);
    setIsDeleteSiteConfirmationDialogOpen(false);
  };

  const onDeleteSiteClickHandler = () => {
    setIsDeleteSiteConfirmationDialogOpen(true);
    if (
      projectInfo.sites.length > 0 
    ) {
      setIsWarning(true);
      setTitle(localized["cannot-delete-project"]);
      setDescription(localized["delete-project-warning"]);
    } else {
      setTitle(localized["delete-project"]);
      setDescription(localized["site-delete-confirmation"]);
    }
  };
  const onDeleteProjectConfirmHandler = () => {
    client
      .mutate({
        mutation: DELETE_PROJECT,
        variables: {
          projectID: projectId,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        setIsDeleteSiteConfirmationDialogOpen(false);
        ShowSnackbar(localized["delete-project-success"], true, enqueueSnackbar);
        navigate(`/engineering/customer/${projectInfo.customer?.id}`);
      })
      .catch(() => {
        setIsDeleteSiteConfirmationDialogOpen(false);
        ShowSnackbar(localized["delete-project-failed"], false, enqueueSnackbar);
      });
  };
  const handleEditSubmit = (editedDetails: any) => {
    setProjectUpdated(false);
    client
      .mutate({
        mutation: UPDATE_PROJECT,
        variables: {
          customerID: projectInfo.customer?.id,
          id: projectId,
          name: editedDetails.name,
          description: editedDetails.description,
        },
      })
      .then(() => {
        setOpenProjectEditDialog(false);
        setProjectUpdated(true);
        ShowSnackbar(
          localized["project-update-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["project-update-failed"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };

  const formDetail: FormDetail = {
    title: localized["edit-project-dialog-title"],
    nameLabel: localized["project-name-label"],
    namePlaceholder: localized["project-name-placeholder"],
    descriptionLabel: localized["description-label"],
    descriptionPlaceholder: localized["project-description-placeholder"],
    saveBtnText: localized["save-btn"],
  };

  return (
    <>
      <NameAndDescriptionCard
        name={projectInfo.name}
        description={projectInfo.description}
        createdOn={projectInfo.createdAt}
        onEditClickHandler={handleEditDialogOpenClose}
        onDeleteClickHandler={onDeleteSiteClickHandler}
      />
      <CreateDialog
        open={openProjectEditDialog}
        handleClose={handleEditDialogOpenClose}
        formDetail={formDetail}
        onSubmitHandler={handleEditSubmit}
        submitStatus={false}
        defaultDetail={{ name: projectInfo?.name, description: projectInfo?.description }}
      />
        {isDeleteSiteConfirmationDialogOpen && (
        <ConfirmationDialog
          isDialogOpen={isDeleteSiteConfirmationDialogOpen}
          handleCloseDialog={handleCloseDeleteDialog}
          title={title}
          description={description}
          leftButtonText={localized["cancel-btn"]}
          rightButtonText={localized["yes-delete"]}
          leftButtonClickHandler={handleCloseDeleteDialog}
          rightButtonClickHandler={onDeleteProjectConfirmHandler}
          isWarning={isWarning}
        />
      )}
    </>
  );
};
