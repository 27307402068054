import localized from "../../../../en.json";

import {
  AssetEdegeMappingIcon,
  EnergyAssetsIcon,
  IotDeviceIcon,
  ProjectSiteDetailsIcon
} from "../../../../theme/Icons/IshIcons";
import { MenuList } from "../../../../util/MenuList";

const siteDetailsEngineeringMenuList = [
  {
    name: localized["energy-assets"],
    icon: <EnergyAssetsIcon />,
  },
  {
    name: localized["asset-edge-mapping"],
    icon: <AssetEdegeMappingIcon />,
  },
  {
    name: localized["edge-devices"],
    icon: <IotDeviceIcon />,
  },
];

const siteDetailsManagementMenuList = [
  {
    name: localized["site-details"],
    icon: <ProjectSiteDetailsIcon />,
  },
];

export const SiteMenuList = () => {
  return (
    <MenuList
      engineeringMenuList={siteDetailsEngineeringMenuList}
      ManagementMenuList={siteDetailsManagementMenuList}
      displayText={localized["site-management"]}
    ></MenuList>
  );
};
