import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { zodResolver } from "@hookform/resolvers/zod";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from 'zod';
import { loginRequest } from "../../authConfig";
import localized from "../../en.json";
import { SE_ICON } from "../../theme/Icons/SE_Logo";
import "./signIn.css";

let validationSchema = z.object({
  email: z.string()
    .min(1, { message: localized['email-required'] })
    .email(localized['email-not-valid']),
  password: z.string()
    .min(1, { message: localized['password-required'] }),
});

const SignIn = () => {
  type FormValues = z.infer<typeof validationSchema>;

  const { register, handleSubmit, reset,
    formState: {
      isDirty,
      isValid,
      errors,
    } } = useForm<FormValues>({
      mode: 'onChange',
      resolver: zodResolver(validationSchema),
      defaultValues: {
        email: "",
        password: ""
      }
    });

  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      navigate("/dashboard/");
    }
  }, [isAuthenticated, inProgress, navigate]);

  const signIn: SubmitHandler<FormValues> = async (data) => {
    navigate("/dashboard");
    reset();
  };

  const initializeSignIn = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#1A1534",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="column" sx={{ ml: "80px", padding: "50px" }}>
        <SE_ICON
          sx={{
            width: "186px",
            height: "60px",
            marginBottom: "44px",
            color: "white",
          }}
        />
        <form className="login-form" onSubmit={handleSubmit(signIn)}>
          <Stack
            sx={{
              backgroundColor: "#2D114B",
              color: "white",
              padding: "40px",
            }}
          >
            <Stack
              sx={{
                borderBottom: "1px solid #A451F1",
              }}
            >
              <Typography
                variant="body2"
                sx={{ margin: 0, marginBottom: "8px", fontWeight: "500" }}
              >
                {localized["greet"]}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "25.5px",
                  fontWeight: "700",
                  margin: " 8px 0 14px 0",
                  lineHeight: "27px",
                }}
              >
                {localized["app-name"]}
              </Typography>
              <FormControl sx={{ margin: "10px 0" }} variant="outlined">
                <TextField
                  id="outlined-adornment-email"
                  aria-describedby="outlined-weight-helper-text"
                  size="small"
                  placeholder="Email"
                  {...register('email')}
                  error={errors.email !== undefined}
                  helperText={errors.email?.message}
                  inputProps={{
                    style: {
                      backgroundColor: "white",
                      border: "1px solid #ADA9C2",
                      borderRadius: "4px",
                    },
                    "aria-label": "email",
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px"
                    }
                  }}
                />
              </FormControl>
              <FormControl sx={{ margin: "10px 0" }} variant="outlined">
                <TextField
                  id="outlined-adornment-password"
                  size="small"
                  placeholder="Password"
                  type="password"
                  {...register('password')}
                  error={errors.password !== undefined}
                  helperText={errors.password?.message}
                  inputProps={{
                    style: {
                      backgroundColor: "white",
                      border: "1px solid #ADA9C2",
                      borderRadius: "4px",
                    },
                    "aria-label": "password",
                  }}
                  FormHelperTextProps={{
                    sx: {
                      marginLeft: "0px"
                    }
                  }}
                />
              </FormControl>
              <Stack
                direction="row"
                sx={{ marginTop: "25px", marginBottom: "32px" }}
                gap="30px"
                alignItems="center"
              >
                <Button
                  type="submit"
                  sx={{
                    borderRadius: "25px",
                    padding: "8px 25px 8px 25px",
                    fontSize: "18px",
                    height: "auto",
                    textTransform: "none",
                    lineHeight: "13px",
                    maxWidth: "120px",
                    "&.Mui-disabled": {
                      color: "white",
                      backgroundColor: "#ADA9C2",
                    },
                  }}
                  variant="contained"
                  disabled={!(isDirty && isValid)}
                >
                  {localized["login"]}{" "}
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: 24, marginTop: "3px" }}
                  />
                </Button>
                <Link
                  href="/"
                  target="_blank"
                  rel="noopener"
                  underline="always"
                  color="rgba(255,255,255,1)"
                >
                  {localized["forgot-pass"]}
                </Link>
              </Stack>
            </Stack>
            <Button
              type="button"
              onClick={initializeSignIn}
              variant="text"
              disableRipple
              sx={{
                textTransform: "none",
                textDecoration: "underline white",
                marginTop: "32px",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  margin: 0,
                  marginBottom: "8px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  fontSize: "18px",
                  color: "white",
                }}
              >
                {localized["login-with-org"]}
              </Typography>
            </Button>
          </Stack>
        </form>
      </Stack>
      <Box display="flex">
        <video autoPlay loop muted id="video" className="rocLogo">
          <source src={require("../../theme/Icons/sphereVideo.mp4")} />
        </video>
      </Box>
    </Box>
  );
};

export default SignIn;
