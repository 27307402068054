import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Asset, Device } from "../../../../Models/models";
import localized from "../../../../en.json";
import { arraysEqual } from "../../../../util/ArrayUtil";
import { ListStyle } from "../../../../util/StyleUtil";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";

export const GET_ASSETS_BY_SITE_ID = gql`
  query ($siteID: Int!) {
    getAllAssetsBySiteId(siteID: $siteID) {
      id
      name
      numberOfDevicesInAsset
      devicesInAsset {
        id
      }
    }
  }
`;
export const GET_DEVICES_BY_SITE_ID = gql`
  query ($siteID: Int!) {
    getDevicesBySiteID(siteID: $siteID) {
      id
      name
      numberOfAssetsInDevice
    }
  }
`;
export const ASSIGN_DEVICES_TO_ASSET = gql`
  mutation ($deviceIDs: [String]!, $assetID: Int!) {
    assignDevicesToAsset(deviceIDs: $deviceIDs, assetID: $assetID)
  }
`;
type Props = {
  siteId: string | undefined;
};
export const AssetEdgeMapping = (props: Props) => {
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(
    undefined
  );
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const onAssetClickHandler = (asset: Asset) => {
    setSelectedAsset(asset);
    setSelectedDeviceIds(asset.devicesInAsset.map((device: any) => device.id));
  };

  const onDeviceCheckedHandler = (deviceId: string) => {
    setSelectedDeviceIds((prevDeviceIds: string[]) => {
      if (prevDeviceIds.includes(deviceId)) {
        return prevDeviceIds.filter((item: string) => item !== deviceId);
      } else {
        return [...prevDeviceIds, deviceId];
      }
    });
  };
  const getAllAssetsForSite = () => {
    client
      .query({
        query: GET_ASSETS_BY_SITE_ID,
        fetchPolicy: "no-cache",
        variables: {
          siteID: props.siteId,
        },
      })
      .then((response: ApolloQueryResult<any>) => {
        setAssets(response.data.getAllAssetsBySiteId);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-assets"],
          false,
          enqueueSnackbar
        );
      });
  };
  const getAllDevicesForSite = () => {
    client
      .query({
        query: GET_DEVICES_BY_SITE_ID,
        fetchPolicy: "no-cache",
        variables: {
          siteID: props.siteId,
        },
      })
      .then((response: ApolloQueryResult<any>) => {
        setDevices(response.data.getDevicesBySiteID);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-devices"],
          false,
          enqueueSnackbar
        );
      });
  };
  useEffect(() => {
    getAllAssetsForSite();
    getAllDevicesForSite();
  }, []);

  useEffect(() => {
    if (!selectedAsset && assets.length > 0) {
      setSelectedAsset(assets[0]);
      setSelectedDeviceIds(
        assets[0]!.devicesInAsset.map((device: any) => device.id)
      );
    }
  }, [assets]);
  useEffect(() => {
    if (selectedAsset) {
      setSelectedAsset(
        assets.filter((asset: Asset) => asset.id === selectedAsset?.id)[0]
      );
    }
  }, [assets]);
  const assignDevicesToAsset = () => {
    client
      .mutate({
        mutation: ASSIGN_DEVICES_TO_ASSET,
        variables: {
          deviceIDs: selectedDeviceIds,
          assetID: selectedAsset?.id,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        getAllAssetsForSite();
        getAllDevicesForSite();
        ShowSnackbar(
          localized["asset-edge-mapping-updated-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch(() => {
        ShowSnackbar(
          localized["asset-edge-mapping-updated-failed"],
          false,
          enqueueSnackbar
        );
      });
  };

  return (
    <Stack padding={"32px"} width={"96%"} height={"93%"}>
      <Stack direction={"row"} justifyContent={"flex-end"} width={"99%"}>
        <Button
          disableRipple
          variant="contained"
          sx={{
            borderRadius: "24px",
            height: "40px",
            width: "86px",
          }}
          disabled={
            assets.length === 0 ||
            arraysEqual(
              selectedAsset?.devicesInAsset?.map((device: any) => device.id),
              selectedDeviceIds
            )
          }
          onClick={assignDevicesToAsset}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "none",
            }}
          >
            {localized["update-button"]}
          </Typography>
        </Button>
      </Stack>
      <Stack direction={"row"} gap="55%" width={"100%"}>
        <Typography
          fontSize={"22px"}
          lineHeight={"28px"}
          fontWeight={700}
          padding={"16px"}
        >
          {localized["assets"]}
        </Typography>
        <Typography
          fontSize={"22px"}
          lineHeight={"28px"}
          fontWeight={700}
          padding={"16px"}
          marginLeft={"8px"}
        >
          {localized["edge-devices"]}
        </Typography>
      </Stack>
      <Stack
        width={"99.4%"}
        height={"80%"}
        border={"1px solid #DEDEDE"}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack width={"41%"} height={"100%"}>
          <Stack
            width={"93.5%"}
            direction={"row"}
            justifyContent={"space-between"}
            padding={"8px 24px"}
            height={"24px"}
          >
            <Typography variant="h5" fontWeight={400}>
              {localized["asset"]}
            </Typography>
            <Typography variant="h5" fontWeight={400}>
              {localized["connected-to"]}
            </Typography>
          </Stack>
          <Divider />
          {assets.length === 0 && (
            <>
              <Typography
                padding={"10px"}
                color={"#959595"}
                fontWeight={700}
                fontSize={"14px"}
                textAlign={"center"}
              >
                {localized["start-creating-new-asset"]}
              </Typography>
              <Divider></Divider>
            </>
          )}
          <List sx={ListStyle}>
            {assets.map((asset: Asset) => {
              return (
                <>
                  <ListItem
                    key={asset.id}
                    data-testid={`item-${asset.id}`}
                    sx={{
                      height: "64px",
                      paddingRight: "26px",
                      paddingLeft: "20px",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                      borderLeft:
                        selectedAsset?.id === asset.id
                          ? "4px solid #8A00E5"
                          : "4px solid #ffffff",
                      "&:hover": {
                        backgroundColor: "#EAEAEA",
                        borderLeft:
                          selectedAsset?.id === asset.id
                            ? "4px solid #8A00E5"
                            : "4px solid #EAEAEA",
                      },
                    }}
                    onClick={() => onAssetClickHandler(asset)}
                  >
                    <Stack
                      direction={"row"}
                      flexWrap={"wrap"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography
                        variant="body2"
                        lineHeight={"24px"}
                        fontSize={"16px"}
                        style={{ maxWidth: "calc(100% - 120px)" }}
                      >
                        {asset.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        lineHeight={"22px"}
                        fontSize={"16px"}
                        color={"#5F5F5F"}
                      >
                        {asset.numberOfDevicesInAsset}{" "}
                        {asset.numberOfDevicesInAsset > 1
                          ? "devices"
                          : "device"}
                      </Typography>
                    </Stack>
                  </ListItem>
                  <Divider key={asset.name} />
                </>
              );
            })}
          </List>
        </Stack>
        <Stack width="41%">
          <Stack
            width={"93.5%"}
            direction={"row"}
            justifyContent={"space-between"}
            padding={"8px 24px"}
            height={"24px"}
          >
            <Typography variant="h5" fontWeight={400} marginLeft={"58px"}>
              {localized["edge-devices"]}
            </Typography>
            <Typography variant="h5" fontWeight={400}>
              {localized["connected-to"]}
            </Typography>
          </Stack>
          <Divider />
          {devices.length === 0 && (
            <>
              <Typography
                padding={"10px"}
                color={"#959595"}
                fontWeight={700}
                fontSize={"14px"}
                textAlign={"center"}
              >
                {localized["start-assigning-new-device"]}
              </Typography>
              <Divider></Divider>
            </>
          )}
          <List sx={ListStyle}>
            {devices.map((device: Device) => {
              return (
                <>
                  <ListItem
                    key={device.id}
                    data-testid={`item-${device.id}`}
                    sx={{
                      width: "100%",
                      height: "64px",
                      paddingRight: "26px",
                      paddingLeft: "20px",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#EAEAEA",
                      },
                    }}
                  >
                    <FormGroup
                      key={device.id + device.name}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        sx={{ ".MuiFormControlLabel-label": { width: "100%" } }}
                        control={
                          <Checkbox
                            checked={selectedDeviceIds?.includes(device.id)}
                            onChange={() => {
                              onDeviceCheckedHandler(device.id);
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction={"row"}
                            flexWrap={"wrap"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            width="100%"
                            marginLeft={"5%"}
                          >
                            <Typography
                              variant="body2"
                              lineHeight={"24px"}
                              fontSize={"16px"}
                              maxWidth={"70%"}
                              sx={{ wordWrap: "break-word" }}
                            >
                              {device.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              lineHeight={"22px"}
                              fontSize={"16px"}
                              color={"#5F5F5F"}
                            >
                              {device.numberOfAssetsInDevice}{" "}
                              {device.numberOfAssetsInDevice > 1
                                ? localized["assets-lowercase"]
                                : localized["asset"]}
                            </Typography>
                          </Stack>
                        }
                      />
                    </FormGroup>
                  </ListItem>
                  <Divider key={device.name} />
                </>
              );
            })}
          </List>
        </Stack>
      </Stack>
    </Stack>
  );
};
