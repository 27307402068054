import { Box, Divider, Typography } from "@mui/material";
import localized from "../../en.json";
import { NavigationIcon } from "../../theme/Icons/IshIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface NavigationItem {
  path: string;
  headerKey: string;
}

const navigationItems: NavigationItem[] = [
  {
    path: "/software-management",
    headerKey: localized["software-store-header"],
  },
];

interface PropTypes{
  handleDrawer:Function;
}
const MoreInfo = (props: PropTypes) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedPath, setSelectedPath] = useState<string>("");

  const onClick = (path: string) => {
    setSelectedPath(path);
    props.handleDrawer();
    navigate(path);
  };

  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  return (
    <Box>
      {navigationItems.map((item) => (
        <Box key={item.path}>
          <Box
            data-testid={item.headerKey}
            display="flex"
            sx={{
              padding: "16px",
              gap: "11px",
              cursor: "pointer",
              borderLeft:
                selectedPath === item.path
                  ? "5px solid #9747FF"
                  : "5px solid transparent",
            }}
            role="button"
            onClick={() => onClick(item.path)}
          >
            <Box display="flex" gap="8px">
              <NavigationIcon
                data-testid="software-store-icon"
                style={{
                  color: selectedPath === item.path ? "#9747FF" : "#0D0D0D",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  color: selectedPath === item.path ? "#9747FF" : "#0D0D0D",
                  fontWeight: "400",
                  pr: "12px",
                }}
              >
                {item.headerKey}
              </Typography>
            </Box>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default MoreInfo;
