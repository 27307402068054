import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { CircularProgress, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import localized from "../../../en.json";
import {
  CpuUsageType,
  MemoryType,
  SignalValueList,
  SystemHealthType
} from "../../../Models/models";
import ShowSnackbar from "../../CustomizedSnackbar/ShowSnackbar";
import CpuUsage from "./CpuUsage";
import Memory from "./Memory";
import SystemHealth from "./SystemHealth";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
  border: "1px solid #EAEAEA",
  boxShadow: "none",
  borderRadius: "8px",
  minHeight: "calc(100vh - 195px)",
  overflowY: "auto",
  maxHeight: "20vh",
}));

export const SIGNAL_VALUES = gql`
  query ($deviceId: String!, $signals: [String]!) {
    fetchSignalsLastValue(deviceId: $deviceId, signalsNodePath: $signals) {
      nodePath
      signalValues {
        value
        timestamp
      }
    }
  }
`;
const getSignalValue = (signalsLastValue: SignalValueList[], nodePath: string) => {
  return signalsLastValue?.find((signalValueList: SignalValueList) => signalValueList.nodePath === nodePath)?.signalValues[0];
};

interface PropTypes {
  deviceId: string | undefined;
  setSignalsSelected: Function;
}

const DeviceMonitor = (props: PropTypes) => {
  const { deviceId, setSignalsSelected } = props;
  const signalList = useMemo(() => {
    return [
      "framework/metrics/system/cpu-utilisation-percent",
      "framework/metrics/system/memavailable-mb",
      "framework/metrics/system/membuffers-mb",
      "framework/metrics/system/memcache-mb",
      "framework/metrics/system/memtotal-mb",
      "framework/metrics/system/memused-mb",
      "framework/metrics/system/memused-percent",
      "framework/metrics/system/memfree-mb",
      "system/health/battery/value",
      "system/health/boot-count/value",
      "system/health/operation-hours/value",
      "system/health/temperature-system/value",
      "system/health/temperature-core/value",
      "system/resources/cpu/core0/usage",
      "system/resources/cpu/core1/usage",
      "system/resources/cpu/core2/usage",
      "system/resources/cpu/core3/usage",
    ];
  }, []);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [systemHealth, setSystemHealth] = useState<
    SystemHealthType | undefined
  >();
  const [memory, setMemory] = useState<MemoryType | undefined>();
  const [cpuUsage, setCpuUsage] = useState<CpuUsageType | undefined>();

  useEffect(() => {
    const fetchUpdatedSignalValues = () => {
      client
        .query({
          query: SIGNAL_VALUES,
          variables: {
            deviceId: deviceId,
            signals: signalList,
          },
          fetchPolicy: "no-cache",
        })
        .then((response: any) => {
          const signalsLastValue = response.data?.fetchSignalsLastValue;

          setSystemHealth({
            systemTemp: getSignalValue(signalsLastValue, signalList[11]),
            cpuTemp: getSignalValue(signalsLastValue, signalList[12]),
            batteryVoltage: getSignalValue(signalsLastValue, signalList[8]),
            operatingHours: getSignalValue(signalsLastValue, signalList[10]),
            startUpCount: getSignalValue(signalsLastValue, signalList[9]),
          });
          setMemory({
            freeMemory: getSignalValue(signalsLastValue, signalList[7]),
            usageMemory: getSignalValue(signalsLastValue, signalList[5]),
            availableMemory: getSignalValue(signalsLastValue, signalList[1]),
            operatingHours: getSignalValue(signalsLastValue, signalList[10]),
            totalMemory: getSignalValue(signalsLastValue, signalList[4]),
            cache: getSignalValue(signalsLastValue, signalList[3]),
            buffer: getSignalValue(signalsLastValue, signalList[2]),
            available: getSignalValue(signalsLastValue, signalList[1]),
            memoryUsed: getSignalValue(signalsLastValue, signalList[5]),
          });
          setCpuUsage({
            core0: getSignalValue(signalsLastValue, signalList[13]),
            core1: getSignalValue(signalsLastValue, signalList[14]),
            core2: getSignalValue(signalsLastValue, signalList[15]),
            core3: getSignalValue(signalsLastValue, signalList[16]),
          });
        })
        .catch((err: ApolloError) => {
          ShowSnackbar(
            localized["failed-fetch-signalList"] + err.message,
            false,
            enqueueSnackbar
          );
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchUpdatedSignalValues();
    const interval = setInterval(() => {
      fetchUpdatedSignalValues();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [deviceId, client, enqueueSnackbar, signalList]);

  return (
    <Item sx={{ width: "100%", height: "100vh" }}>
      {loading ? (
        <Stack
          width="100%"
          height="calc(100vh - 195px)"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack p="24px" pt="0px">
          <SystemHealth
            systemHealth={systemHealth}
            setSignalsSelected={setSignalsSelected}
          />
          <Memory
            memory={memory}
            setSignalsSelected={setSignalsSelected}
          />
          <CpuUsage
            cpuUsage={cpuUsage}
            setSignalsSelected={setSignalsSelected}
          />
        </Stack>
      )}
    </Item>
  );
};

export default DeviceMonitor;
