import { gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Site } from "../../../../../Models/models";
import localized from "../../../../../en.json";
import DetailsCard from "../../../../../util/DetailsCard";

export const GET_ALL_SITES_FOR_PROJECT = gql`
  query ($projectID: Int!) {
    getAllSitesByProjectId(projectID: $projectID) {
      id
      name
      numberOfAssets
      numberOfDevices
    }
  }
`;
type Props = {
  siteList: Site[];
};

export const SiteList = (props: Props) => {
  const navigate = useNavigate();
  const onSiteClickHandler = (siteId: number) => {
    navigate(`/engineering/site/${siteId}/energyassets`);
  };
  return (
    <>
      {props.siteList.length > 0 ? (
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          sx={{ overflowY: "auto", scrollbarWidth: "thin", maxHeight: "78%" }}
        >
          {props.siteList.map((site: Site) => (
            <DetailsCard
              key={site.id}
              cardDetails={site}
              onClickHandler={onSiteClickHandler}
              backgroundColor="#F2F2F2"
            />
          ))}
        </Stack>
      ) : (
        <Typography
          variant="overline"
          sx={{
            color: "#959595",
            textTransform: "none",
            fontWeight: "700",
            marginTop: "8px",
          }}
        >
          {localized["no-sites-to-show"]}
        </Typography>
      )}
    </>
  );
};
