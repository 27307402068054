import { Route, Routes } from "react-router-dom";
import ProjectListContextProvider from "../../store/projectList-context";
import { CustomerManagement } from "./CustomerManagement/CustomerManagement";
import { ProjectManagement } from "./ProjectManagement/ProjectManagement";
import { SiteManagement } from "./SiteManagement/SiteManagement";

const Engineering = () => {
  return (
    <>
      <ProjectListContextProvider>
        <Routes>
          <Route path="/customer/*" element={<CustomerManagement />} />
          <Route path="/project/:projectId/*" element={<ProjectManagement />} />
          <Route path="/site/:siteId/*" element={<SiteManagement />} />
        </Routes>
      </ProjectListContextProvider>
    </>
  );
};

export default Engineering;
