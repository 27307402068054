import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  OutlinedInput,
  Stack,
  styled,
  ToggleButton,
} from "@mui/material";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import localized from "../../../../en.json";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "&.MuiToggleButtonGroup-root": {
    gap: "10px",
  },

  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    borderRadius: "18px !important",
    padding: "9px 18px",
    lineHeight: "10px !important",
    fontSize: "14px",
    color: "#1B1534",
    backgroundColor: "#EAEAEA",
    textTransform: "none",
    border: 0,
  },
  "& .Mui-selected": {
    background: "none",
    fontWeight: "700",
    border: "1px solid #8A00E5 !important",
    color: "#8A00E5",
  },
}));

interface PropTypes {
  setstartTime: Function;
  setEndTime: Function;
  isEventPage: boolean;
}

export const TimeFilter = (props: PropTypes) => {
  const [selectedBtn, setSelectedBtn] = useState<any>("day");
  let calcDate = new Date();
  const [currentDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(calcDate.setDate(currentDate.getDate() - 1))
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSaveCustomTimeRange = () => {
    setSelectedBtn("custom");
    handleClose();
    props.setstartTime(startDate);
    props.setEndTime(endDate);
  };
  const calcMaxDate = () => {
    let startDateCopy = new Date(startDate!.getTime());
    let maxDate = new Date(startDateCopy.setDate(startDate!.getDate() + 30));
    return maxDate.getTime() > new Date().getTime() ? new Date() : maxDate;
  };

  const filterPassedEndTime = (time: Date) => {
    return new Date().getTime() > new Date(time).getTime();
  };

  const filterPassedStartTime = (time: Date) => {
    return endDate!.getTime() > new Date(time).getTime();
  };
  const calcMinDate = () => {
    let ensDateCopy = new Date(endDate!.getTime());
    return new Date(ensDateCopy.setDate(endDate!.getDate() - 30));
  };
  const handleChange = (event: any, btnValue: string) => {
    if (btnValue && btnValue !== "custom") {
      props.setstartTime({ startRange: btnValue });
      setSelectedBtn(btnValue);
    }
    if (event.target.value === "day") {
      calcDate = new Date();
      props.setstartTime(new Date(calcDate.setDate(currentDate.getDate() - 1)));
      props.setEndTime(new Date());
    }
    if (event.target.value === "week") {
      calcDate = new Date();
      props.setstartTime(new Date(calcDate.setDate(currentDate.getDate() - 7)));
      props.setEndTime(new Date());
    }
    if (event.target.value === "month") {
      calcDate = new Date();
      props.setstartTime(
        new Date(calcDate.setDate(currentDate.getDate() - 30))
      );
      props.setEndTime(new Date());
    }
  };
  return (
    <>
      <StyledToggleButtonGroup
        color="primary"
        value={selectedBtn}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="day" data-testid="toggle-theme-btn" disableRipple>
          {localized["last_24_hours"]}{" "}
        </ToggleButton>
        <ToggleButton value="week" data-testid="toggle-theme-btn2" disableRipple>
          {localized["last_7_days"]}
        </ToggleButton>
        <ToggleButton value="month" data-testid="toggle-theme-btn3" disableRipple>
          {localized["last_30_days"]}
        </ToggleButton>
        <ToggleButton
          value="custom"
          disableRipple
          data-testid="toggle-theme-bt4"
          onClick={() => handleClickOpen()}
        >
          {selectedBtn === "custom"
            ? startDate?.toDateString() + " - " + endDate?.toDateString()
            : localized["custom_date"]}
        </ToggleButton>
      </StyledToggleButtonGroup>

      <BootstrapDialog
      sx={{marginLeft:props.isEventPage? "15% !important":"0% !important"}}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {localized["select_time_range"]}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction="row" gap="20px">
            <Box>
              <FormControl
                sx={{ margin: "10px 0" }}
                variant="outlined"
                focused={true}
              >
                <FormLabel>Start Time</FormLabel>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "email",
                  }}
                  sx={{
                    backgroundColor: "white",
                    border: "1px solid #ADA9C2",
                    borderRadius: "4px",
                  }}
                  size="small"
                  placeholder="Email"
                  name="email"
                  value={startDate}
                  required
                  notched={true}
                />
              </FormControl>
              <DatePicker
                showTimeSelect
                selected={startDate}
                minDate={calcMinDate()}
                maxDate={endDate}
                onChange={(date) => setStartDate(date)}
                filterTime={filterPassedStartTime}
                inline
              />
            </Box>

            <Box>
              <FormControl
                sx={{ margin: "10px 0" }}
                variant="outlined"
                focused={true}
              >
                <FormLabel color="primary">End Time</FormLabel>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "email",
                  }}
                  sx={{
                    backgroundColor: "white",
                    border: "1px solid #ADA9C2",
                    borderRadius: "4px",
                  }}
                  size="small"
                  placeholder="Email"
                  name="email"
                  value={endDate}
                  required
                  notched={true}
                />
              </FormControl>
              <DatePicker
                showTimeSelect
                selected={endDate}
                minDate={startDate}
                maxDate={calcMaxDate()}
                filterTime={filterPassedEndTime}
                onChange={(date) => setEndDate(date)}
                inline
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            autoFocus
            onClick={onSaveCustomTimeRange}
            variant="outlined"
            disabled={
              startDate && endDate
                ? startDate?.getTime() >= endDate?.getTime()
                : false
            }
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    height: "500px",
    maxWidth: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
