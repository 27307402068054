import { ApolloError, gql, useApolloClient } from "@apollo/client";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import axios from "axios";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useAuthToken } from "../../auth";
import localized from "../../en.json";
import { Software } from "../../Models/models";
import { SoftwareContext } from "../../store/software-list-context";
import { DeleteIcon } from "../../theme/Icons/IshIcons";
import { ErrorMessageDisplay } from "../../util/FileUploadUtil";
import { UploadUtil } from "../../util/UploadUtil";
import ShowSnackbar from "../CustomizedSnackbar/ShowSnackbar";
import { ConfigPendingSoftwareCard } from "./ConfigPendingSoftwareCard";

const CHUNK_SIZE = 9 * 1024 * 1024;
interface PropType {
  open: boolean;
  onClose: () => void;
}
export const GET_ALL_CONFIG_PENDING_SOFTWARES = gql`
  query {
    getAllConfigPendingSoftware {
      id
      name
      description
      version
      uploadedAt
      uploadedBy
      status
      s3Url
      fileSize
      isDeleted
      compatibleDeviceType
    }
  }
`;
export const UPDATE_SOFTWARE = gql`
  mutation ($id: ID!, $compatibleDeviceType: String) {
    updateSoftware(
      softwareDtoReq: { id: $id, compatibleDeviceType: $compatibleDeviceType }
    ) {
      id
    }
  }
`;
const SoftwareUploadDrawer = (props: PropType) => {
  const { onClose } = props;
  const [isOver, setIsOver] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const context = useContext(SoftwareContext);
  const { enqueueSnackbar } = useSnackbar();

  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [authToken] = useAuthToken();
  const client = useApolloClient();
  const [allConfigPendingSoftwares, setAllConfigPendingSoftwares] = useState<
    Software[]
  >([]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };
  const calculateFileSize = (file: any, setErrorMessage: Function) => {
    const BYTES_IN_KB = 1024;
    const MAX_FILE_SIZE_IN_KB = 300000;
    const fileSizeInKB = Math.round(file?.size / BYTES_IN_KB);
    const isFileSizeValid = fileSizeInKB <= MAX_FILE_SIZE_IN_KB;
    if (!isFileSizeValid) {
      setErrorMessage(localized["file-size-exceeds-validation"]);
    }
  };
  const checkFileExtension = (file: any, setErrorMessage: Function) => {
    const fileName = file?.name;
    const validExtensions = [".snap", ".app"];
    const hasValidExtension =
      fileName &&
      validExtensions.some((extension) => fileName.endsWith(extension));
    if (!hasValidExtension) {
      setErrorMessage(localized["invalid-file-format-validation"]);
    }
  };
  const handleFileUploadOnClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetValues();
    event.preventDefault();
    const dataFile = event.target.files?.[0];
    if (dataFile) {
      setUploadedFile(dataFile);
      checkFileExtension(dataFile, setErrorMessage);
      calculateFileSize(dataFile, setErrorMessage);
    }
  };
  const checkMultipleFiles = (files: any, setErrorMessage: Function) => {
    const isMultipleFiles = files.length > 1;
    if (isMultipleFiles) {
      setErrorMessage(localized["multiple-files-upload-not-supported"]);
    }
    return isMultipleFiles;
  };

  const handleFileUploadOnDrop = async (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    resetValues();
    event.preventDefault();
    setIsOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    const isMultiple = checkMultipleFiles(droppedFiles, setErrorMessage);
    if (!isMultiple) {
      calculateFileSize(droppedFiles[0], setErrorMessage);
      checkFileExtension(droppedFiles[0], setErrorMessage);
    }
    setUploadedFile(droppedFiles[0]);
  };

  const resetValues = () => {
    setUploadedFile(undefined);
    setErrorMessage("");
    setLoading(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!uploadedFile) return;
    const uuid = generateUUID();
    const totalChunks = Math.ceil(uploadedFile.size / CHUNK_SIZE);
    let start = 0;
    let uploadSuccessful = true;

    for (let i = 0; i < totalChunks; i++) {
      const end = Math.min(start + CHUNK_SIZE, uploadedFile.size);
      const chunk = uploadedFile.slice(start, end);
      const chunkNumber = i + 1;
      //const base64Chunk = await convertChunkToBase64(chunk);

      // Prepare FormData for each chunk
      const formData = new FormData();
      formData.append("fileNameWithExtension", uploadedFile.name);
      formData.append("file", chunk);
      formData.append("chunkNumber", chunkNumber.toString());
      formData.append("isLastChunk", (totalChunks === chunkNumber).toString());
      formData.append("chunkUUID", uuid);

      // Upload each chunk

      await axios
        .post(process.env.REACT_APP_UPLOAD_CHUNK_URL || "", formData, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .catch((error: any) => {
          uploadSuccessful = false;
          setLoading(false);
          setUploadedFile(undefined);
          ShowSnackbar(
            localized["failed-to-upload-software"] +
              " " +
              error?.response?.data?.message,
            false,
            enqueueSnackbar
          );
        });

      start = end;
    }

    if (uploadSuccessful) {
      setUploadedFile(undefined);
      setLoading(false);
      ShowSnackbar(
        localized["software-upload-successful"],
        true,
        enqueueSnackbar
      );
    }
  };
  useEffect(() => {
    getAllConfigPendingSoftwares();
    const interval = setInterval(() => {
      getAllConfigPendingSoftwares();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const getAllConfigPendingSoftwares = async () => {
    client
      .query({
        query: GET_ALL_CONFIG_PENDING_SOFTWARES,
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        const data = response.data?.getAllConfigPendingSoftware;
        setAllConfigPendingSoftwares(data);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-scanning-software"],
          false,
          enqueueSnackbar
        );
      });
  };
  const updateSoftware = (softwareId: number, compatibleDeviceType: string) => {
    client
      .mutate({
        mutation: UPDATE_SOFTWARE,
        variables: {
          id: softwareId,
          compatibleDeviceType: compatibleDeviceType,
        },
      })
      .then(() => {
        getAllConfigPendingSoftwares();
        context?.softwareListChangeHandler();
        ShowSnackbar(
          localized["saved-software-detail-success"],
          true,
          enqueueSnackbar
        );
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(error.message, false, enqueueSnackbar);
      });
  };
  return (
    <Box
      minWidth="440px"
      sx={{
        marginTop: "-40px",
        backgroundColor: "#fff",
        marginRight: "32px",
        height: "calc(100vh - 180px)",
        overflowY: "auto",
        scrollbarWidth: "thin",
      }}
    >
      <Box ml="24px">
        <Box sx={{ height: "calc(100vh - 340px)" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="16px"
            mb="16px"
          >
            <Typography variant="h6">{localized["upload-software"]}</Typography>
            <IconButton onClick={onClose} sx={{ marginRight: "24px" }}>
              <KeyboardDoubleArrowRightIcon style={{ color: "#8A00E5" }} />
            </IconButton>
          </Box>
          <Box
            display="flex"
            data-testid="software-upload-dropzone"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleFileUploadOnDrop}
            sx={{
              minHeight: !uploadedFile || errorMessage ? "200px" : "83px",
              backgroundColor: "rgba(185, 108, 255, 0.10)",
              borderRadius: "10px",
              border: "1px dashed #8A00E5",
              marginRight: "32px",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {!uploadedFile || errorMessage ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  data-testid="upload-software-btn"
                  component="label"
                  variant="contained"
                  sx={{
                    height: "30px",
                    backgroundColor: isOver
                      ? "lightgray !important"
                      : "white !important",
                    transition: "background-color 0.3s",
                    borderRadius: "4px",
                    border: "1px solid #C0C0C0",
                    background: "#FFF",
                    padding: "8px 24px 8px 24px",
                    "&:hover": {
                      background: "#FFF !important",
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#1B1534",
                      fontSize: "12px",
                      textTransform: "capitalize",
                    }}
                  >
                    {localized["select-file-button"]}
                  </Typography>
                  <input
                    id="upload-file"
                    data-testid="upload-software-input"
                    type="file"
                    hidden
                    accept=".snap,.app"
                    onChange={handleFileUploadOnClick}
                  />
                </Button>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#1B1534",
                    fontSize: "12px",
                    paddingTop: "16px",
                  }}
                >
                  {localized["supported-file-text-upload"]}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: "10px",
                    padding: 0,
                    fontSize: "12px",
                    color: "#1B1534",
                  }}
                >
                  {localized["maximum-file-size-text"]}
                  <strong>300MB</strong>
                </Typography>
                {errorMessage && (
                  <ErrorMessageDisplay
                    errorMsg={errorMessage}
                    customSx={{ padding: "0px !important" }}
                  />
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  gap: "16px",
                }}
              >
                <Box display="flex" sx={{ alignItems: "center", gap: "8px" }}>
                  <UploadUtil fileName={uploadedFile?.name} />
                  <IconButton onClick={resetValues}>
                    <DeleteIcon sx={{ path: { fill: "#8A00E5" } }} />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>

          <Typography
            variant="body1"
            sx={{
              color: "#1B1534",
              fontSize: "12px",
            }}
          >
            Please ensure you have a <strong>Strong</strong> internet
            connection.
          </Typography>
          {allConfigPendingSoftwares.map((software: Software) => (
            <ConfigPendingSoftwareCard
              key={software.id}
              softwareDetails={software}
              updateSoftware={updateSoftware}
            />
          ))}
          {loading && (
            <Stack
              position={"fixed"}
              right={240}
              alignItems={"center"}
              sx={{
                opacity: "1 !important",
                zIndex: "1000",
              }}
            >
              <CircularProgress />
            </Stack>
          )}
        </Box>

        <Button
          variant="contained"
          sx={{
            mt: "15%",
            mb: "26px",
            width: "95%",
            height: "40px",
            borderRadius: "24px",
          }}
          onClick={handleUpload}
          disabled={
            !uploadedFile || allConfigPendingSoftwares.length > 0 || loading
          }
        >
          <Typography variant="h5" textTransform="none">
            {localized["upload-btn"]}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
function generateUUID(): string {
  // Create an array of 16 random bytes
  const array = new Uint8Array(16);
  crypto.getRandomValues(array);

  // Adjust the 7th and 9th byte to conform to the UUID v4 specification
  array[6] = (array[6] & 0x0f) | 0x40;
  array[8] = (array[8] & 0x3f) | 0x80;

  // Convert bytes to hex and format as UUID
  const uuid = Array.from(array)
    .map((byte, i) => {
      const hex = byte.toString(16).padStart(2, "0");
      return [4, 6, 8, 10].includes(i) ? `-${hex}` : hex;
    })
    .join("");

  return uuid;
}
const convertChunkToBase64 = (chunk: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const byteArray = new Uint8Array(arrayBuffer);
      const binaryString = byteArray.reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      );

      // Use the safe base64 encoding
      const base64String = window.btoa(binaryString);
      const base64SizeInBytes = base64String.length;

      console.log(
        `Base64 Chunk Size: ${base64SizeInBytes} characters (equivalent to ${Math.ceil(
          (base64SizeInBytes * 3) / 4
        )} bytes)`
      );
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(chunk); // Read the chunk as binary data
  });
};

export default SoftwareUploadDrawer;
