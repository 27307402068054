import {
  ApolloError,
  ApolloQueryResult,
  gql,
  useApolloClient,
} from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  CreateSite,
  Site,
  getAllSitesByProjectIdResponse,
} from "../../../../Models/models";
import localized from "../../../../en.json";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { CreateSiteDialog } from "./CreateSite/CreateSiteDialog";
import { SiteList } from "./SiteList/SiteList";

type Props = {
  projectId: string | undefined;
  setSiteUpdated:Function;
};
export const CREATE_SITE = gql`
  mutation (
    $name: String!
    $description: String
    $latitude: Float
    $longitude: Float
    $altitude: Float
    $projectID: Int!
  ) {
    createSite(
      siteDtoReq: {
        name: $name
        description: $description
        latitude: $latitude
        longitude: $longitude
        altitude: $altitude
        projectID: $projectID
      }
    ) {
      id
    }
  }
`;
export const GET_ALL_SITES_FOR_PROJECT = gql`
  query ($projectID: Int!) {
    getAllSitesByProjectId(projectID: $projectID) {
      id
      name
      numberOfAssets
      numberOfDevices
    }
  }
`;
export const SitesDetails = (props: Props) => {
  const [isOpenCreateSiteDialog, setIsOpenCreateSiteDialog] = useState(false);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [siteList, setSiteList] = useState<Site[]>([]);
  const onCreateSiteClickHandler = () => {
    setIsOpenCreateSiteDialog(true);
  };
  const createSiteHandler = (createSiteFormData: CreateSite) => {
    client
      .mutate({
        mutation: CREATE_SITE,
        variables: {
          name: createSiteFormData.name.trim(),
          description: createSiteFormData.description,
          latitude: createSiteFormData.latitude,
          longitude: createSiteFormData.longitude,
          altitude: createSiteFormData.altitude,
          projectID: props.projectId,
        },
      })
      .then(() => {
        props.setSiteUpdated(true);
        ShowSnackbar(
          localized["site-creation-success-msg"],
          true,
          enqueueSnackbar
        );
        getAllSites();
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["site-creation-failed-msg"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };
  const getAllSites = () => {
    client
      .query({
        query: GET_ALL_SITES_FOR_PROJECT,
        fetchPolicy: "no-cache",
        variables: {
          projectID: props.projectId,
        },
      })
      .then((response: ApolloQueryResult<getAllSitesByProjectIdResponse>) => {
        setSiteList(response.data.getAllSitesByProjectId);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-sites"],
          false,
          enqueueSnackbar
        );
      });
  };
  useEffect(() => {
    getAllSites();
  }, []);
  return (
    <Stack gap="32px" padding="32px" height={"100%"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "32px",
        }}
      >
        <Typography
          sx={{
            lineHeight: "26px",
            color: "#0D0D0D",
            fontSize: "22px",
            fontWeight: "400",
          }}
        >
          {localized["sites"]}
        </Typography>
        <Button
          disableRipple
          variant="contained"
          sx={{
            borderRadius: "24px",
            height: "44px",
            alignItems: "center",
          }}
          startIcon={<AddIcon sx={{ width: "24px", height: "24px" }} />}
          onClick={onCreateSiteClickHandler}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "none",
            }}
          >
            {localized["new-site-button"]}
          </Typography>
        </Button>
        <CreateSiteDialog
          isDialogOpen={isOpenCreateSiteDialog}
          setIsDialogOpen={setIsOpenCreateSiteDialog}
          createSiteHandler={createSiteHandler}
        />
      </Box>
      <SiteList siteList={siteList} />
    </Stack>
  );
};
