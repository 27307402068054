import { createTheme } from "@mui/material";
import SiemensSans from "./fonts/SiemensSans_Global_Roman.ttf";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#8A00E5",
    },
    secondary: {
      main: "#1B1534",
    },
    success: {
      main: "#347841",
    },
    warning: {
      main: "#E2D000",
    },
    error: {
      main: "#DA1E28",
    },
    text: {
      primary: "#1B1534",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1500,
      xl: 2500,
    },
  },
  typography: {
    fontFamily: "SiemensSans, Arial",
    h1: {
      fontSize: "48px",
      lineHeight: "58px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "36px",
      lineHeight: "43px",
      fontWeight: 700,
    },
    h3: {
      fontWeight: "700",
      fontSize: "36px",
      lineHeight: "20px",
    },
    h4: {
      fontWeight: "700",
      fontSize: "25px",
      lineHeight: "30px",
    },
    h5: {
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "24px",
    },
    h6: {
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "24px",
    },
    subtitle1: {
      fontSize: "36px",
      lineHeight: "43px",
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: "36px",
      lineHeight: "43px",
      fontWeight: 700,
    },
    body1: {
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "22px",
    },
    body2: {
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "20px",
    },
    overline: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
    },
    caption: {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
              @font-face {
                font-family: 'SiemensSans';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: url(${SiemensSans}) format('truetype');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
              }
            `,
    },
  },
});
