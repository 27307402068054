import { ApolloError, gql, useApolloClient } from "@apollo/client";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Box, Stack } from "@mui/material";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROLES } from "../../Models/models";
import { useAuthToken } from "../../auth";
import { loginRequest } from "../../authConfig";
import { BreadCrumbContext } from "../../store/breadcrumb-context";
import { UnAuthorisedDialog } from "../AlertDialogs/UnAuthorisedDialog";
import ApplicationBar from "../ApplicationBar/ApplicationBar";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import { AssetMonitoring } from "../Dashboard/AssetMonitoring/AssetMonitoring";
import Dashboard from "../Dashboard/Dashboard";
import Engineering from "../Engineering/Engineering";
import { MuiNavbar } from "../MuiNavbar/MuiNavbar";
import Notifications from "../Notifications/Notifications";
import UserManagement from "../Usermanagement/UserManagement";
import SoftwareManagement from "./../SoftwareManagement/SoftwareManagement";
import DataPrivacyDialog from "./DataPrivacyDialog";
import { DeviceDetails } from "../Dashboard/DeviceDetails/DeviceDetails";

export const LOGIN = gql`
  query login($emailId: String!) {
    login(emailId: $emailId) {
      role {
        roleName
      }
      isDataPrivacyAccepted
    }
  }
`;
export type RoleContextType = {
  currRole: ROLES | null;
};

export const RoleContext = createContext<RoleContextType | null>(null);
export const Home = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [, , removeAuthToken] = useAuthToken();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const [, setAuthToken] = useAuthToken();
  const [role, setRole] = useState<ROLES | null>(null);
  const client = useApolloClient();
  const [openAuthErrorDialog, setOpenAuthErrorDialog] = useState(false);
  const [openDataPrivacyDialog, setOpenDataPrivacyDialog] = useState(false);
  const breadCrumbContext = useContext(BreadCrumbContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleLogout = async () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
    await apolloClient.clearStore(); // we remove all information in the store
    removeAuthToken();
  };
  const handleDialogClose = async () => {
    setOpenAuthErrorDialog(false);
    await handleLogout();
  };
  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length === 0) {
      navigate("/login");
    }
  }, [inProgress, accounts.length, navigate]);

  useEffect(() => {
    const accessTokenRequest = {
      ...loginRequest,
      account: accounts[0],
    };
    async function fetchToken() {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          setAuthToken(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          handleErrorInTokenAcquire(error, accessTokenRequest);
        });
    }

    fetchToken();
  });
  const handleErrorInTokenAcquire = (error: any, accessTokenRequest: any) => {
    if (error instanceof InteractionRequiredAuthError) {
      instance
        .acquireTokenRedirect(accessTokenRequest)
        .then(function (accessTokenResponse: any) {
          setAuthToken(accessTokenResponse.accessToken);
        })
        .catch(function (accessTokenError) {
          // Acquire token interactive failure
        });
    }
  };
  useEffect(() => {
    const fetchUser = () => {
      if (isAuthenticated) {
        client
          .query({
            query: LOGIN,
            variables: {
              emailId: accounts[0]?.username,
            },
            fetchPolicy: "no-cache",
          })
          .then((response: any) => {
            setRole(response.data.login.role.roleName);
            if (!response.data.login.isDataPrivacyAccepted) {
              setOpenDataPrivacyDialog(true);
            }
          })
          .catch((err: ApolloError) => {
            if (err.message.includes("Received status code 404")) {
              setOpenAuthErrorDialog(true);
            }
          });
      }
    };
    fetchUser();
  }, [isAuthenticated, accounts, client]);
  return (
    <RoleContext.Provider value={useMemo(() => ({ currRole: role }), [role])}>
      <AuthenticatedTemplate>
        <ApplicationBar />
        <Stack
          direction="row"
          data-testid="stackHome"
          height="100%"
          width="100%"
          marginTop="80px"
        >
          <MuiNavbar setIsDrawerOpen={setIsDrawerOpen} />
          <Stack
            sx={{
              width: isDrawerOpen ? "100vw" : "calc(100vw - 56px)",
              marginLeft: isDrawerOpen ? "56px" : "0px",
              bgcolor: "#F2F2F2",
            }}
          >
            <Stack>
              <Box
                sx={{ padding: "12px 32px", borderBottom: "1px solid #EAEAEA" }}
              >
                <Breadcrumb
                  breadCrumbsArray={breadCrumbContext?.breadcrumbArray}
                />
              </Box>
            </Stack>
            <Routes>
              <Route path="/engineering/*" element={<Engineering />} />
              <Route path="/user-management/*" element={<UserManagement />} />
              <Route path="/notifications/*" element={<Notifications />} />
              <Route
                path="/software-management/*"
                element={<SoftwareManagement />}
              />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route
                path="/device-detail-view/:deviceId"
                element={<Dashboard />}
              />
              <Route
                path="/asset-monitoring/:assetId/*"
                element={<AssetMonitoring />}
              />
              <Route
                path="/device-details/:deviceId/*"
                element={<DeviceDetails />}
              />
              <Route path="/" element={<Navigate to="/dashboard/" replace />} />
              <Route path="*" element={<Navigate to="/dashboard/" replace />} />
            </Routes>
          </Stack>
        </Stack>
      </AuthenticatedTemplate>
      <UnAuthorisedDialog
        openAuthErrorDialog={openAuthErrorDialog}
        handleDialogClose={handleDialogClose}
      />
      <DataPrivacyDialog
        email={accounts[0]?.username}
        openDataPrivacyDialog={openDataPrivacyDialog}
        handleDataPrivacyDialogClose={handleLogout}
      />
    </RoleContext.Provider>
  );
};
