import AddIcon from '@mui/icons-material/Add';
import {Box, Button, Stack, Typography} from "@mui/material";
import localized from '../../../../en.json';
import AssetsTable from "./AssetsTable/AssetsTable";
import {useNavigate, useParams} from 'react-router-dom';

export const EnergyAssets = () => {
    const navigate = useNavigate();
    let {siteId} = useParams();

    const createNewAsset = () => {
        navigate(`/engineering/site/${siteId}/energyassets/createAsset`);
    }
    return (
        <Stack sx={{padding: "24px", gap: "24px"}}>
            <Box display="flex" gap="32px" alignItems="center">
                <Typography variant='h4' sx={{fontSize: "22px", color: "#0D0D0D"}}>
                    {localized['assets']}
                </Typography>
                <Button
                    onClick={createNewAsset}
                    variant="contained"
                    startIcon={<AddIcon sx={{width: "24px", height: "24px"}}/>}
                    sx={{textTransform: "none", borderRadius: "24px"}}
                >
                    <Typography variant='h5'>{localized['new-asset']}</Typography>
                </Button>
            </Box>
            <AssetsTable/>
        </Stack>
    );
};
