import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import localized from "../../../../en.json";
import { CreateSite, SiteDetail } from "../../../../Models/models";
import { ConfirmationDialog } from "../../../../util/ConfirmationDialog";
import { NameAndDescriptionCard } from "../../../../util/NameAndDescriptionCard";
import ShowSnackbar from "../../../CustomizedSnackbar/ShowSnackbar";
import { CreateSiteDialog } from "../../ProjectManagement/SitesDetails/CreateSite/CreateSiteDialog";
type PropsType = {
  siteDetails: SiteDetail | undefined;
  siteEdit: Function;
};
export const DELETE_SITE = gql`
  mutation ($siteID: Int!) {
    deleteSite(siteID: $siteID)
  }
`;
export const EDIT_SITE = gql`
  mutation (
    $name: String!
    $description: String
    $latitude: Float
    $longitude: Float
    $altitude: Float
    $projectID: Int!
    $id: Int!
  ) {
    updateSite(
      siteDtoReq: {
        name: $name
        description: $description
        latitude: $latitude
        longitude: $longitude
        altitude: $altitude
        projectID: $projectID
        id: $id
      }
    ) {
      id
    }
  }
`;

export const SiteDetails = (props: PropsType) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [
    isDeleteSiteConfirmationDialogOpen,
    setIsDeleteSiteConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [isOpenEditSiteDialog, setIsOpenEditSiteDialog] = useState(false);

  const handleCloseDeleteDialog = () => {
    setIsWarning(false);
    setIsDeleteSiteConfirmationDialogOpen(false);
  };
  const onDeleteSiteClickHandler = () => {
    setIsDeleteSiteConfirmationDialogOpen(true);
    if (
      props.siteDetails!.devices.length > 0 ||
      props.siteDetails!.assets.length > 0
    ) {
      setIsWarning(true);
      setTitle(localized["cannot-delete-site"]);
      setDescription(localized["delete-site-warning"]);
    } else {
      setTitle(localized["delete-site"]);
      setDescription(localized["site-delete-confirmation"]);
    }
  };
  const onDeleteSiteConfirmHandler = () => {
    client
      .mutate({
        mutation: DELETE_SITE,
        variables: {
          siteID: props.siteDetails?.id,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        setIsDeleteSiteConfirmationDialogOpen(false);
        ShowSnackbar(localized["delete-site-success"], true, enqueueSnackbar);
        navigate(`/engineering/project/${props.siteDetails?.project.id}/sites`);
      })
      .catch(() => {
        setIsDeleteSiteConfirmationDialogOpen(false);
        ShowSnackbar(localized["delete-site-failed"], false, enqueueSnackbar);
      });
  };
  const onEditSiteClickHandler = () => {
    setIsOpenEditSiteDialog(true);
  };
  const editSiteHandler = (editSiteFormData: CreateSite) => {
    client
      .mutate({
        mutation: EDIT_SITE,
        variables: {
          name: editSiteFormData.name.trim(),
          description: editSiteFormData.description,
          latitude: editSiteFormData.latitude,
          longitude: editSiteFormData.longitude,
          altitude: editSiteFormData.altitude,
          projectID: props.siteDetails?.project.id,
          id: props.siteDetails?.id,
        },
      })
      .then(() => {
        ShowSnackbar(localized["site-edit-success-msg"], true, enqueueSnackbar);
        props.siteEdit(true);
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["site-edit-failed-msg"] + " : " + error.message,
          false,
          enqueueSnackbar
        );
      });
  };
  return (
    <>
      <NameAndDescriptionCard
        name={props.siteDetails?.name}
        description={props.siteDetails?.description}
        createdOn={props.siteDetails?.createdAt}
        onDeleteClickHandler={onDeleteSiteClickHandler}
        onEditClickHandler={onEditSiteClickHandler}
      />
      {isDeleteSiteConfirmationDialogOpen && (
        <ConfirmationDialog
          isDialogOpen={isDeleteSiteConfirmationDialogOpen}
          handleCloseDialog={handleCloseDeleteDialog}
          title={title}
          description={description}
          leftButtonText={localized["cancel-btn"]}
          rightButtonText={localized["yes-delete"]}
          leftButtonClickHandler={handleCloseDeleteDialog}
          rightButtonClickHandler={onDeleteSiteConfirmHandler}
          isWarning={isWarning}
        />
      )}
      <CreateSiteDialog
        isDialogOpen={isOpenEditSiteDialog}
        setIsDialogOpen={setIsOpenEditSiteDialog}
        editSiteHandler={editSiteHandler}
        defaultValues={{
          name: props.siteDetails?.name,
          description: props.siteDetails?.description,
          latitude: getValidValue(props.siteDetails?.latitude),
          longitude: getValidValue(props.siteDetails?.longitude),
          altitude: getValidValue(props.siteDetails?.altitude),
          weatherApi: "metblue",
        }}  
      />
    </>
  );
};
const getValidValue = (value: any) => (value == null ? undefined : value);
