import { Box, Typography } from "@mui/material";
import localized from "../../../en.json";
import { Software } from "../../../Models/models";
interface PropTypes {
  filteredStoredsoftwares:Software[] |undefined
}
const UploadedSoftwareCount = (props:PropTypes) => {
  return (
    <Box
      display="grid"
      sx={{
        textAlign: "left",
        marginBottom: "12px",
        paddingLeft: "32px"
      }}
    >
      <Typography variant="h6" color="#1B1534">
        {localized["uploaded-software"]} ({props.filteredStoredsoftwares?.length})
      </Typography>
    </Box>
  );
};

export default UploadedSoftwareCount;
