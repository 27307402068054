import { ROLES } from "./Models/models";

const permissions = {
  "user.create": ["ADMIN"],
  "user.read": ["ADMIN"],
  "user.edit": ["ADMIN"],
  "user.delete": ["ADMIN"],
  "project.create": ["ADMIN", "NONADMIN"],
  "project.view": ["ADMIN", "NONADMIN"],
  "project.delete": ["ADMIN", "NONADMIN"],
  "signals.update": ["ADMIN", "NONADMIN"],
  "signals.view": ["ADMIN", "NONADMIN"],
  "device.register": ["ADMIN", "NONADMIN"],
  "device.view": ["ADMIN", "NONADMIN"],
  "device.monitor": ["ADMIN", "NONADMIN"],
  "data.visualise": ["ADMIN", "NONADMIN"],
  "device.configure": ["ADMIN", "NONADMIN"],
  "device.onboard": ["ADMIN"],
  "software.upload": ["ADMIN"],
  "software.install": ["ADMIN"],
};

export type PermissionNames =
  | "user.create"
  | "user.read"
  | "user.edit"
  | "user.delete"
  | "project.create"
  | "project.view"
  | "project.delete"
  | "signals.update"
  | "signals.view"
  | "device.register"
  | "device.view"
  | "device.monitor"
  | "data.visualise"
  | "device.configure"
  | "device.onboard"
  | "software.upload"
  | "software.install";

export const hasPermission = (
  permissionName: PermissionNames,
  role: ROLES | undefined | null
) => {
  if (role) {
    return permissions[permissionName].includes(role);
  }
};
