import { Box, SxProps, Typography } from "@mui/material";
import { ErrorIcon } from "../theme/Icons/IshIcons";

interface ErrorMessageDisplayProps {
  errorMsg: string;
  customSx?: SxProps; 
}
export const ErrorMessageDisplay = ({ errorMsg, customSx }: ErrorMessageDisplayProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "0px 24px 14px 24px",
        alignItems: "center",
        justifyContent: "center",
        ...customSx, 
      }}
    >
      <ErrorIcon
        sx={{
          paddingTop: "7px",
          width: "25px",
          height: "25px",
        }}
      />
      <Typography
        variant="body1"
        sx={{
          color: "#DA1E28",
          fontSize: "12px",
        }}
      >
        {errorMsg}
      </Typography>
    </Box>
  );
};

export const commonMethodExcelDataParse = (details: { [key: string]: any }) => {
  const excelDetails: { [key: string]: any } = {};
  details.forEach((row: any) => {
    if (row.length >= 2) {
      const key = row[0];
      const value = row[1];
      excelDetails[key] = value;
    }
  });
  return excelDetails;
}
export const objectData = (data: { [key: string]: any }) => {
  return Object.keys(data);
}

export const compareFunc = (a: string[], b: string[]) => a.length === b.length && a.every((element, index) => element === b[index]);
