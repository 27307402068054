import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

type PropsType = {
  isDialogOpen: boolean;
  handleCloseDialog: Function;
  title: string;
  description: string;
  leftButtonText: string;
  rightButtonText: string;
  leftButtonClickHandler: Function;
  rightButtonClickHandler: Function;
  isWarning?: boolean;
};
export const ConfirmationDialog = (props: PropsType) => {
  return (
    <Dialog
      onClose={() => props.handleCloseDialog(false)}
      aria-labelledby="customized-dialog-title"
      open={props.isDialogOpen}
      fullWidth
      maxWidth="md"
      sx={{ border: "1px solid #EAEAEA", borderRadius: "8px" }}
    >
      <Box display="flex" sx={{ p: "16px", pl: "8px" }}>
        <DialogTitle
          sx={{ m: 0, fontWeight: "400", fontSize: "28px", lineHeight: "36px" }}
          id="customized-dialog-title"
        >
          <Typography fontSize={"28px"} lineHeight={"36px"} fontWeight={400}>
            {props.title}
          </Typography>
        </DialogTitle>
        <IconButton
          data-testid="cancelButton"
          aria-label="close"
          onClick={() => props.handleCloseDialog(false)}
          sx={{
            position: "absolute",
            right: 32,
            top: 32,
            color: "#0D0D0D",
            width: "32px",
            height: "32px",
          }}
        >
          <CloseIcon
            style={{
              width: "32px",
              height: "32px",
            }}
          />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: "32px" }}>
        <Typography variant="body1" lineHeight={"26px"}>
          {props.description}
        </Typography>
      </DialogContent>
      {!props.isWarning && (
        <DialogActions sx={{ alignItems: "center", padding: "32px" }}>
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "24px",
              width: "100%",
            }}
            onClick={() => props.leftButtonClickHandler(false)}
          >
            <Typography variant="h5">{props.leftButtonText}</Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "24px",
              width: "100%",
            }}
            onClick={() => {
              props.rightButtonClickHandler();
            }}
          >
            <Typography variant="h5">{props.rightButtonText}</Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
