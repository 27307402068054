import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import localized from "../en.json";
import { ArrowForward } from "../theme/Icons/IshIcons";
import { truncateText } from "./ConstantUtils";

interface PropTypes {
  cardDetails: any;
  onClickHandler: Function;
  backgroundColor?: string;
}
const CardDetailUtil = (props: PropTypes) => {
  const { cardDetails } = props;
  return (
    <Box
      sx={{
        height: "88px !important",
        width: "280px",
        margin: "0px 24px 24px 0px",
      }}
    >
      <Button
        disableRipple
        sx={{
          height: "100%",
          padding: "0px !important",
          width: "100%",
          textTransform: "none",
          "&:hover": {
            background: "none",
          },
        }}
      >
        <Card
          sx={{
            height: "88px",
            background: props.backgroundColor,
            width: "280px",
            minWidth: "280px !important",
            borderRadius: "8px",
            boxShadow: "none !important",
            border: "1px solid #DEDEDE",
            ".MuiCardContent-root:last-child": { paddingBottom: "16px" },
          }}
          onClick={() => {
            props.onClickHandler(cardDetails.id);
          }}
        >
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "8px",
              }}
            >
              <Typography variant="body2" lineHeight="28px">
                {truncateText(cardDetails.name)}
              </Typography>
              <ArrowForward
                style={{
                  width: "9.49px",
                  height: "15.23px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                color: "#393939",
              }}
            >
              {cardDetails.numberOfAssets} {localized["energy-assets"]}
              <span style={{ marginRight: "16px" }} />{" "}
              {cardDetails.numberOfDevices} {localized["edge-devices"]}
            </Box>
          </CardContent>
        </Card>
      </Button>
    </Box>
  );
};

export default CardDetailUtil;
