import { gql, useApolloClient } from "@apollo/client";
import { Box, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useLocation } from "react-router-dom";
import { Asset } from "../../Models/models";
import localized from "../../en.json";
import { BreadCrumbContext } from "../../store/breadcrumb-context";
import { TabPanel } from "../../util/TabPanelProps";
import ShowSnackbar from "../CustomizedSnackbar/ShowSnackbar";
import Map from "../Dashboard/Map/Map";
import AssetTableView from "./AssetTableView/AssetTableView";
import EnergyAssetsFilter from "./EnergyAssetsFilter";
import EnergyAssetsSummary from "./EnergyAssetsSummary";
import MapTableTabs from "./MapTableTabs";

export const GET_ALL_ASSETS = gql`
  query {
    getAllAssets {
      id
      name
      type
      createdAt
      updatedAt
      createdBy
      capacity
      capacityUnit
      latitude
      longitude
      status
      alarm
      warning
      projectID
      site {
        id
        name
      }
      devicesInAsset {
        id
        name
      }
    }
  }
`;

const Dashboard = () => {
  const [selectedProject, setSelectedProject] = useState("Fleet View");
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const breadCrumbContext = useContext(BreadCrumbContext);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [filteredAssets, setFilteredAssets] = useState<Asset[]>([]);
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
  Geocode.setLanguage("en");
  useEffect(() => {
    breadCrumbContext?.setBreadCrumbsArray([
      {
        name: "Monitor",
        link: "/dashboard/",
      },
    ]);
  }, [breadCrumbContext?.setBreadCrumbsArray, location, tabValue]);

  const getAllAssets = () => {
    client
      .query({
        query: GET_ALL_ASSETS,
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        setAssets(response.data.getAllAssets);
        setFilteredAssets(response.data.getAllAssets)
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-assets"],
          false,
          enqueueSnackbar
        );
      });
  };

  const handleSearch = (query:string) => {
    if (query) {
      const searchQuery = query.toLowerCase();
      const filteredAsserts = assets?.filter((item) =>
        item.name?.toLowerCase().includes(searchQuery) ||
        item.location?.toLowerCase().includes(searchQuery) ||
        item.type?.toLowerCase().includes(searchQuery)
      );
      setFilteredAssets(filteredAsserts);
    } else {
      setFilteredAssets(assets);
    }
  };

  function updateAssetWithLocationName(updateAssets: Asset[]) {
    updateAssets.forEach(async (m: Asset) => {
      const response = await Geocode.fromLatLng(m.latitude, m.longitude);
      const cityName = response["plus_code"]["compound_code"];
      m.location = cityName.substring(cityName.indexOf(" ") + 1).split(",")[0];
    });
  }
  useEffect(() => {
    getAllAssets();
  }, []);
  useEffect(() => {
    (async () => {
      if (assets?.length > 0) {
        let updateAssets = [...assets];
        (async () => {
          updateAssetWithLocationName(updateAssets);
        })();
        setAssets([...updateAssets]);
        setFilteredAssets([...updateAssets]);
      }
    })();
  }, [assets.length]);
  return (
    <Stack sx={{ padding: "24px 32px", gap: "24px" }}>
      <EnergyAssetsFilter
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        onSearch={handleSearch}
      />
      <Stack
        sx={{
          gap: "24px",
          padding: "24px",
          background: "#FFFFFF",
          border: "1px solid #EAEAEA",
          borderRadius: "8px",
          height: "calc(100vh - 303px)",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <EnergyAssetsSummary assets={filteredAssets} />
          <MapTableTabs tabValue={tabValue} setTabValue={setTabValue} />
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Map assets={filteredAssets} onMarkerClickHandler={() => { }} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <AssetTableView assets={filteredAssets} />
        </TabPanel>
      </Stack>
    </Stack>
  );
};

export default Dashboard;
