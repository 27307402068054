import moment from "moment-timezone";

export function convertDateTime(utcDateString: string | undefined): string {
  if (utcDateString) {
    const localDate = moment.utc(utcDateString).tz(moment.tz.guess());
    const timeZoneAbbr = localDate.format("z");

    return localDate.format("YYYY-MM-DD hh:mm A") + " " + timeZoneAbbr;
  }
  return "NA";
}

export function resetMilliseconds(date:Date) {
  const newDate = new Date(date);
  newDate.setMilliseconds(0);
  return newDate;
}

export const convertToUTC = (date: Date): string => {
  Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return date.toISOString().replace("T", " ").slice(0, -5);
};
