import { Card, CardContent } from "@mui/material";

const Sld = () => {
  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: "none",
      }}
    >
      <CardContent sx={{ padding: "0px !important", height: "100%" }}>
        <iframe
          src={process.env.REACT_APP_IGNITION_SCADA_URL}
          height="100%"
          width={"100%"}
        ></iframe>
      </CardContent>
    </Card>
  );
};

export default Sld;
