const ShowSnackbar = (message: any, isSuccess: boolean, enqueueSnackbar: Function) => {
    return enqueueSnackbar(message, {
        variant: isSuccess ? "success" : "error",
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }
    });

};
export default ShowSnackbar;