import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import {
  AppBar,
  Avatar,
  IconButton,
  Popover,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import localized from "../../en.json";
import { SE_ICON } from "../../theme/Icons/SE_Logo";
import { Logout } from "../Logout/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export function setUserInitial(
  accounts: AccountInfo[],
  setUserInitials: (value: ((prevState: string) => string) | string) => void
) {
  if (accounts) {
    const lastName = accounts[0]?.name?.split(",")[0]?.trim().charAt(0);
    const firstName = accounts[0]?.name?.split(",")[1]?.trim().charAt(0);
    if (firstName && lastName) {
      setUserInitials(firstName + lastName);
    }
  }
}

const ApplicationBar = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [userInitials, setUserInitials] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileOpen(!profileOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [profileOpen, setProfileOpen] = useState(false);
  const handleProfileClose = (event: Event | React.SyntheticEvent) => {
    setProfileOpen(false);
  };
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    setUserInitial(accounts, setUserInitials);
  }, [accounts]);

  return (
    <AppBar
      position="fixed"
      sx={{ background: "#FFFFFF", boxShadow: "unset", padding: "24px 32px", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          p: "0px !important",
          minHeight: "0px !important",
        }}
      >
        <Stack flexDirection="row" sx={{ gap: "32px", alignItems: "center" }}>
          <IconButton
            data-testid="logo-icon"
            aria-label="logo"
            sx={{ p: 0 }}
            onClick={() => navigate("/")}>
            <SE_ICON
              sx={{
                width: "80px",
                height: "28px",
              }}
            />
          </IconButton>
          <Typography variant="h4" sx={{ color: "#0D0D0D", fontSize: "22px" }}>
            {localized["app-name"]}
          </Typography>
        </Stack>

        <Stack flexDirection="row">
          <IconButton
          data-testid="user-profile-button"
          disableRipple sx={{ p: 0 }} onClick={handleClick}>
            <Avatar
              sx={{
                bgcolor: "#EAEAEA",
                color: "#0D0D0D",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                width: "32px",
                height: "32px",
              }}
            >
              {userInitials}
            </Avatar>

            {profileOpen === false ? (
              <KeyboardArrowDownIcon
                sx={{ color: "#1B1534", cursor: "pointer" }}
              />
            ) : (
              <KeyboardArrowUpIcon
                sx={{ color: "#1B1534", cursor: "pointer" }}
              />
            )}
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "1%",
            }}
          >
            <Logout handleProfileClose={handleProfileClose} />
          </Popover>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
export default ApplicationBar;
