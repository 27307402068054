import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetDevicesBySiteId } from "../../../../../../Models/models";
import localized from "../../../../../../en.json";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../../../util/SearchStyleUtil";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";
import { LightTooltip } from "../../../../CustomerManagement/AssignEdgeDevice/AssignEdgeDeviceDialog";

export const GET_DEVICES_BY_SITE_ID = gql`
  query ($siteID: Int!) {
    getDevicesBySiteID(siteID: $siteID) {
      id
      name
      deviceStatus
      numberOfAssetsInDevice
    }
  }
`;

interface Device {
  id: string;
  name: string;
  deviceStatus: string;
  numberOfAssetsInDevice: number;
}

interface PropTypes {
  siteId: number;
  isDeviceConfigUpdated: boolean;
}
const EdgeDevicesList = (props: PropTypes) => {
  const { siteId, isDeviceConfigUpdated } = props;
  let deviceId = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [devices, setDevices] = useState<Device[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
    const selectedDevices = devices.filter((device: Device) =>
      device.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDevices(selectedDevices);
  };
  const onClickDevice = (event: any, id: string) => {
    setSelectedDeviceId(id);
    const selectedDeviceObj = devices.find(
      (device: Device) => device.id.toLowerCase() === id.toLowerCase()
    );
    navigate(`/engineering/site/${siteId}/edgedevices/device/${id}/tab1`, {
      state: selectedDeviceObj,
    });
  };
  useEffect(() => {
    const getDevicesBySiteId = async () => {
      setLoading(true);
      client
        .query({
          query: GET_DEVICES_BY_SITE_ID,
          variables: {
            siteID: siteId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response: ApolloQueryResult<GetDevicesBySiteId>) => {
          const getDevicesBySiteId = response.data.getDevicesBySiteID;
          setDevices(getDevicesBySiteId);
          setFilteredDevices(getDevicesBySiteId);
        })
        .catch(() => {
          ShowSnackbar(
            localized["failed-to-fetch-devices"],
            false,
            enqueueSnackbar
          );
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getDevicesBySiteId();

    const device_id = (deviceId["*"] || "").split("/")[1];
    setSelectedDeviceId(device_id);
  }, [isDeviceConfigUpdated, location]);
  return (
    <>
      <Stack
        sx={{
          border: "1px solid #DEDEDE",
          borderRadius: "8px",
          width: "280px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: "#0D0D0D", fontSize: "22px", margin: "16px" }}
        >
          {localized["edge-devices"]}
        </Typography>
        <Search
          sx={{
            display: "flex",
            height: "44px",
            width: "240px !important",
            marginLeft: "24px !important",
          }}
        >
          <SearchIconWrapper>
            <SearchIcon
              sx={{ width: "24px", height: "24px", color: "#5D596E" }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            sx={{ minWidth: "240px", borderRadius: "0px", border: "none" }}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Search>
        <Divider sx={{ border: "1px solid #DEDEDE" }} />
        <Box
          display="flex"
          sx={{
            justifyContent: "space-between",
            margin: "8px",
            marginLeft: "24px",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "400" }}>
            Device Name
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "400" }}>
            Status
          </Typography>
        </Box>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            alignSelf="center"
            height="500px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <ButtonGroup
            orientation="vertical"
            aria-label="Vertical button group"
            sx={{
              display: "flex",
              overflowY: "auto",
              height: "auto",
              maxHeight: "57vh",
              background: "#ffffff",
              gap: "1px",
            }}
          >
            {filteredDevices.map((device) => (
              <Button
                key={device.id}
                onClick={(event) => onClickDevice(event, device?.id)}
                sx={{
                  display: "flex",
                  gap: "2px",
                  color: "#1B1534",
                  textTransform: "none",
                  width: "100%",
                  height: "56px",
                  justifyContent: "space-between",
                  background: "#fff !important",
                  border: "1px solid #EAEAEA",
                  borderBottom: "none !important",
                  borderLeft:
                    device?.id === selectedDeviceId
                      ? "4px solid #8A00E5!important"
                      : "none",
                  borderRadius: "0px !important",
                  ":hover, :focus, :active": {
                    border: "1px solid #EAEAEA !important",
                    borderBottom: "none !important",
                    background:
                      device?.id === selectedDeviceId
                        ? "#fff !important"
                        : "#EAEAEA !important",
                    borderLeft:
                      device?.id === selectedDeviceId
                        ? "4px solid #8A00E5!important"
                        : "1px solid #EAEAEA",
                  },
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#0D0D0D",
                    fontWeight: "400",
                    textAlign: "justify",
                  }}
                >
                  {device?.name}
                </Typography>
                <Chip
                  icon={
                    device?.deviceStatus === "Configured" ? (
                      <LightTooltip title="Configured">
                        <CheckCircleOutlineIcon
                          sx={{
                            color: "#fff !important",
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      </LightTooltip>
                    ) : (
                      <LightTooltip title="Unconfigured">
                        <CancelOutlinedIcon
                          sx={{
                            color: "#000000 !important",
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      </LightTooltip>
                    )
                  }
                  sx={{
                    gap: "2px",
                    height: "22px",
                    color:
                      device?.deviceStatus === "Configured"
                        ? "#fff"
                        : "#000000",
                    background:
                      device?.deviceStatus === "Configured"
                        ? "#007942"
                        : "#E2D000",
                    padding: "4px 8px",
                    ".MuiChip-label": {
                      padding: "0px",
                      fontSize: "10px",
                    },
                    ".MuiChip-icon": {
                      margin: "0px",
                    },
                  }}
                />
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Stack>
    </>
  );
};

export default EdgeDevicesList;
