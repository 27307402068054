import { SvgIcon } from "@mui/material";

const CustomSvgIcon = (props: any) => (
  <SvgIcon
    width="58"
    height="65"
    viewBox="0 0 58 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_5539_23011)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5401 44.4194L4 55.7754L19.8628 60.5695L22.5748 56.6277L37.3938 60.4191L24.2865 54.1399L28.5279 47.9753L12.5401 44.4194Z"
        fill="black"
        fill-opacity="0.2"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 4C15.7909 4 14 5.79086 14 8V39.8222C14 42.0314 15.7909 43.8222 18 43.8222H29L34 60L39 43.8222H50C52.2091 43.8222 54 42.0314 54 39.8222V8C54 5.79086 52.2091 4 50 4H18Z"
      fill={props.fillColor}
    />
    <rect
      x="16.5"
      y="6.48926"
      width="35"
      height="34.8444"
      rx="2"
      fill="white"
    />
    <g clip-path="url(#clip0_5539_23011)">
      <path d={props.path} fill="#1B1534" />
    </g>
    <defs>
      <filter
        id="filter0_f_5539_23011"
        x="0"
        y="40.4189"
        width="41.3936"
        height="24.1504"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_5539_23011"
        />
      </filter>
      <clipPath id="clip0_5539_23011">
        <rect width="48" height="48" fill="white" transform="translate(10)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CustomSvgIcon;
