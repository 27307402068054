import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Stack, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { BreadCrumbObject } from "../../Models/models";

type BreadCrumbPropsType = {
  breadCrumbsArray: BreadCrumbObject[];
};
export const Breadcrumb = (props: BreadCrumbPropsType) => {
  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" color="primary" />}
        aria-label="breadcrumb"
      >
        {props.breadCrumbsArray?.map(
          (bread: BreadCrumbObject, index: number) => {
            if (index !== props.breadCrumbsArray.length - 1) {
              return (
                <Link
                  key={bread.link}
                  underline="hover"
                  color="inherit"
                  fontWeight={700}
                  onClick={() => navigate(bread.link)}
                >
                  <Typography variant="caption" lineHeight="17px">
                    {bread.name}
                  </Typography>
                </Link>
              );
            } else {
              return (
                <Stack
                  direction={"row"}
                  alignItems={"flex-end"}
                  gap={"4px"}
                  marginTop={"2px"}
                  key={bread.name}
                >
                  <Typography variant="caption" lineHeight="17px">
                    {bread.name}
                  </Typography>
                  <NavigateNextIcon
                    sx={{ width: "20px", height: "20px" }}
                    color="primary"
                  />
                </Stack>
              );
            }
          }
        )}
      </Breadcrumbs>
    </Stack>
  );
};
